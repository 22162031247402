import { useState, useEffect } from "react";
import { useSelector } from './redux'
import { useDispatch } from "react-redux";
import * as actions from "@redux-actions/";
import { getCountryLabels } from "../store/actions/contentLanguageActions";

const useLanguageSelection = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.userProfile);

  const languages = useSelector((state) => state.utils.languages);

  useEffect(() => {
    if (userProfile && languages) {
      setInitialLang();
    }
  }, [userProfile, languages]);

  const setInitialLang = () => {
    let langValue = null;
    let userLang =
      userProfile.preferredLang !== "" ? userProfile.preferredLang : "en"; //fallback on english

    languages.map((language) => {
      if (language.code === userLang) {
        langValue = language;
      }
    });

    if (langValue) {
      setSelectedLanguage(langValue);
    }
  };
  const setLanguage = (el) => {
    setSelectedLanguage(el);
    dispatch(actions.updateUser(el.code, "language"));
    // CONTENT LANGUAGE
    dispatch(getCountryLabels(el.code))
  };

  return [languages, selectedLanguage, setLanguage];
};

export default useLanguageSelection;
