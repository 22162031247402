import CardLoader from '@components/UI/CardLoader';
import { getFallbackImage } from '@utility/Api';
import React, { ReactElement, useEffect, useState } from 'react';
import LazyImage from 'react-lazy-progressive-image';
import clsx from '@utility/clsx';
import { CoverHarvard } from '../Harvard/Harvard';
import { cmMetaData } from '@utility/previewUtils';
import { GRADIENT_VARIANT_ENUM } from '@model/GradientClass';

type Props = {
  image: string;
  fallbackImage?: string;
  courseFullName: string; //alt text
  variant?:
    | 'card-big'
    | 'card-medium'
    | 'vertical'
    | 'learningpath'
    | 'horizontal'
    | 'small'
    | 'image'
    | 'image-carousel-preview'
    | 'training-pill-medium'
    | 'none'
    | 'chip-large'
    | 'card-activity-cover'
    | 'card-bigonboarding'
    | 'onboarding-hp-section-img'
    | 'card-evolution-desk'
    | 'card-evolution-mobile'
    | 'card-large-evolution'
    | 'card-upcoming-events'
    | 'collectionbanner'
    | 'areaofexpertise'
    | 'card-evolution-desk-harvard'
    | 'card-career-program'
    | 'card-byc-office';
  fallbackText?: string;
  hideShadow?: boolean;
  forceFallback?: boolean;
  className?: string;
  dontCrop?: boolean;
  centerSpinner?: boolean;
  centerSpinnerVertical?: boolean;
  forceLoader?: boolean;
  hideSpinner?: boolean;
  hideFallback?: boolean;
  typeHarvard?: string;
  visibilitySensorProps?: object;
  contentId?: number | string; //FOR CMS PREVIEW
  noLazyLoadImage?: boolean;
  gradients?: [GRADIENT_VARIANT_ENUM];
};

const CardCover = ({
  image,
  contentId,
  courseFullName,
  fallbackImage,
  variant,
  fallbackText,
  hideShadow,
  forceFallback,
  dontCrop,
  className,
  centerSpinner,
  centerSpinnerVertical,
  forceLoader,
  hideSpinner,
  hideFallback,
  typeHarvard,
  visibilitySensorProps = { partialVisibility: true, offset: { bottom: -100, right: -100 } },
  noLazyLoadImage,
  gradients
}: Props): ReactElement => {
  const [hideImage, setHideImage] = useState(false);

  useEffect(() => {
    if (image) {
      setHideImage(false);
    }
  }, [image]);

  const printCrop = () => {
    if (dontCrop) {
      return '';
    }

    switch (variant) {
      case 'card-big':
        return '?impolicy=Card&cropeast=170&cropwest=170&width=300';
      case 'card-medium':
        return '?impolicy=Card&cropeast=170&cropwest=170&width=160';
      case 'vertical':
        return '?impolicy=Card&cropeast=170&cropwest=170';
      case 'learningpath':
        return '?impolicy=Card&cropnorth=170&cropsouth=170&width=333';
      case 'horizontal':
        return '?impolicy=Card&cropnorth=170&cropsouth=170';
      case 'card-bigonboarding':
        return '?impolicy=Card&cropnorth=170&cropsouth=170&width=460';
      case 'onboarding-hp-section-img':
        return '?width=670';
      case 'small':
        return '?impolicy=Card';
      case 'training-pill-medium':
        return image?.includes('imwidth') ? '' : '?imwidth=300';
      case 'image':
        return image?.includes('imwidth') ? '' : '?imwidth=1024';
      case 'image-carousel-preview':
        return image?.includes('imwidth') ? '' : '?imwidth=300';
      case 'chip-large':
        return '?imwidth=100';
      case 'card-activity-cover':
        return '?impolicy=Card&cropnorth=238&cropsouth=135&cropwest=62&cropeast=79&width=327&height=223';
      case 'card-evolution-desk':
        return '?impolicy=Card&cropnorth=170&cropsouth=170&width=400';
      case 'card-evolution-desk-harvard':
        return '?impolicy=Card&cropnorth=170&cropsouth=170&width=600'
      case 'card-evolution-mobile':
        return '?impolicy=Card&cropeast=170&cropwest=170&width=300';
      case 'card-large-evolution':
        return '?impolicy=Card&width=513';
      case 'card-upcoming-events':
        return '?impolicy=Card&cropnorth=170&cropsouth=170&width=400';
      case 'collectionbanner':
        return '?impolicy=Card&cropnorth=170&cropsouth=170&width=450';
      case 'areaofexpertise':
        return '?impolicy=Card&cropnorth=170&cropsouth=170&width=500';
      case 'card-career-program':
        return '?impolicy=Card&width=321&height=180';
      // case 'card-byc-office':
      //   return '?impolicy=Card&width=432&height=180';
      default:
        return '';
    }
  };

  // IF noLazyLoadImage return the <img /> 
  if(noLazyLoadImage && image) {
    return (<>
      <img
        className={clsx('card-v3__background', {
          [variant]: variant,
          [className]: className,
        })}
        src={image + printCrop()}
        alt={courseFullName}
        draggable={false}
        {...contentId ? { ...cmMetaData(contentId) } : null}
      />
    </>)
  }

  // IF forceLoader return the <CardLoader /> even if image is set and loaded (?WHY?)
  if (forceLoader) {
    return (
      <LazyImage src={''} placeholder="" visibilitySensorProps={visibilitySensorProps}>
        {(src, loading) => (
          <CardLoader
            className={clsx({
              'card-loader__center': centerSpinner,
              'card-loader__center-vertical': centerSpinnerVertical || variant === 'image',
            })}
          />
        )}
      </LazyImage>
    );
  }

  return !hideImage && !forceFallback ? (
      <>
        {typeHarvard && (
          <div className="card_cover--harvard">
            <CoverHarvard type={typeHarvard} />
          </div>
        )}
        <LazyImage
          src={image + printCrop()}
          onError={() => {
            setHideImage(true);
          }}
          placeholder=""
          visibilitySensorProps={visibilitySensorProps}
        >
        {(src, loading) => {
          return loading ? (
            hideSpinner ? (

                  <div
                    style={{ minWidth: '1px', minHeight: '1px' }}
                    className={className ? className : ''}
                  >
                {'​'}
                  </div>
            ) : (


                <CardLoader
                  className={clsx({
                    'card-loader__center': centerSpinner,
                    'card-loader__center-vertical': centerSpinnerVertical || variant === 'image',
                  })}
                />
            )


          ) : (
              <img
                className={clsx('card-v3__background', {
                  loading: loading,
                  [variant]: variant,
                  [className]: className,
                })}
                src={src}
                alt={courseFullName}
                draggable={false}
                {...contentId ? { ...cmMetaData(contentId) } : null}
              />
            );

          }}
        </LazyImage>

      {!hideShadow && <div className="card-v3__shadow" />}
    </>
  ) : (
      <>
        {hideFallback ? (
          <div className={className ? className : ''}></div>
        ) : (

          <img
            className={clsx('card-v3__background card-v3__background-fallback', {
              [className]: className,
            })}
            src={fallbackImage ? fallbackImage : getFallbackImage(4)}
            alt={courseFullName}
            draggable={false}
          />
        )}
        {fallbackText && <span className="card-v3__fallback-text">{fallbackText}</span>}
        {!hideShadow && <div className="card-v3__shadow" />}
      </>

  );
};

export default CardCover;
