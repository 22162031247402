import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '@utility/Api';
import { CountryLanguagesMapping, CountryTranslations } from '../../types/ContentLanguage/contentLanguage';

class InitialStateProps {
  countryTranslations: CountryTranslations = null;
  isLoadingGetCountryLabels: boolean = false;
  isLoadedGetCountryLabels: boolean = false;
  // countryLanguagesMapping: CountryLanguagesMapping = null;
  // isLoadingGetCountryLanguagesMapping: boolean = false;
  // isLoadedGetCountryLanguagesMapping: boolean = false;
}

export const initialState: InitialStateProps = new InitialStateProps();

// COUNTRY LABELS
const getCountryLabelsRequest = (state: InitialStateProps) => {
  return updateObject(state, {
    isLoadingGetCountryLabels: true,
    isLoadedGetCountryLabels: false,
  });
};

const getCountryLabelsSuccess = (state: InitialStateProps, translations: CountryTranslations) => {
  return updateObject(state, {
    isLoadingGetCountryLabels: false,
    isLoadedGetCountryLabels: true,
    countryTranslations: translations,
  });
};

const getCountryLabelsFailure = (state: InitialStateProps) => {
  return updateObject(state, {
    isLoadingGetCountryLabels: false,
    isLoadedGetCountryLabels: false,
  });
};

// COUNTRY LANGUAGES MAPPING
// const getCountryLanguagesMappingRequest = (state: InitialStateProps) => {
//   return updateObject(state, {
//     isLoadingGetCountryLanguagesMapping: true,
//     isLoadedGetCountryLanguagesMapping: false,
//   });
// };

// const getCountryLanguagesMappingSuccess = (state: InitialStateProps, mapping: CountryLanguagesMapping) => {
//   return updateObject(state, {
//     isLoadingGetCountryLanguagesMapping: false,
//     isLoadedGetCountryLanguagesMapping: true,
//     countryLanguagesMapping: mapping,
//   });
// };

// const getCountryLanguagesMappingFailure = (state: InitialStateProps) => {
//   return updateObject(state, {
//     isLoadingGetCountryLanguagesMapping: false,
//     isLoadedGetCountryLanguagesMapping: false,
//   });
// };

interface Action {
  type: string;
  payload?: any;
}

const reducer = (state: InitialStateProps = initialState, action: Action): InitialStateProps => {  
  switch (action.type) {
    // COUNTRY LABELS
    case actionTypes.GET_COUNTRY_LABELS_REQUEST:
      return getCountryLabelsRequest(state);
    case actionTypes.GET_COUNTRY_LABELS_SUCCESS:
      return getCountryLabelsSuccess(state, action.payload);
    case actionTypes.GET_COUNTRY_LABELS_FAILURE:
      return getCountryLabelsFailure(state);
    // COUNTRY LANGUAGES MAPPING
    // case actionTypes.GET_COUNTRY_LANGUAGES_MAPPING_REQUEST:
    //   return getCountryLanguagesMappingRequest(state);
    // case actionTypes.GET_COUNTRY_LANGUAGES_MAPPING_SUCCESS:
    //   return getCountryLanguagesMappingSuccess(state, action.payload);
    // case actionTypes.GET_COUNTRY_LANGUAGES_MAPPING_FAILURE:
    //   return getCountryLanguagesMappingFailure(state);
    // DEFAULT
    default:
      return state;
  }
}

export default reducer;