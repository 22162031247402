import clsx from '@utility/clsx';
import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import { SelectProps, SelectVariations } from '../../../../types/UI/ContentLanguage/Select';
import AlertIcon from '@svg-icons/alert.svg';
import ArrowDown from '@svg-icons/chevron-down-2.svg';
import SearchIcon from '@svg-icons/header/search.svg';
import LazySVG from '@components/LazySvg';
import ErrorMessage from '@components/UI/ErrorMessage';
import { useSelector } from 'react-redux';
import ErrorMessageV2 from '@components/UI/ErrorMessageV2';

const ValueContainer = ({ children, ...props }) => {
    return <components.ValueContainer {...props} className={`${clsx({ 'icon-svg': props.selectProps.iconSvg })}`} clearValue={props.clearValue} cx={props.cx} getStyles={props.getStyles} getValue={props.getValue} hasValue={props.hasValue} isMulti={props.isMulti} selectOption={props.selectOption} selectProps={props.selectProps} setValue={props.setValue} options={props.options}>
        {props.selectProps.iconSvg && (
            <div className="icon-svg_wrapper">
                <img src={props.selectProps.iconSvg.src} alt={props.selectProps.iconSvg.alt} />
            </div>
        )}
        {props.selectProps.id !== "role-select" && (
            <components.Placeholder {...props} isFocused={props.isFocused} isDisabled={props.isDisabled} innerProps={props.innerProps} clearValue={props.clearValue} cx={props.cx} getStyles={props.getStyles} getValue={props.getValue} hasValue={props.hasValue} selectOption={props.selectOption} setValue={props.setValue} options={props.options} isMulti={props.isMulti} selectProps={props.selectProps}>
                {props.selectProps.placeholder}
                <div className="iconSearch">
                    {props.selectProps.inputValue !== "" &&
                        <LazySVG src="/images/svg-icons/x-icon2.svg" alt="" className='dropdown_close_icon' />
                    }
                    {props.selectProps.inputValue === "" &&
                        <SearchIcon />
                    }
                </div>
            </components.Placeholder>
        )}
        {React.Children.map(children, child => (
            child && child.type !== components.Placeholder ? child : null
        ))}
    </components.ValueContainer>
};

const Select: FC<SelectProps> = ({
    className,
    name,
    id,
    value,
    options,
    onChange,
    placeholder,
    ariaLabel,
    label,
    hasSearch = false,
    mandatory,
    isLoading = false,
    disabled = false,
    error,
    setError,
    inputRef,
    arrow = <ArrowDown />,
    variation = SelectVariations.big
}) => {

    const lang = useSelector((state: any) => state.utils.lang);
    const [inFocus, setInFocus] = useState<boolean>(false);
    const [keyboardActive, setKeyboardActive] = useState(false);
    const [hidePlaceholder, setHidePlaceholder] = useState<boolean>(false);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const plcHolder = useMemo(() => {
        if(isMenuOpen && hasSearch) return lang?.SEARCH_LABEL || 'Search';
        // if(hidePlaceholder) return '';
        if(value) return '';
        return placeholder
    }, [isMenuOpen,hidePlaceholder,placeholder,value])

    useEffect(() => {
        const handleKeyDown = () => setKeyboardActive(true);
        const handleMouseDown = () => setKeyboardActive(false);

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('mousedown', handleMouseDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    const handleOnFocus = () => {
        setInFocus(true);
        setHidePlaceholder(false);
    }

    const handleOnBlur = () => {
        setInFocus(false);
        setHidePlaceholder(true);
    }

    const handleOnChange = (selectedOption: any) => {
        if (onChange) {
            onChange(selectedOption);
            if(error && setError) setError(null)
        }
    }

    const handleOnInputChange = (e: any) => {
        setHidePlaceholder(true);
    }

    const selectWrapperClassName = clsx('form__wrapper_select', {
        [className]: className,
        'disabled': disabled
    });

    const selectClassName = clsx('custom-select-v3', {
        [className]: className,
        'keyboard-active': keyboardActive,
        inFocus: inFocus,
        mandatory: mandatory,
        'input--error': error,
        'disabled': disabled,
        'searchable': hasSearch,
        [variation]: variation
    });

    const DropdownIndicator = (props) => (
        <div className="wrapper-icon with-icon-suffix">
            {error && <AlertIcon id="alert-icon" />}
            <components.DropdownIndicator {...props}>
                {arrow && arrow}
            </components.DropdownIndicator>
        </div>
    );

    return (
        <div 
            className={selectWrapperClassName}
            aria-label={ariaLabel}
        >
            {/* LABEL ABOVE SELECT */}
            {label &&
                <span className={clsx(`select__label`, {'disabled': disabled})}>
                    {label}
                </span>
            }
            {/* SELECT */}
            <ReactSelect
                id={id ? id : 'select-id'}
                tabIndex="0"
                className={selectClassName}
                classNamePrefix={`custom-select`}
                name={name}
                value={value}
                options={options}
                styles={{
                    singleValue: provided => ({
                        ...provided,
                        position: 'static',
                        textOverflow: 'none',
                        WebkitTransform: 'none',
                        transform: 'none',
                        whiteSpace: 'normal',
                    }),
                    input: provided => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                    })
                }}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                onInputChange={handleOnInputChange}
                inputRef={inputRef}
                aria-label={ariaLabel}
                placeholder={plcHolder}
                isSearchable={hasSearch}
                isDisabled={disabled}
                isLoading={isLoading}
                components={{ValueContainer,DropdownIndicator}}
            />
            {error && error.type &&
                <ErrorMessageV2 
                    className={clsx('errorMessage', {[className]: className})} 
                    error={error} 
                />
            }
        </div>
    )
}

export default Select;