import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
import { courseStatesConst, updateType, TAGS, queryParams } from '@utility/const';
const axios = require('axios');
import {
  isVirtualClassroom,
  isLearningPath,
  updateCourseArrays,
  insertInMap,
  getHeaders,
  isCourseVisible,
  checkisInArray,
  enrichCourseInfo,
  enrichChildInfo,
  completedLinksToShow,
  checkisCourseEliminated,
  deleteFromAllArrays,
  isCompletedCourse,
  isCompletedChildCourse,
  printCorrectCourseInModal,
  checkCourseState,
  milliseconds,
  canJoinSession,
  getParentCourse,
  isLivestreamCourse,
  isMobile,
  addSubtitleParam,
  orderUpcomingEvents,
  isLpEnrolled,
  getCoursesWithinLP,
  isVCInTheFuture,
  isPathAbsolute,
  goToBrandPage,
  hasCoursePartialChildren,
  isCourse0,
  getChildCourseByLanguage,
  sanitizeCourseIdV2,
  getCompletedCourseforLP,
  handleCourseDetailQueryParam,
  isCourseCertificateCtype,
  isMandatory,
} from '../../utility/Api';
import {
  showError,
  getL1Map,
  toggleIframePopup,
  setShowModalByType,
  setAutoManageHistoryIframeOnClose,
  getUserRelevantSessionsById
} from './utilsActions';
// import { getCookie } from '../../utility/cookie';
// import coursesMoodle from '../../data/courses_moodle.json';
// import { refreshReportMyTeam } from './reportManagerActions';
// import { generateNotif } from './firebaseActions';
import { createIcs, getLocalDateFromString } from '@utility/CalendarUtility';
import { isLivestreamNotYetFinished } from '@utility/LivestreamUtility';
import { pushNotificationPreview } from './firebaseActions';
import { isPreviewToShow } from '@utility/firebaseUtility';
import { canCourseBeAccessed, promiseMock } from '@utility/ecommerceUtility';
import history from '../../history';
import { USER_URLS, styledLogUtagView } from '@components/link-utils';
import { addPreviouslySearched } from '.';
import {
  isCourseOnBoarding,
  isSupportLessonInOnboarding,
  isSupportLessonInOnboardingNoCompletion,
} from '@utility/onBoardingCourseUtility';
import { matchPath } from 'react-router';
import { coursesSummaries } from '@data/courses/HPOptimizationW1CourseSummaryMock';
import { triggerTrackEvent } from '@utility/analytics-utils';
import { removeVideoProgressCookie } from '@utility/VideoTracking/videoTrackingUtility';
// import coursesForNotification from '../../data/coursesForNotification.json';
// import coursesExpiring from '../../data/coursesExpiring.json';
// import courseCounter from '../../data/courseCounter.json';
// import courseOverviewMock from "../../data/course_overview_mock";
// import courseOverviewCompletedMock from "../../data/c_overview_completed_mock";

export const maxCoursesInCarousel = 12;
const privus4Catalog = '../../data/courses/HPOptimizationW1CatalogMock.json';
const privus2Catalog = '../../data/courses/HPOptimizationW1CatalogMock2.json';
const whs12 = '../../data/courses/HPOptimizationW1CatalogMock3.json';
const elearntest12 = '../../data/courses/HPOptimizationW1CatalogMock4.json';
const whs13 = '../../data/courses/HPOptimizationW1CatalogMock5.json';

const mockCatalog = async () => {
  const catalogMock = await fetch(elearntest12);
  const catalogMockJson = await catalogMock.json()
  return { data: catalogMockJson }
}

const mockCatalogPunctual = async () => {
  const privus2Lp1642 = '../../data/courses/HPOptimizationW1CatalogPunctualMock2.json'
  const privus4CatalogLp2066 = '../../data/courses/HPOptimizationW1CatalogPunctualMock.json';

  const catalogMockPunctual = await fetch(privus2Lp1642);
  const catalogMockPunctualJson = await catalogMockPunctual.json()
  return { data: catalogMockPunctualJson }
}

export const getCourses = () => {
  const mock = false;
  return dispatch => {
    dispatch(request());
    let url = urlList.GET_COURSES_V3_URL;

    (mock ? mockCatalog() :
      axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      }))
      .then(response => {
        // console.log('getCourses')
        let courses = response.data;

        if (!Array.isArray(courses)) {
          console.error('/course/catalog has not returned an array');
          dispatch(failure());
          dispatch(showError('/course/catalog has not returned an array'));
          return;
        }

       // remove courses without courseId
        if (courses && courses.length > 0) {
          courses.map(course => {
            sanitizeCourseIdV2(course);

            //Following the homepage optimization developments, child courses no longer arrive in the form of an object but in the form of a map, for this reason it was decided to transform the field from a map to an object so as not to have an impact on the other pages
            if (course.childCourses && course.childCourses.length !== 0) {
              let newChildCourses = [];
            Object.keys(course.childCourses).map(
              key => {
              let childCourse = {childId:String,language: String};
               childCourse.childId = key;
               childCourse.language = course.childCourses[key];
               newChildCourses.push(childCourse); 
              }
            )
            course.childCourses = newChildCourses;
            }
          })
          courses = courses.filter(course => course.courseIdMaster);
        }
        dispatch(success(courses)); 
      })
      .catch(err => {
        console.error('Error during catalog request', err);
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_COURSES_REQUEST,
    };
  }

  function success(courses) {
    return {
      type: actionTypes.GET_COURSES_SUCCESS,
      courses,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_COURSES_FAILURE,
    };
  }
};

export const saveCourses = (isFast = false) => {
  return (dispatch, getState) => {
    const courses = getState().course.courses, //coursesMoodle, //
      userProfile = getState().user.userProfile;
    let coursesMap = {},
      coursesMapCompleted = {},
      joiningCourses = [],
      joiningLivestreams = [];

    courses.forEach(course => {
      coursesMapCompleted[course.courseIdMaster] = course;
      if (!checkisCourseEliminated(course) || isLearningPath(course)) {
        coursesMap[course.courseIdMaster] = course;
      }
    });

    for (let courseId in coursesMap) {
      let course = coursesMap[courseId];
      let enrichedCourse = enrichCourseInfo(course, coursesMapCompleted, userProfile.preferredLang);
      coursesMap[course.courseIdMaster] = enrichedCourse;
      coursesMapCompleted[course.courseIdMaster] = enrichCourseInfo(
        course,
        coursesMapCompleted,
        userProfile.preferredLang
      );

      if (
        isVirtualClassroom(enrichedCourse) &&
        checkCourseState(enrichedCourse.courseStates, courseStatesConst.SCHEDULED)
      ) {
        joiningCourses.push(enrichedCourse);
      }

      if (isLivestreamCourse(enrichedCourse) && isLivestreamNotYetFinished(course, userProfile)) {
        joiningLivestreams.push(enrichedCourse);
      }

      //get certificates not completed
      if (
        isCourseCertificateCtype(enrichedCourse) &&
        !isCompletedCourse(enrichedCourse) &&
        enrichedCourse.parentLP
      ) {
        enrichedCourse.parentLP.forEach(parentLP => {
          const lpCertificate = coursesMap[parentLP.courseId];
          if (isCompletedCourse(lpCertificate)) {
            //if LP is completed but its certificate doesn't --> complete certificate
            completeCertificateActivity(enrichedCourse.courseId || enrichedCourse.courseIdMaster);
          }
        });
      }
    }

    if (isFast) {
      dispatch(success(coursesMapCompleted));
    } else {
      dispatch(setJoiningCourses(joiningCourses));
      dispatch(setJoiningLivestreams(joiningLivestreams));

      dispatch(success(coursesMapCompleted));
      dispatch(getUpcomingEvents(coursesMap));

      dispatch(getTimeOnTraining(Object.values(coursesMap)));

      //new homepage
      dispatch(getLivestreams(coursesMap));

      //get L1 map
      dispatch(getL1Map(coursesMap));

      dispatch(setTimerJoin());
      dispatch(setLivestreamTimerJoin());
      dispatch(updateCourseInModal(coursesMap));

      //update courses in PLP arriving from carousel
      dispatch(updateCatalogueCourses(coursesMap));
    }
  };

  function success(coursesMap) {
    return {
      type: actionTypes.CHANGE_COURSE_MAP,
      coursesMap,
    };
  }
};

export const updateCatalogueCourses = coursesMap => {
  return (dispatch, getState) => {
    let catalogueCourses = getState().course.catalogueCourses;
    let newCatalogueCourses = [];

    if (catalogueCourses?.length > 0) {
      for (let course of catalogueCourses) {
        newCatalogueCourses.push(coursesMap[course.courseIdMaster]);
      }

      dispatch(setCatalogueCourses(newCatalogueCourses, getState().course.catalogueTitle));
    }
  };
};

export const getCourseSummaryMock = async (courseId) => {
  const courseSummaries = await fetch('../../data/courses/HPOptimizationW1CourseSummaryMock.json');
  const courseSummariesJson = await courseSummaries.json();
  return { data: courseSummariesJson };
}

export const getCourseSummary = (
  courses,
  coursesMap,
  childCourse = null,
  retrievingSummaryForLandingLang = false,
  prevUrlIsMyCertificates = false
  ) => {
  if(courses.length == 0) {
    return
  }
  const mock = false;
  const idsToRequest = childCourse ? [childCourse.childId] : courses.map(course => course?.courseId)
  const queryParams = `?courseId=${[...idsToRequest].join(',')}`

  return (dispatch) => {
    let url = urlList.GET_COURSE_SUMMARY_URL + queryParams;
    dispatch(request(idsToRequest));

    (mock
      ? getCourseSummaryMock(idsToRequest)
      : axios({
        url,
        method: 'GET',
        headers: getHeaders(),
      })
    )
      .then((response) => {
          if (coursesMap && response) {

            const coursesSummaries = response.data.reduce((acc, course) => {
              acc[course?.courseId] = course.courseSummary; 
              return acc
            }, {})

            // Handle case empty courseummary
            //  This prevent a loop in PDP when the course summary is empty
            if(Object.keys(coursesSummaries)?.length === 0) {
              dispatch(success(idsToRequest));
              return
            }

            let coursesWithCourseSummary = [];
            courses.forEach((course) => {
              if(childCourse && !retrievingSummaryForLandingLang) {
                let oldChildCourses = cloneDeep(course?.childCourses);
                oldChildCourses.forEach(oldChildCourse => {
                  if(oldChildCourse.childId === childCourse?.childId) {
                    oldChildCourse.courseSummary = coursesSummaries[childCourse?.childId]
                  }
                })
                coursesWithCourseSummary.push({...course, childCourses: oldChildCourses})
              } else {
                coursesWithCourseSummary.push({ 
                  ...course,
                  courseSummary: coursesSummaries[course?.courseId],
                  courseIdMaster: childCourse ? childCourse?.languageToPrint?.parentId : course.courseIdMaster
                })
              }
            })
            dispatch(updateCoursesInCatalog(coursesWithCourseSummary, courses.map(course => course?.courseIdMaster), '', true, false, prevUrlIsMyCertificates));
            dispatch(success(idsToRequest));
          }
      })
      .catch(err => {
        console.error("Error during getCourseSummary for courseIds: " + [...idsToRequest])
        dispatch(showError(err));
        dispatch(failure(idsToRequest));
      });

  };

  function request(courseIds) {
    return {
      type: actionTypes.GET_COURSE_SUMMARY_REQUEST,
      courseIds
    };
  }

  function success(courseIds) {
    return {
      type: actionTypes.GET_COURSE_SUMMARY_SUCCESS,
      courseIds
    };
  }

  function failure(courseIds) {
    return {
      type: actionTypes.GET_COURSE_SUMMARY_FAILURE,
      courseIds
    };
  }
};

export const saveSearchResults = searchResults => {
  return {
    type: actionTypes.GET_SEARCH_RESULTS,
    searchResults,
  };
};

export const insertSearchArrayInMap = results => {
  return (dispatch, getState) => {
    let courseIdSubarrayMap = getState().course.courseIdSubarrayMap;

    //remove all previoues search arrays
    Object.keys(courseIdSubarrayMap).map(courseId => {
      let index = courseIdSubarrayMap[courseId].findIndex(e => e === 'searchResults');
      if (index !== -1) {
        if (courseIdSubarrayMap[courseId].length === 1) {
          delete courseIdSubarrayMap[courseId];
        } else {
          courseIdSubarrayMap[courseId].splice(index, 1);
        }
      }
    });

    //adde search array to new elements
    results.map(course => {
      courseIdSubarrayMap = insertInMap(
        courseIdSubarrayMap,
        course.courseIdMaster,
        'searchResults'
      );
    });

    dispatch({
      type: actionTypes.UPDATE_COURSE_ID_SUBARRAY_MAP,
      courseIdSubarrayMap,
    });
  };
};

export const resetSearch = () => {
  return {
    type: actionTypes.RESET_SEARCH,
  };
};

export const setSuggestionInput = (searchInput, searchType, searchValueToView) => {
  return {
    type: actionTypes.SET_SUGGESTION_INPUT,
    searchInput,
    searchType,
    searchValueToView,
  };
};

export const setVisibilitySearch = isOpen => {
  return {
    type: actionTypes.SET_VISIBILITY_SEARCH,
    isOpen,
  };
};

export const resetFilteredCourses = () => {
  return {
    type: actionTypes.RESET_FILTERED_COURSES,
  };
};

export const setCatalogueCourses = (
  catalogueCourses,
  catalogueTitle = '',
  catalogueDescription = ''
) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_CATALOGUE_COURSES,
      catalogueCourses,
      catalogueTitle,
      catalogueDescription,
    });
  };
};

export const resetCatalogueCourses = () => {
  return {
    type: actionTypes.RESET_CATALOGUE_COURSES,
  };
};

export const setCatalogueLP = (catalogueLP, catalogueTitle, catalogueDescription = '') => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_CATALOGUE_LP,
      catalogueLP,
      catalogueTitle,
      catalogueDescription,
    });
  };
};

export const resetCatalogueLP = () => {
  return {
    type: actionTypes.RESET_CATALOGUE_LP,
  };
};

export const getCompletedCourses = coursesMap => {
  return (dispatch, getState) => {
    let courseIdSubarrayMap = getState().course.courseIdSubarrayMap,
      completedCourses = [];
    for (var id in coursesMap) {
      let course = coursesMap[id];

      if (isCompletedCourse(course)) {
        completedCourses.push(course);
        courseIdSubarrayMap = insertInMap(courseIdSubarrayMap, id, 'completedCourses');
      }
    }

    // console.log('getCompletedCourses', completedCourses.map(a => { return { courseIdMaster: a.courseIdMaster, courseFullName: a.courseFullName, courseCompletionDate: a.courseCompletionDate } }))

    dispatch({
      type: actionTypes.GET_COMPLETED_COURSES,
      completedCourses,
      courseIdSubarrayMap,
    });

    dispatch(getTimeOnTraining(Object.values(coursesMap)));
  };
};

const getTimeOnTraining = courses => {
  return dispatch => {
    let timeOnTraining = 0;
    if (courses) {
      for (let course of courses) {
        //if a course has children --> consider children
        let considerParent = true;
        if (course.childCourses && course.childCourses.length > 0) {
          course.childCourses.forEach(child => {
            if (isCompletedChildCourse(child)) {
              timeOnTraining += child.duration;
              considerParent = false;
            }
          });
        }

        if (considerParent && isCompletedCourse(course)) {
          timeOnTraining += course.duration;
        }
      }
    }

    const timeOnTrainingString = (timeOnTraining ? Math.floor(timeOnTraining / 3600) : 0) + '';

    dispatch({
      type: actionTypes.SET_TIME_ON_TRAINING,
      timeOnTraining: timeOnTrainingString,
    });
  };
};

export const getWishList = () => {
  return (dispatch, getState) => {
    const mockRequest = false;

    let url = urlList.GET_WISHLIST_COURSES_URL;
    dispatch(request());

    (mockRequest
      ? promiseMock({
          data: {
            courseIdWishlist: [
              ...(Object.keys(getState().course.courseIdSubarrayMap)?.slice(0, 20) || []),
            ],
          },
        })
      : axios.get(url, {
          headers: getHeaders(),
        })
    )
      .then(response => {
        let coursesMap = getState().course.coursesMap;
        let courseIdSubarrayMap = getState().course.courseIdSubarrayMap;
        let wishlist = [];

        const courseIdWishlist = response.data.courseIdWishlist || [];
        courseIdWishlist.forEach(id => {
          wishlist.push(coursesMap[id]);
          courseIdSubarrayMap = insertInMap(courseIdSubarrayMap, id, 'wishlist');
        });
        dispatch(success({ wishlist, courseIdSubarrayMap }));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.GET_WISHLIST_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: actionTypes.GET_WISHLIST_SUCCESS,
      ...payload,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_WISHLIST_FAILED,
    };
  }
};

export const getUpcomingEvents = coursesMap => {
  return (dispatch, getState) => {
    let courseIdSubarrayMap = getState().course.courseIdSubarrayMap;
    let userProfile = getState().user.userProfile;
    let upcomingEvents = [];

    for (var id in coursesMap) {
      let course = coursesMap[id];
      if (
        isCourseVisible(course) &&
        (isVCInTheFuture(course) ||
          // || isLearningPathContainingVCInFuture(course, coursesMap)
          (isLivestreamCourse(course) && isLivestreamNotYetFinished(course, userProfile)))
      ) {
        upcomingEvents.push(course);
        courseIdSubarrayMap = insertInMap(courseIdSubarrayMap, id, 'upcomingEvents');
      }
    }

    upcomingEvents = orderUpcomingEvents(upcomingEvents, coursesMap);

    dispatch({
      type: actionTypes.GET_UPCOMING_EVENTS,
      upcomingEvents,
      courseIdSubarrayMap,
    });
  };
};

export const getLivestreams = coursesMap => {
  return (dispatch, getState) => {
    let courseIdSubarrayMap = getState().course.courseIdSubarrayMap,
      newCourses = [];

    for (var id in coursesMap) {
      let course = coursesMap[id];
      if (isLivestreamCourse(course) && isCourseVisible(course)) {
        newCourses.push(course);
        courseIdSubarrayMap = insertInMap(courseIdSubarrayMap, id, 'livestreams');
      }
    }

    dispatch({
      type: actionTypes.GET_LIVESTREAMS,
      livestreams: newCourses,
      courseIdSubarrayMap,
    });
  };
};

export const bookSession = (sessionId, booked, id, isInPopup) => {
  return (dispatch, getState) => {
    dispatch(request());
    let url =
      urlList.BOOK_SESSION_URL +
      '?sessionId=' +
      sessionId +
      '&subscribe=' +
      booked +
      '&courseId=' +
      id;

    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        let course = response.data;

        const labelL1Map = getState().utils.labelL1Map;

        //get courseIdMaster if present, otherwise courseId
        let courseId = course.courseIdMaster;
        if (!courseId) {
          courseId = course.courseId;
        }

        dispatch(updateCourseInCatalog(course, courseId, '', true));

        dispatch(success());

        if(!!booked) {
          let courseContextIdFromSessionStorage = window?.sessionStorage.getItem("Products_ContextId_Array") ?? "";
          if(courseContextIdFromSessionStorage === "undefined") courseContextIdFromSessionStorage = ""
          let utagEventData = {
            Products_Id_Array: [course?.courseIdMaster],
            Products_Category_Array: course?.catalogTypes.map(catalogType => {
              let categoryName = labelL1Map?.[catalogType]?.label;
              return categoryName?.toLowerCase()
            }),
            Products_ModelName_Array: [course?.courseFullName?.toLowerCase()],
            Products_Language_Array: [course?.language],
            Events_BookingEvent: '1',
            Products_Mandatory_Array: isMandatory(course) ? ['1']: ['0'],
            Products_ContextId_Array: [courseContextIdFromSessionStorage]
          }
  
          triggerTrackEvent(utagEventData);
          styledLogUtagView("Book session", utagEventData)
        }
      })
      .catch(err => {
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.BOOK_SESSION_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.BOOK_SESSION_SUCCESS,
    };
  }

  function failure() {
    return {
      type: actionTypes.BOOK_SESSION_FAILURE,
    };
  }
};

export const deleteCourse = courseId => {
  return (dispatch, getState) => {
    if (!courseId) {
      return;
    }

    console.log('Delete course ' + courseId);

    let coursesMap = { ...getState().course.coursesMap };

    if (coursesMap && coursesMap[courseId]) {
      let courseIdSubarrayMap = getState().course.courseIdSubarrayMap;
      let currentCourse = getState().course.currentCourse;

      if (coursesMap[courseId]) {
        delete coursesMap[courseId];
      }
      //1) update courses map
      dispatch({
        type: actionTypes.CHANGE_COURSE_MAP,
        coursesMap,
      });

      deleteFromAllArrays(courseIdSubarrayMap, courseId, dispatch, getState);

      if (courseIdSubarrayMap[courseId]) {
        delete courseIdSubarrayMap[courseId];
      }

      dispatch({
        type: actionTypes.UPDATE_COURSE_ID_SUBARRAY_MAP,
        courseIdSubarrayMap,
      });

      if (currentCourse && currentCourse.courseIdMaster === courseId) {
        dispatch(toggleModal(false));
      }
    } else {
      //check if the courseId is a child course --> if true, update the parent
      let parentCourse = getParentCourse(courseId, coursesMap);
      if (parentCourse && parentCourse.courseIdMaster !== courseId) {
        dispatch(updateCourse(parentCourse.courseIdMaster, {}));
      }
    }
  };
};

export const getFullCourse = course => {
  return (dispatch, getState) => {
    if (!course) {
      return;
    }

    if (hasCoursePartialChildren(course)) {
      dispatch(updateCourse(course.courseIdMaster, {}, true, true));
    }
  };
};

export const getChildCourse = (course, childId = null, language = null, coursesMap) => {
  return (dispatch, getState) => {
    if (!course || (!childId && !language)) {
      return;
    }

    if (!childId && language) {
      childId = getChildCourseByLanguage(course, language)?.childId;
    }

    if (childId && hasCoursePartialChildren(course, childId)) {
      dispatch(updateCourse(childId, {}, true, true));
    } else if (childId && !hasCoursePartialChildren(course, childId) && coursesMap) {
        course?.childCourses.forEach(childCourse => {
          if(childCourse?.childId === childId && childCourse.courseSummary === undefined) {
            dispatch(getCourseSummary([childCourse], coursesMap, childCourse, true))
          }
        })
    }

  };
};

export const checkIfUpdateOnboarding = course => {
  return (dispatch, getState) => {
    let coursesMap = getState().course.coursesMap;

    if (!coursesMap || !course) {
      return;
    }

    //just in onboarding page
    if (
      window.location.pathname.includes(USER_URLS.ONBOARDING_COURSE_ID.NORMAL_URL) ||
      window.location.pathname === USER_URLS.EDUCATIONAL_PATHS.URL
    ) {
      //if you're updating a lesson in the onboarding --> update onboarding to get updated chart data
      for (let courseTemp of Object.values(coursesMap)) {
        if (isCourseOnBoarding(courseTemp)) {
          const onboardingCourse = courseTemp;
          if (
            onboardingCourse?.learningPath?.findIndex(a => a.parentId === course.courseIdMaster) >=
            0
          ) {
            //the lesson to update is included in the onboarding --> update onboarding
            dispatch(updateCourse(onboardingCourse.courseIdMaster, {}, true));
          }
        }
      }
    }
  };
};

export const updateCourse = (
  courseId,
  notif,
  maintainCourseDetail = false,
  updateAllReturnedCourses = false,
  maintainCourseSummary = false,
  prevUrlIsMyCertificates = false,
  isReviewModal = false
) => {
  const mock = false;
  return (dispatch, getState) => {
    if (!courseId) {
      return;
    }

    let coursesMapTemp = { ...getState().course.coursesMap };
    let LpChildId = null;

    //if the course is not in your coursesMap --> not update it
    //if the course is not in your coursesMap but the course is new --> update it
    if (!coursesMapTemp) {
      return;
    }
    let parentTemp = getParentCourse(courseId, coursesMapTemp);

    const updateTypeRes = notif?.notificationtype;
    if (
      updateTypeRes !== updateType.NEW_COURSE &&
      updateTypeRes !== updateType.NEW_COURSE_ASSIGNED &&
      updateTypeRes !== updateType.NEW_LP_ASSIGNED
    ) {
      if (!parentTemp || !coursesMapTemp[parentTemp.courseIdMaster]) {
        return;
      }

      //if LP --> update parent
      if (isLearningPath(parentTemp)) {
        console.log('Update LP ' + courseId + ', parent ' + parentTemp.courseIdMaster);
        //store LP child id in LpChildId
        LpChildId = courseId;
        courseId = parentTemp.courseIdMaster;
      }
    } else {
      if (parentTemp) {
        //console.log("NOT UPDATE");
        return;
      }
    }

    dispatch(checkIfUpdateOnboarding(parentTemp));

    dispatch(request(parentTemp?.courseIdMaster));

    console.log('Update course ' + courseId);

    let url = urlList.GET_SINGLE_COURSE_V3;

    (mock? mockCatalogPunctual() :
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
      params: { courseId: courseId, childId: LpChildId },
    }))
      .then(response => {
        if (!response || !response.data || response.data.length < 1) {
          return;
        }

        if (updateAllReturnedCourses) {
          dispatch(
            updateCoursesInCatalog(
              response.data,
              response.data.map(a => a.courseIdMaster),
              updateTypeRes,
              maintainCourseDetail,
              maintainCourseSummary,    
            )
          );
        } else {
          const course =
            response.data.filter(a => a.courseIdMaster === courseId)?.[0] || response.data[0];
          dispatch(updateCourseInCatalog(course, courseId, updateTypeRes, maintainCourseDetail, maintainCourseSummary, prevUrlIsMyCertificates));
        }

        //Moved logic from ReviewModal.tsx
        const course =
        response.data.filter(a => a.courseIdMaster === courseId)?.[0] || response.data[0];
        if (isReviewModal && isVirtualClassroom(course)) {
          dispatch(getUserRelevantSessionsById(courseId));
        } 

        //show notif preview after the course is updated
        if (isPreviewToShow(notif, true, true)) {
          dispatch(pushNotificationPreview(notif));
        }
      })
      .catch(err => {
        dispatch(showError(err));
      })
      .finally(() => {
        dispatch(end(parentTemp?.courseIdMaster));
      });
  };

  function request(courseId) {
    return {
      type: actionTypes.GET_COURSE_REQUEST,
      courseId,
    };
  }

  function end(courseId) {
    return {
      type: actionTypes.GET_COURSE_END,
      courseId,
    };
  }
};

export const updateCourseInCatalog = (
  course,
  courseId,
  updateTypeRes = '',
  maintainCourseDetail = false,
  maintainCourseSummary = false,
  prevUrlIsMyCertificates = false
) => {
  return dispatch => {
    if (!course || !courseId) {
      return;
    }
    dispatch(updateCoursesInCatalog([course], [courseId], updateTypeRes, maintainCourseDetail, maintainCourseSummary, prevUrlIsMyCertificates));
  };
};

export const updateCoursesInCatalog = (
  courses,
  courseIds,
  updateTypeRes = '',
  maintainCourseDetail = false,
  maintainCourseSummary = false,
  prevUrlIsMyCertificates
) => {
  return (dispatch, getState) => {
    if (!courses || !courseIds) {
      return;
    }
    console.log('Update course in catalog ' + courseIds);
    let coursesMap = { ...getState().course.coursesMap };
    let completedCourses = { ...getState().course.completedCourses };
    let completedCoursesArr = [ ...getState().course.completedCourses ];

    const currentUrlIsMyCertificates = window.location?.pathname?.includes(USER_URLS.PROFILE_MY_CERTIFICATES.URL);
    const isOnboardingPage = window.location?.pathname?.includes(USER_URLS.ONBOARDING_COURSE.URL) && !window.location?.pathname?.includes(queryParams.COURSE_DETAIL);

    const userProfile = getState().user.userProfile;
    const currentCourse = getState().course.currentCourse;

    if (currentUrlIsMyCertificates || (prevUrlIsMyCertificates && isOnboardingPage)) {
      //LEON-4784 - If a card is clicked from My Certificate page, keep the certificateLinks populated value 
      if (completedCourses) {
        let updatedCoursesMap = Object.keys(completedCourses).reduce((acc, key) => {
          let courseId = completedCourses[key].courseId;
          acc[courseId] = completedCourses[key];
          return acc;
        }, {});

        let updatedCourses = courses.map(course => {
          let completedCourse = completedCoursesArr.find(completed => completed.courseId === course.courseId);
          if (completedCourse) {
            return completedCourse;
          } else {
            return course;
          }
        });

        if (updatedCoursesMap) {
          coursesMap = { ...coursesMap, ...updatedCoursesMap };
        }

        if (updatedCourses) {
          function deepMerge(target, source) {
            for (const key in source) {
              if (source.hasOwnProperty(key)) {
                if (source[key] instanceof Object && key in target) {
                  Object.assign(source[key], deepMerge(target[key], source[key]));
                }
              }
            }
            Object.assign(target || {}, source);
            return target;
          }
          //merge all fields, even nested ones
          courses = deepMerge({ ...courses }, { ...updatedCourses });
        }
      }
    }

    if (coursesMap) {
      const cloneCourses = cloneDeep(courses);
      for (const index in cloneCourses) {
        let course = sanitizeCourseIdV2(cloneCourses[index]);
        //if course is empty --> remove it
        if (Object.keys(course).length === 1) {
          dispatch(deleteCourse(courseIds[index]));
          return;
        }

        //if the course is a child course --> update the parent
        let parentCourse = getParentCourse(course.courseIdMaster, coursesMap);
        if (
          parentCourse &&
          parentCourse.courseIdMaster !== course.courseIdMaster &&
          parentCourse.childCourses &&
          parentCourse.childCourses.length > 0
        ) {
          //find and update the correct child
          for (let i = 0; i < parentCourse.childCourses.length; i++) {
            if (parentCourse.childCourses[i].childId === course.courseIdMaster) {
              //update child
              parentCourse.childCourses[i] = maintainCourseDetail
                ? {
                    ...course,
                    courseDetails: parentCourse.childCourses[i]?.courseDetails,
                    numActivities: parentCourse.childCourses[i]?.numActivities,
                  }
                : course;
              //now continue the course updating with the parent
              course = parentCourse;
              break;
            }
          }
        } else if (
          maintainCourseDetail &&
          parentCourse &&
          parentCourse?.courseIdMaster === course.courseId &&
          parentCourse?.courseDetails?.length > 0
        ) {
          course.courseDetails = cloneDeep(parentCourse.courseDetails);
        }

        //merge children info stored in coursesMap (to not loose children already downloaded)
        const oldCourse = coursesMap[course.courseIdMaster];

        if(maintainCourseSummary && oldCourse) {
          if(oldCourse?.courseSummary) {
            course.courseSummary = oldCourse?.courseSummary
          }
        }

        if (oldCourse?.childCourses) {
          let newChildCourses = [];
          //for each child --> find the old one
          course.childCourses?.map(child => {
            let newChild = child;
            const oldChild = oldCourse.childCourses.find(a => a.childId === child.childId);
            if (oldChild) {
              //overwrite new child info to old info
              newChild = { ...oldChild, ...newChild };
            }
            newChildCourses.push(newChild);
          });
          course.childCourses = newChildCourses;
        }

        course = enrichCourseInfo(course, coursesMap, userProfile.preferredLang);
        if (oldCourse?.parentLP?.length > 0) {
          course.parentLP = oldCourse.parentLP;
        }

        //do not loose showJoin attribute
        if (oldCourse?.showJoin) {
          course.showJoin = true;
        }
        if (oldCourse?.showLiveBadge) {
          course.showLiveBadge = true;
        }
        if (oldCourse?.showLiveCountdown) {
          course.showLiveCountdown = true;
        }

        //merge childCourses
        if (oldCourse?.childCourses) {
          for (let child of oldCourse.childCourses) {
            const indexChild = course.childCourses.findIndex(a => a.childId === child.childId);
            if (indexChild >= 0) {
              course.childCourses[indexChild] = {
                ...cloneDeep(child),
                ...cloneDeep(course.childCourses[indexChild]),
              };
            }
          }
        }

        coursesMap[course.courseIdMaster] = maintainCourseDetail
          ? {
              ...course,
              courseDetails: oldCourse?.courseDetails,
              numActivities: oldCourse?.numActivities,
            }
          : course;

        //save new course in cloneCourses
        cloneCourses[index] = course;
      }

      //1) update courses map
      dispatch({
        type: actionTypes.CHANGE_COURSE_MAP,
        coursesMap,
      });

      // 2) RECALCULATE ALL ARRAYS
      dispatch(addSingleCoursesImproved(coursesMap, cloneCourses));

      for (let course of cloneCourses) {
        //3) if course is in modal, update it
        if (currentCourse && currentCourse.courseIdMaster === course.courseIdMaster) {
          let toPrint = printCorrectCourseInModal(currentCourse, course);
          if (isCourseVisible(toPrint, false)) {
            dispatch(setCurrentCourseInModal(toPrint));
            if (!isLearningPath(course)) {
              const isLoadingDetails = getState().course.isLoadingDetails?.[toPrint.courseIdMaster];
              console.log('isLoadingDetails', isLoadingDetails);
              //download details if not already in progress
              if (!isLoadingDetails) {
                console.log('updateCourse 3 if course is currentCourse - getCourseDetails');
                dispatch(getCourseDetails(toPrint));
              }
            }
          } else {
            dispatch(toggleModal(false));
          }
        } else {
          //3.1) if course is in iframe but not in modal (e.g. launching lesson from LP), update activities
          const courseIframe = getState().utils.courseIframe;

          if (
            courseIframe?.courseIdMaster === course.courseIdMaster &&
            isCourseVisible(course, false) &&
            !isLearningPath(course)
          ) {
            const isLoadingDetails = getState().course.isLoadingDetails?.[course.courseIdMaster];
            //download details if not already in progress
            if (!isLoadingDetails) {
              console.log(
                'updateCourse 3.1 if course is in iframe but not in modal - getCourseDetails'
              );
              dispatch(getCourseDetails(course));
            }
          }
        }

        //4) if a learning path containing the course is in modal, update it
        if (currentCourse && isLearningPath(currentCourse)) {
          //the learning path contains the modified course?
          const index = currentCourse.learningPath?.findIndex(
            lpCourse => lpCourse.parentId === course.courseIdMaster
          );
          if (index >= 0) {
            let toPrint = printCorrectCourseInModal(
              currentCourse,
              coursesMap[currentCourse.courseIdMaster]
            );
            if (isCourseVisible(toPrint, false)) {
              dispatch(setCurrentCourseInModal(toPrint));
              //if(!isLearningPath(coursesMap[currentCourse.courseIdMaster])) dispatch(getCourseDetails(toPrint))
            } else {
              dispatch(toggleModal(false));
            }
          }
        }
      }

      //NOT THIS IS DONE AT NOTIFICATION ARRIVAL in firebaseActions.js, handleNotifArrival()
      //6) if (notification has type activity_completed || course_completed) && user is entered at least once in completions page
      // --> call course/v2/completions to have the feedback and certificate buttons
      // if ((updateTypeRes === updateType.ACTIVITY_COMPLETED || updateTypeRes === updateType.COURSE_COMPLETED_OLD) && getState().course.isGetCourseCompletionCompleted) {
      //     dispatch(getCourseCompletion(true));
      // }
    }
  };
};

export const noticeArrived = () => {
  return {
    type: actionTypes.NOTICE_ARRIVED,
  };
};

export const clearNoticeArrived = () => {
  return {
    type: actionTypes.CLEAR_NOTICE_ARRIVED,
  };
};

export const getNoticeReceived = (courseId, updateTypeRes = null) => {
  return dispatch => {
    let courseIdParam = 'courseId=' + courseId;
    let updateTypeParam = '';
    if (updateTypeRes && updateTypeRes == updateType.REPORT_MANAGER_UPDATED_OLD) {
      updateTypeParam = 'updateType=' + updateTypeRes;
      courseIdParam = '';
    }

    axios({
      url: urlList.GET_NOTICE_RECEIVED + '?' + courseIdParam + updateTypeParam,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {})
      .catch(err => {});
  };
};
/* MODIFY COURSES API LIST */
// export const toggleWish = (id, value, isInPopup = false, currentCourseModal = null) => {
//     let idCourse = id;

//     return (dispatch, getState) => {
//         let url = urlList.UPDATE_WISHLIST_URL;

//         dispatch(request())

//         axios.get(url, {
//             headers: getHeaders(),
//             params: {
//                 refid: idCourse,
//                 wl: value
//             }
//         }).then(response => {
//             let coursesMap = { ...getState().course.coursesMap },
//                 courseIdSubarrayMap = getState().course.courseIdSubarrayMap,
//                 wishlist = getState().course.wishlist,
//                 course = response.data,
//                 currentCourse = coursesMap[idCourse],
//                 userProfile = getState().user.userProfile;

//             //get details for parent
//             course.courseDetails = currentCourse.courseDetails;

//             //get details for children
//             if (course.childCourses.length > 0) {
//                 course.childCourses.map(child => {
//                     currentCourse.childCourses.map(oldChild => {
//                         if (child.courseId === oldChild.courseId) {
//                             oldChild.courseDetails ? child.courseDetails = oldChild.courseDetails : null
//                         }
//                     })
//                 })
//             }

//             course = enrichCourseInfo(course, coursesMap, userProfile.preferredLang);
//             //do not loose showJoin attribute
//             if (coursesMap[idCourse]?.showJoin) {
//                 course.showJoin = true;
//             }
//             if (coursesMap[idCourse]?.showJoinCountdown) {
//                 course.showJoinCountdown = true;
//             }
//             coursesMap[idCourse] = course;

//             //1) update map
//             dispatch({
//                 type: actionTypes.CHANGE_COURSE_MAP,
//                 coursesMap
//             });

//             //2) push/pop from wishlist
//             if (course.wishlist) {
//                 insertInMap(courseIdSubarrayMap, course.courseId, "wishlist")
//             } else {
//                 let index = wishlist.findIndex(wishlistCourse => wishlistCourse.courseId === course.courseId);
//                 wishlist.splice(index, 1);

//                 deleteFromMap(courseIdSubarrayMap, course.courseId, "wishlist");

//                 dispatch({
//                     type: actionTypes.GET_WISHLIST,
//                     courseIdSubarrayMap,
//                     wishlist
//                 });
//             }

//             //3) update all array with that course
//             updateCourseArrays(dispatch, getState, courseIdSubarrayMap, course);

//             //4) update course in modal
//             if (isInPopup) {
//                 let toPrint = printCorrectCourseInModal(currentCourseModal, course)
//                 dispatch(setCurrentCourseInModal(toPrint))
//             }

//             dispatch(success())
//         }).catch(err => {
//             dispatch(showError(err));
//             dispatch(failure())
//         });
//     }

//     function request() {
//         return {
//             type: actionTypes.UPDATE_WISHLIST_REQUEST
//         };
//     }

//     function success() {
//         return {
//             type: actionTypes.UPDATE_WISHLIST_SUCCESS
//         };
//     }

//     function failure() {
//         return {
//             type: actionTypes.UPDATE_WISHLIST_FAILURE
//         };
//     }
// };

export const toggleWish = (id, value) => {
  let idCourse = id;

  const mockRequest = false;

  return (dispatch, getState) => {
    let url = urlList.UPDATE_WISHLIST_V2_URL;
    dispatch(request());

    (mockRequest
      ? promiseMock({
          response: true,
        })
      : axios.post(url, null, {
          headers: getHeaders(),
          params: {
            courseId: idCourse,
            add: !!value,
          },
        })
    )
      .then(response => {
        let coursesMap = getState().course.coursesMap;
        let currentCourse = getState().course.currentCourse;
        const labelL1Map = getState().utils.labelL1Map;

        let course = null;
        if (coursesMap?.[idCourse]) {
          //retrieve course from coursesMap
          course = cloneDeep(coursesMap[idCourse]);

          //update wishlist
          course.wishlist = value;

          //get details if present in currentCourse
          if (currentCourse?.courseDetails) {
            course.courseDetails = currentCourse.courseDetails;
            course.numActivities = currentCourse.numActivities;
          }

          dispatch(updateCourseInCatalog(course, idCourse, null, true));
        }
        let courseContextIdFromSessionStorage = window?.sessionStorage.getItem("Products_ContextId_Array") ?? "";
        if(courseContextIdFromSessionStorage === "undefined") courseContextIdFromSessionStorage = ""
        let utagEventData = {
          Events_ProdFavAdd: '1',
          Products_Id_Array: [course?.courseIdMaster],
          Products_Category_Array: course?.catalogTypes?.map(catalogType => {
            let categoryName = labelL1Map?.[catalogType]?.label;
            return categoryName?.toLowerCase();
          }),
          Products_ModelName_Array: [course?.courseFullName?.toLowerCase()],
          Products_Language_Array: [course?.language],
          Products_Mandatory_Array: isMandatory(course) ? ['1'] : ['0'],
          Products_ContextId_Array: [courseContextIdFromSessionStorage]
        }

        dispatch(success());
        if(!!value) {
          triggerTrackEvent(utagEventData);
          styledLogUtagView("Add to favorite course", utagEventData);
        }
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.UPDATE_WISHLIST_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.UPDATE_WISHLIST_SUCCESS,
    };
  }

  function failure() {
    return {
      type: actionTypes.UPDATE_WISHLIST_FAILURE,
    };
  }
};

export const toggleLike = (id, value, isInPopup = false, currentCourseModal = null) => {
  let idCourse = id;

  return (dispatch, getState) => {
    let url = urlList.UPDATE_LIKE_URL;
    dispatch(request());
    axios
      .get(url, {
        headers: getHeaders(),
        params: {
          refid: idCourse,
          like: value,
        },
      })
      .then(response => {
        let coursesMap = { ...getState().course.coursesMap },
          courseIdSubarrayMap = getState().course.courseIdSubarrayMap,
          course = response.data,
          userProfile = getState().user.userProfile;

        const currentCourse = coursesMap[idCourse];
        //get details for parent
        if (currentCourse.courseDetails) {
          course.courseDetails = currentCourse.courseDetails;
          course.numActivities = currentCourse.numActivities;
        }

        //get details for children
        if (course.childCourses?.length > 0) {
          course.childCourses.map(child => {
            currentCourse.childCourses.map(oldChild => {
              if (child.childId === oldChild.childId) {
                oldChild.courseDetails ? (child.courseDetails = oldChild.courseDetails) : null;
                oldChild.numActivities ? (child.numActivities = oldChild.numActivities) : null;
              }
            });
          });
        }

        course = enrichCourseInfo(course, coursesMap, userProfile.preferredLang);
        coursesMap[idCourse] = course;

        //1) update course map
        dispatch({
          type: actionTypes.CHANGE_COURSE_MAP,
          coursesMap,
        });

        //2) update all array with that course
        updateCourseArrays(dispatch, getState, courseIdSubarrayMap, course);

        //if course is in modal, update course in modal
        let toPrint = printCorrectCourseInModal(currentCourseModal, course);
        if (isInPopup) dispatch(setCurrentCourseInModal(toPrint));

        dispatch(success());
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure());
      });
  };

  function request() {
    return {
      type: actionTypes.TOGGLE_LIKE_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.TOGGLE_LIKE_SUCCESS,
    };
  }

  function failure() {
    return {
      type: actionTypes.TOGGLE_LIKE_FAILURE,
    };
  }
};

/** ACTIONS FOR COURSE MODAL **/
export const getCourseDetails = course => {
  let id = course.courseId;
  let parentId = course.courseIdMaster;

  if (!id) {
    //if child id is not present --> get parent id
    id = parentId;
  }

  return (dispatch, getState) => {
    if (!id) {
      console.error('getCourseDetails - courseId not found');
      return;
    }

    let url = urlList.COURSE_OVERVIEW_URL;
    // console.log('getCourseDetails', course)

    dispatch(request(parentId));

    axios
      .get(url, {
        headers: getHeaders(),
        params: {
          courseId: id,
        },
      })
      .then(response => {
        //ORDER COURSE DETAIL
        const details = orderBy(response.data?.activities, ['sectionPosition']);

        const numActivities = {
          totalActivities: response.data?.totalActivities || 0,
          completedActivities: response.data?.completedActivities || 0,
        };

        let coursesMap = cloneDeep(getState().course.coursesMap),
          currentCourse = getState().course.currentCourse;

        if (parentId !== id) {
          let course = coursesMap[parentId];

          course.courseDetails = details;
          course.numActivities = numActivities;

          course.childCourses?.map(child => {
            if (child.childId === id) {
              child.courseDetails = details;
              child.numActivities = numActivities;
              child = enrichChildInfo(course, child);
              //not override other course popup already opened
              //!currentCourse ||
              if (
                currentCourse?.courseIdMaster === course.courseIdMaster ||
                currentCourse?.courseId === child.courseId
              ) {
                dispatch(setCurrentCourseInModal(child));
              }
            }
          });

          // updateCourseArrays(dispatch, getState, courseIdSubarrayMap, course);

          dispatch({
            type: actionTypes.CHANGE_COURSE_MAP,
            coursesMap,
          });
        } else {
          let course = coursesMap[id];

          course.courseDetails = details;
          course.numActivities = numActivities;

          // updateCourseArrays(dispatch, getState, courseIdSubarrayMap, course);

          // if(id === currentCourse.courseIdMaster) setCurrentCourseInModal(course);

          dispatch({
            type: actionTypes.CHANGE_COURSE_MAP,
            coursesMap,
          });

          //not override other course popup already opened
          if (!currentCourse || currentCourse.courseIdMaster === course.courseIdMaster) {
            dispatch(setCurrentCourseInModal(course));
          }
        }
        // remove courseId_videoProgress cookie if present
        removeVideoProgressCookie(course?.courseId);
        
        dispatch(success(parentId));
      })
      .catch(err => {
        dispatch(showError(err));
        dispatch(failure(parentId));
      });
  };

  function request(courseId) {
    return {
      type: actionTypes.GET_COURSE_DETAILS_REQUEST,
      courseId,
    };
  }

  function success(courseId) {
    return {
      type: actionTypes.GET_COURSE_DETAILS_SUCCESS,
      courseId,
    };
  }

  function failure(courseId) {
    return {
      type: actionTypes.GET_COURSE_DETAILS_FAILURE,
      courseId,
    };
  }
};

export const setCurrentCourseInModal = currentCourse => {
  return (dispatch, getState) => {
    const coursesMap = getState().course.coursesMap;
    if (currentCourse) {
      let parentCourse = currentCourse.courseIdMaster
        ? coursesMap[currentCourse.courseIdMaster]
        : currentCourse;
      let btnToPrint = completedLinksToShow(parentCourse);
      handleCourseDetailQueryParam(currentCourse, true);
      dispatch({
        type: actionTypes.SET_CURRENT_COURSE,
        currentCourse,
        feedbackLinks: btnToPrint.feedbackLinks,
        certificateLinks: btnToPrint.certificateLinks,
      });
    } else {
      dispatch({
        type: actionTypes.SET_CURRENT_COURSE,
        currentCourse,
        feedbackLinks: [],
        certificateLinks: [],
      });
    }
  };
};

export const setChildCourse = language => {
  return (dispatch, getState) => {
    let childId = language.courseId;
    let coursesMap = getState().course.coursesMap;
    
    if (language.isParent) {
      dispatch(setCurrentCourseInModal(coursesMap[language.courseId]));
    } else {
      let parent = coursesMap[language.parentId],
        childToPrint = null;

      parent.childCourses?.forEach(child => {
        if (child.childId === childId) {
          // child.totalLanguages = parent.totalLanguages;
          childToPrint = enrichChildInfo(parent, child);
          if (!childToPrint.courseFullName) {
            dispatch(getCatalog(childToPrint));
          }
          return dispatch(setCurrentCourseInModal(childToPrint));
        }
      });
      
      if (!isLearningPath(parent)) dispatch(getCourseDetails(childToPrint));
    }
  };
};

export const openSubCourseInLP = currentCourse => {
  return {
    type: actionTypes.OPEN_SUB_COURSE_IN_LP,
    currentCourse,
  };
};

export const toggleModal = (
  value,
  course = null,
  closeAll = false,
  scrollToCurrentCourseSessions = false,
  openLessonWithReducer = false,
  prevUrlIsMyCertificates = false
) => {
  return (dispatch, getState) => {
    if (isSupportLessonInOnboarding(course)) {
      //these courses cannot be opened in the detail modal
      return;
    }

    let coursesMap = getState().course.coursesMap;
    //close what's next modal if open
    const showNextUpModal = getState().course.showNextUpModal;
    if (showNextUpModal) {
      dispatch(toggleNextUpModal(false));
    }

    dispatch(addPreviouslySearched());

    if (value && isCourseOnBoarding(course)) {
      const redirectUrl = USER_URLS.ONBOARDING_COURSE_ID.URL.replace(':id', course.courseIdMaster);
      //go to course.courseIdMaster onboarding page if not already in
      if (!matchPath(window.location.pathname, { path: redirectUrl, exact: true })) {
        let refreshPage = false;
        //if in onboarding page but not course.courseIdMaster onboarding page --> refresh
        if (
          matchPath(window.location.pathname, {
            path: USER_URLS.ONBOARDING_COURSE_ID.URL,
            exact: true,
          })
        ) {
          refreshPage = true;
        }
        history.push(redirectUrl, {previousUrlCertificates: prevUrlIsMyCertificates});
        if (refreshPage) {
          history.go();
        }
      } else {
        //just close header
        dispatch(setShowModalByType(null));
      }
      return;
    }

    //if open lesson --> update just url
    if (value && !course?.isStatic) {
      if (course) {
        handleCourseDetailQueryParam(course);
        dispatch(triggerAppJsUpdate());
      } else {
        return;
      }

      if (!openLessonWithReducer) {
        //do not call reducer
        return;
      }
    }

    //if close just one course detail --> history.back()
    const currentCourse = getState().course.currentCourse;
    if (!value && !closeAll && currentCourse && !currentCourse.isStatic) {
      console.log('HISTORY', window.history);
      /* let courseMasterCurrent = coursesMap[currentCourse.courseIdMaster];
            let courseIdPage = history.location.search;
            let index = courseIdPage.indexOf("&");
            courseIdPage = courseIdPage.substring(14, index);
            let courseMasterNext = getParentCourse(courseIdPage, coursesMap);
            if (courseMasterNext?.courseIdMaster === courseMasterCurrent?.courseIdMaster) {
                let newUrl = window.location.href;
                let indexPage = newUrl.indexOf("?");
                newUrl = newUrl.substring(indexPage, newUrl.length);
                window.location.replace(window.location.href.replace(newUrl, ""));
                return;
            } */
      //get the Id from new url
      let newUrl = window?.history?.state?.path || window.location.href;
      if (newUrl !== undefined) {
        let index = newUrl.indexOf('&');
        let indexFirst = newUrl.indexOf('?');
        newUrl = newUrl.substring(indexFirst + 14, index);
      }
      //get the id from the old url
      let oldUrl = window?.history?.state?.prevUrl;
      if (oldUrl !== undefined) {
        let oldIndex = oldUrl.indexOf('&');
        let indexFirstOld = oldUrl.indexOf('?');
        oldUrl = oldUrl.substring(indexFirstOld + 14, oldIndex);
      }

      //get the course parent from coursesMap
      let courseCurrent = getParentCourse(newUrl, coursesMap);
      let coursePrev = getParentCourse(oldUrl, coursesMap);
      console.log('????', courseCurrent, coursePrev);
      if (courseCurrent?.courseIdMaster === coursePrev?.courseIdMaster) {
        let urlToReplace = window.location.href;
        let indexPage = urlToReplace?.indexOf('?');
        urlToReplace = urlToReplace.substring(indexPage, urlToReplace.length);
        window.location.replace(window.location.href.replace(urlToReplace, ''));
        return;
      }

      //if history.goBack() is not possible --> just remove query params
      if (!oldUrl) {
        history.push(window.location.pathname);
        return;
      }

      console.log('????', newUrl, oldUrl);
      history.goBack();
      return;
    }

    dispatch({
      type: actionTypes.TOGGLE_COURSE_MODAL,
      value,
      course,
      closeAll,
      scrollToCurrentCourseSessions,
    });
  };
};

export const resetScrollToCurrentCourseSessions = () => {
  return {
    type: actionTypes.RESET_SCROLL_SESSIONS,
  };
};

// export const getCourseO = () => {
//     return dispatch => {
//         dispatch(request());
//         let url = urlList.GET_COURSES_URL;

//         axios({
//             url,
//             method: "POST",
//             headers: getHeaders(),
//             data: {
//                 courseIds: ["co0"]
//             }
//         })
//             .then(response => {

//                 dispatch(success(response.data));
//                 dispatch({
//                     type: actionTypes.GET_COURSE_0_COMPLETED,
//                     courses0: response.data
//                 });

//             }).catch(err => {
//                 dispatch(failure());
//                 dispatch(showError(err));
//             });
//     }

//     function request() {
//         return {
//             type: actionTypes.GET_COURSES_0_REQUEST,
//         };
//     }

//     function success(courses) {
//         return {
//             type: actionTypes.GET_COURSE_0_COMPLETED,
//             courses0: courses
//         };
//     }

//     function failure() {
//         return {
//             type: actionTypes.GET_COURSES_0_FAILURE
//         };
//     }

// }

// export const getCourse = (courseId, successCallback) => {
//     return dispatch => {
//         let url = urlList.GET_COURSES_URL;

//         axios({
//             url,
//             method: "POST",
//             headers: getHeaders(),
//             data: {
//                 courseIds: [courseId]
//             }
//         })
//             .then(response => {
//                 successCallback(response.data);
//             }).catch(err => {
//                 dispatch(showError(err));
//             });
//     }
// }

export const getCourseCompletionById = courseId => {
  return dispatch => {
    dispatch(request());
    let url = urlList.GET_COURSES_COMPLETION_V3_URL;

    axios({
      url,
      method: 'GET',
      params: {
        courseId,
      },
      headers: getHeaders(),
    })
      .then(response => {
        if (response && response.data && response.data.length > 0) {
          let coursesCompletion = response.data;
          //set completed courses except courses 0
          let completedCourses = coursesCompletion.filter(
            a => !isCourse0(a) && !isSupportLessonInOnboardingNoCompletion(a)
          );
          dispatch(success(completedCourses));
        } else {
          dispatch(failure());
        }
      })
      .catch(err => {
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_COURSE_COMPLETION_BY_ID_REQUEST,
    };
  }

  function success(courses) {
    return {
      type: actionTypes.GET_COURSE_COMPLETION_BY_ID_SUCCESS,
      courses,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_COURSE_COMPLETION_BY_ID_FAILURE,
    };
  }
};

export const getCourseCompletion = (resetCompletedParam = false) => {
  return (dispatch, getState) => {
    dispatch(request());
    if (resetCompletedParam) {
      dispatch(resetIsGetCourseCompletionCompleted());
    }
    let url = urlList.GET_COURSES_COMPLETION_V3_URL;
    let coursesMap = getState().course.coursesMap;

    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        // console.log('getCourseCompletion', response.data);
        if (response && response.data && response.data.length > 0) {
          let coursesCompletion = response.data;

          //set courses 0
          dispatch({
            type: actionTypes.GET_COURSE_0_COMPLETED,
            courses0: coursesCompletion.filter(a => isCourse0(a)),
          });

          //set completed courses except courses 0
          let completedCourses = coursesCompletion.filter(
            a => !isCourse0(a) && !isSupportLessonInOnboardingNoCompletion(a)
          );

          //LEON 7193: check if it is in validity period
          for (let course of completedCourses) {
            if (isLearningPath(course)) {
          
              // Check if all lessons in the learning path are valid
              const allLessonsValid = course?.learningPath?.every(lesson => {
                if(coursesMap[lesson.parentId]) return false;
                return true;
              });
          
              // Set isInValidityPeriod based on allLessonsValid
              course.isInValidityPeriod = allLessonsValid;
            } else {
              if (course?.endDate && new Date(course.endDate).getTime() < new Date().getTime()) {
                course.isInValidityPeriod = true;
              }
            }
          }
          
          dispatch({
            type: actionTypes.GET_COMPLETED_COURSES,
            completedCourses: completedCourses,
          });

          // dispatch(getTimeOnTraining(completedCourses));
        }

        dispatch(completed());
      })
      .catch(err => {
        dispatch(completed());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_COURSE_COMPLETION_REQUEST,
    };
  }

  function resetIsGetCourseCompletionCompleted() {
    return {
      type: actionTypes.SET_IS_COURSE_COMPLETION_COMPLETED,
      isGetCourseCompletionCompleted: false,
    };
  }

  function completed() {
    return {
      type: actionTypes.GET_COURSE_COMPLETION_COMPLETED,
    };
  }
};

export const resetVirtual = () => {
  return {
    type: actionTypes.RESET_VIRTUAL,
  };
};

export const setSubScribedCourses = subscribedCourses => {
  return {
    subscribedCourses,
    type: actionTypes.GET_SUBSCRIBED_COURSES,
  };
};

export const daysBetween = (first, second) => {
  // Copy date parts of the timestamps, discarding the time parts.
  var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
  var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

  // Do the math.
  var millisecondsPerDay = 1000 * 60 * 60 * 24;
  var millisBetween = two.getTime() - one.getTime();
  var days = millisBetween / millisecondsPerDay;

  // Round down.
  return Math.floor(days);
};

// export const addSingleCourse = coursesMap => {
//     return (dispatch, getState) => {

//         dispatch(getCompletedCourses(coursesMap));

//         let currentPage = window.location.href;
//         if(currentPage.includes("subcatalogue") && currentPage.includes("search")){
//             let category = null;
//             let searchResults = JSON.parse(JSON.stringify(getState().course.searchResults));
//             let filteredCourses = [];

//             searchResults.map(result => {
//                 filteredCourses.push(coursesMap[result.courseIdMaster])
//             })
//         }
//     }
// }

export const addSingleCourseImproved = (coursesMap, course) => {
  return dispatch => {
    if (!coursesMap || !course) {
      return;
    }

    dispatch(addSingleCoursesImproved(coursesMap, [course]));
  };
};

export const addSingleCoursesImproved = (coursesMap, courses) => {
  return (dispatch, getState) => {
    if (!coursesMap || !courses) {
      return;
    }

    let courseIdSubarrayMap = getState().course.courseIdSubarrayMap;

    //2) completed course
    let completedCourses = [...getState().course.completedCourses];
    //4) update course in catalog arriving from a carousel
    let catalogueCourses = [...getState().course.catalogueCourses];
    //5) update lp in catalog arriving from a carousel
    let isLPPresent = false;
    let catalogueLP = [...getState().course.catalogueLP];

    for (let course of courses) {
      const id = course.courseIdMaster;

      //2) completed course
      if (isCompletedCourse(course)) {
        const index = checkisInArray(completedCourses, id);

        if (index !== null) {
          completedCourses[index] = course;
        } else {
          completedCourses.push(course);
          courseIdSubarrayMap = insertInMap(courseIdSubarrayMap, id, 'completedCourses');
        }
      }

      //4) update course in catalog arriving from a carousel
      const index = checkisInArray(catalogueCourses, id);
      catalogueCourses[index] = course;

      //5) update lp in catalog arriving from a carousel
      if (isLearningPath(course)) {
        isLPPresent = true;
        const indexLp = checkisInArray(catalogueLP, id);
        catalogueLP[indexLp] = course;
      }
    }

    //2) completed courses
    dispatch({
      type: actionTypes.GET_COMPLETED_COURSES,
      completedCourses,
      courseIdSubarrayMap,
    });

    //4) update course in catalog arriving from a carousel
    dispatch(
      setCatalogueCourses(
        catalogueCourses,
        getState().course.catalogueTitle,
        getState().course.catalogueDescription
      )
    );

    //5) update lp in catalog arriving from a carousel
    if (isLPPresent) {
      dispatch(
        setCatalogueLP(
          catalogueLP,
          getState().course.catalogueTitle,
          getState().course.catalogueDescription
        )
      );
    }

    //update carousels
    dispatch(getLivestreams(coursesMap));
    dispatch(getUpcomingEvents(coursesMap));
  };
};

//PER PROVE
export const updateCourseCounter = () => {
  return {
    joiningCourses,
    type: actionTypes.GET_JOINING_COURSES,
  };
};

export const setJoiningCourses = joiningCourses => {
  return {
    joiningCourses,
    type: actionTypes.GET_JOINING_COURSES,
  };
};

export const setTimerJoin = () => {
  return (dispatch, getState) => {
    const courses = getState().course.joiningCourses;
    const userProfile = getState().user.userProfile;

    let coursesMap = { ...getState().course.coursesMap },
      courseIdSubarrayMap = { ...getState().course.courseIdSubarrayMap },
      joiningCourses = cloneDeep(courses),
      today = new Date(),
      tomorrow = new Date(),
      isChanged = false;

    tomorrow.setDate(today.getDate() + 1);

    //get courses starting today
    for (let i = 0; i < courses.length; i++) {
      let course = courses[i];
      course.courseSessions.map(session => {
        course.userRelevantSessions.map(relevantSession => {
          if (canJoinSession(relevantSession) && session.id === relevantSession.id) {
            let startingSession = new Date(
              getLocalDateFromString(session.minStartDate, userProfile?.timezone)
            );
            let finishDate = new Date(
              getLocalDateFromString(session.sessionDates[0].timeFinish, userProfile?.timezone)
            );

            //starting in less tha 15 MIN and
            let diffStarting = startingSession.getTime() - today.getTime();
            let diffFinishing = finishDate.getTime() - today.getTime();

            //if VC is starting --> set showJoin, showLiveCountdown and showLiveBadge = true
            //else show 15 minutes countdown --> showJoin, showLiveCountdown = true
            if (diffStarting <= 900000 && diffFinishing > 0) {
              // 1) show join button
              let courseToChange = coursesMap[course.courseIdMaster];
              courseToChange.showJoin = true;
              courseToChange.showLiveCountdown = true;
              if (diffStarting <= 0) {
                courseToChange.showLiveBadge = true;
              }
              console.log('courseToChange', courseToChange);

              coursesMap[course.courseIdMaster] = courseToChange;

              dispatch({
                type: actionTypes.CHANGE_COURSE_MAP,
                coursesMap,
              });

              console.log('course joining ', courseToChange);

              updateCourseArrays(dispatch, getState, courseIdSubarrayMap, courseToChange);

              //2) set timeout per mostrare badge live now se al momento mostriamo solo countdown
              if (!courseToChange.showLiveBadge) {
                let timeJoin = diffStarting;

                setTimeout(() => {
                  //course would be updated in the meantime
                  let coursesMapAfterTimeout = { ...getState().course.coursesMap };
                  let courseIdMaster = course.courseIdMaster;
                  if (coursesMapAfterTimeout[courseIdMaster]) {
                    coursesMapAfterTimeout[courseIdMaster].showLiveBadge = true;

                    console.log('Show joining ', coursesMapAfterTimeout[courseIdMaster]);

                    //update course in redux
                    dispatch(
                      updateCourseInCatalog(
                        coursesMapAfterTimeout[courseIdMaster],
                        courseIdMaster,
                        '',
                        true
                      )
                    );
                  }
                }, timeJoin);
              }

              //get ending time of session
              //hide the join button at the session ending
              let time = diffFinishing;

              //3) set timeout di due ore per nascondere join
              setTimeout(() => {
                //course would be updated in the meantime
                let coursesMapAfterTimeout = { ...getState().course.coursesMap };
                let courseIdMaster = course.courseIdMaster;
                if (coursesMapAfterTimeout[courseIdMaster]) {
                  coursesMapAfterTimeout[courseIdMaster].showJoin = false;
                  coursesMapAfterTimeout[courseIdMaster].showLiveBadge = false;
                  coursesMapAfterTimeout[courseIdMaster].showLiveCountdown = false;

                  console.log('Hide joining ', coursesMapAfterTimeout[courseIdMaster]);

                  //update course in redux
                  dispatch(
                    updateCourseInCatalog(
                      coursesMapAfterTimeout[courseIdMaster],
                      courseIdMaster,
                      '',
                      true
                    )
                  );
                }

                // dispatch({
                //     type: actionTypes.CHANGE_COURSE_MAP,
                //     coursesMap
                // });
                // updateCourseArrays(dispatch, getState, courseIdSubarrayMap, courseToChange);
              }, time);

              //3) togliere da joining Courses
              let indexR = null;
              joiningCourses.map((joinCourse, index) => {
                if (joinCourse.courseIdMaster === course.courseIdMaster) {
                  indexR = index;
                }
              });
              joiningCourses.splice(indexR, 1);
              isChanged = true;
            }

            if (diffFinishing < 0) {
              let indexR = null;
              joiningCourses.map((joinCourse, index) => {
                if (joinCourse.courseIdMaster === course.courseIdMaster) {
                  indexR = index;
                }
              });
              joiningCourses.splice(indexR, 1);
              isChanged = true;
            }
          }
          // else if(session.id === relevantSession.id){
          //     let startingSession = new Date(session.minStartDate);
          //     let finishDate = new Date(session.sessionDates[0].timeFinish);

          //     //starting in less tha 15 MIN and
          //     let diffStarting = startingSession.getTime() - today.getTime();
          //     let diffFinishing = finishDate.getTime() - today.getTime();
          //     let timeOutTime = milliseconds(2,0,0);

          //     if(diffStarting <= 900000 && diffFinishing > -timeOutTime){
          //         let courseToChange = coursesMap[course.courseIdMaster]
          //         courseToChange.hideCancelBooking = true;

          //         coursesMap[course.courseIdMaster] = courseToChange;

          //         dispatch({
          //             type: actionTypes.CHANGE_COURSE_MAP,
          //             coursesMap
          //         });

          //         console.log("do not show cancel booking ", courseToChange)

          //         updateCourseArrays(dispatch, getState, courseIdSubarrayMap, courseToChange);
          //     }
          // }
        });
      });
    }

    if (isChanged) {
      dispatch(setJoiningCourses(joiningCourses));
    }

    if (joiningCourses.length > 0) {
      let mostRecentCourse = null;
      let sessionTimeout = null;
      //get first joining course
      joiningCourses.map(course => {
        course.courseSessions.map(session => {
          course.userRelevantSessions.map(relevantSession => {
            let sessionToCompare = new Date(session.minStartDate);

            if (canJoinSession(relevantSession) && session.id === relevantSession.id) {
              if (mostRecentCourse) {
                mostRecentCourse.courseSessions.map(mostSession => {
                  mostRecentCourse.userRelevantSessions.map(relevantRecent => {
                    if (canJoinSession(relevantRecent) && mostSession.id === relevantRecent.id) {
                      let recentToCompare = new Date(mostSession.minStartDate);

                      if (sessionToCompare.getTime() < recentToCompare.getTime()) {
                        mostRecentCourse = course;
                        sessionTimeout = session.minStartDate;
                      }
                    }
                  });
                });
              } else {
                mostRecentCourse = course;
                mostRecentCourse.courseSessions.map(mostSession => {
                  mostRecentCourse.userRelevantSessions.map(relevantRecent => {
                    if (canJoinSession(relevantRecent) && mostSession.id === relevantRecent.id) {
                      sessionTimeout = session.minStartDate;
                    }
                  });
                });
              }
            }
          });
        });
      });

      if (mostRecentCourse && sessionTimeout) {
        console.log('most recent ', mostRecentCourse);
        console.log('session timeout ', sessionTimeout);
        let diff2 = new Date(sessionTimeout).getTime() - today.getTime();

        if (diff2 < milliseconds(48, 0, 0)) {
          //set timeout
          let timeO = diff2 - 900000;
          console.log('most recent timeout', timeO);
          setTimeout(() => {
            dispatch(setTimerJoin());
          }, timeO);
        }
      }
    }
  };
};

export const setJoiningLivestreams = joiningLivestreams => {
  return {
    joiningLivestreams,
    type: actionTypes.GET_JOINING_LIVESTREAM,
  };
};

export const setLivestreamTimerJoin = () => {
  return (dispatch, getState) => {
    const courses = getState().course.joiningLivestreams;
    const userProfile = getState().user.userProfile;
    // console.log('setLivestreamTimerJoin', courses)

    let coursesMap = { ...getState().course.coursesMap },
      courseIdSubarrayMap = { ...getState().course.courseIdSubarrayMap },
      joiningCourses = cloneDeep(courses),
      today = new Date(),
      tomorrow = new Date(),
      isChanged = false;

    tomorrow.setDate(today.getDate() + 1);

    //get courses starting today
    for (let i = 0; i < courses.length; i++) {
      let course = courses[i];

      const liveInfo = course.liveInfo?.[0];
      if (liveInfo) {
        let startingSession = new Date(
          getLocalDateFromString(liveInfo.timeStart, userProfile?.timezone)
        );
        let finishDate = new Date(getLocalDateFromString(liveInfo.timeEnd, userProfile?.timezone));

        let diffStarting = startingSession.getTime() - today.getTime();
        let diffFinishing = finishDate.getTime() - today.getTime();

        //if livestream is starting in 15 minutes
        if (diffStarting <= 900000 && diffFinishing > 0) {
          // 1) show join button
          let courseToChange = coursesMap[course.courseIdMaster];

          //if livestream is starting --> set showJoin, showLiveBadge, showLiveCountdown = true
          //else show 15 minutes countdown --> showJoin, showLiveCountdown = true
          courseToChange.showJoin = true;
          courseToChange.showLiveCountdown = true;
          if (diffStarting <= 0) {
            courseToChange.showLiveBadge = true;

            //OUT OF SCOPE
            //generate local notification to join livestream
            // dispatch(generateLocalNotif(courseToChange.courseIdMaster, updateType.LIVESTREAM_JOIN, diffFinishing, true));
          } else {
            courseToChange.showLiveCountdown = true;

            //OUT OF SCOPE
            //generate local notification course is starting in 15 minutes
            // dispatch(generateLocalNotif(courseToChange.courseIdMaster, updateType.LIVESTREAM_STARTING, diffStarting, true));
          }

          // coursesMap[course.courseIdMaster] = courseToChange;

          // dispatch({
          //     type: actionTypes.CHANGE_COURSE_MAP,
          //     coursesMap
          // });

          console.log('course joining ', courseToChange);

          // updateCourseArrays(dispatch, getState, courseIdSubarrayMap, courseToChange);
          dispatch(updateCourseInCatalog(courseToChange, courseToChange.courseIdMaster, '', true));

          //2) set timeout per mostrare join se al momento mostriamo solo countdown
          if (!courseToChange.showLiveBadge) {
            let timeJoin = diffStarting;

            setTimeout(() => {
              //course would be updated in the meantime
              let coursesMapAfterTimeout = { ...getState().course.coursesMap };
              let courseIdMaster = course.courseIdMaster;
              if (coursesMapAfterTimeout[courseIdMaster]) {
                coursesMapAfterTimeout[courseIdMaster].showLiveBadge = true;

                console.log('Show joining ', coursesMapAfterTimeout[courseIdMaster]);

                //OUT OF SCOPE
                //generate local notification to join livestream
                // dispatch(generateLocalNotif(courseToChange.courseIdMaster, updateType.LIVESTREAM_JOIN, diffFinishing, true));

                //update course in redux
                dispatch(
                  updateCourseInCatalog(
                    coursesMapAfterTimeout[courseIdMaster],
                    courseIdMaster,
                    '',
                    true
                  )
                );
              }
            }, timeJoin);
          }

          //get ending time of session
          let time = diffFinishing;

          //3) set timeout per nascondere join
          setTimeout(() => {
            //course would be updated in the meantime
            let coursesMapAfterTimeout = { ...getState().course.coursesMap };
            let courseIdMaster = course.courseIdMaster;
            if (coursesMapAfterTimeout[courseIdMaster]) {
              coursesMapAfterTimeout[courseIdMaster].showJoin = false;
              coursesMapAfterTimeout[courseIdMaster].showLiveBadge = false;
              coursesMapAfterTimeout[courseIdMaster].showLiveCountdown = false;

              console.log('Hide joining ', coursesMapAfterTimeout[courseIdMaster]);

              //update course in redux
              dispatch(
                updateCourseInCatalog(
                  coursesMapAfterTimeout[courseIdMaster],
                  courseIdMaster,
                  '',
                  true
                )
              );
            }
          }, time);

          //4) togliere da joining Courses
          let indexR = null;
          joiningCourses.map((joinCourse, index) => {
            if (joinCourse.courseIdMaster === course.courseIdMaster) {
              indexR = index;
            }
          });
          joiningCourses.splice(indexR, 1);
          isChanged = true;
        }

        if (diffFinishing < 0) {
          let indexR = null;
          joiningCourses.map((joinCourse, index) => {
            if (joinCourse.courseIdMaster === course.courseIdMaster) {
              indexR = index;
            }
          });
          joiningCourses.splice(indexR, 1);
          isChanged = true;
        }
      }
    }

    if (isChanged) {
      dispatch(setJoiningLivestreams(joiningCourses));
    }

    if (joiningCourses.length > 0) {
      let mostRecentCourse = null;
      let sessionTimeout = null;
      //get first livestream joining course
      joiningCourses.map(course => {
        const liveInfo = course.liveInfo?.[0];
        if (liveInfo) {
          let sessionToCompare = new Date(
            getLocalDateFromString(liveInfo.timeStart, userProfile?.timezone)
          );

          if (mostRecentCourse) {
            let recentToCompare = new Date(mostRecentCourse.liveInfo?.[0]);

            if (sessionToCompare.getTime() < recentToCompare.getTime()) {
              mostRecentCourse = course;
              sessionTimeout = sessionToCompare;
            }
          } else {
            mostRecentCourse = course;
            sessionTimeout = sessionToCompare;
          }
        }
      });

      if (mostRecentCourse && sessionTimeout) {
        console.log('most recent ', mostRecentCourse);
        console.log('session timeout ', sessionTimeout);
        let diff2 = sessionTimeout.getTime() - today.getTime();

        if (diff2 < milliseconds(48, 0, 0)) {
          //set timeout
          let timeO = diff2 - 900000;
          console.log('most recent timeout', timeO);
          setTimeout(() => {
            dispatch(setLivestreamTimerJoin());
          }, timeO);
        }
      }
    }
  };
};

export const courseStarted = courseId => {
  return (dispatch, getState) => {
    let url = urlList.COURSE_STARTED_URL;

    axios
      .get(url, {
        headers: getHeaders(),
        params: {
          courseId,
        },
      })
      .then(response => {
        const course = response.data;
        dispatch(updateCourseInCatalog(course, course.courseIdMaster, '', false));
        dispatch(checkIfUpdateOnboarding(course));
      })
      .catch(err => {
        dispatch(showError(err));
      });
  };
};

export const videoLessonSubscribe = courseId => {

  return () => {
    let url = urlList.VIDEO_LESSON_SUBSCRIBE_URL;

    axios
      .get(url, {
        headers: getHeaders(),
        params: {
          courseId: courseId
        },
      })
      .catch(err => { console.error('Error videoLessonSubscribe:', err); });
  };
};

export const updateCourseInModal = coursesMap => {
  return (dispatch, getState) => {
    const currentCourse = getState().course.currentCourse;
    if (!currentCourse) {
      return;
    }

    let courseUpdated = coursesMap[currentCourse.courseIdMaster];

    let toPrint = printCorrectCourseInModal(currentCourse, courseUpdated);
    if (isCourseVisible(toPrint, false)) {
      dispatch(setCurrentCourseInModal(toPrint));
      if (!isLearningPath(courseUpdated)) dispatch(getCourseDetails(toPrint));
    } else {
      dispatch(toggleModal(false));
    }
  };
};

export const openCourseDetailByUrl = course => {
  return (dispatch, getState) => {
    console.log('openCourseDetailByUrl', course);

    let canOpenCourse = true;
    const notificationModalIsOpen = document.querySelector(".notifications-side-modal");
    if (course.tags.includes(TAGS.noStandalone)) {
      //no_standalone lessons cannot be opened by a direct url
      //they have to be opened passing through a LP
      canOpenCourse = false;
      const currentCourse = getState().course.currentCourse;
      course.parentLP?.forEach(lp => {
        if (
          window.location.pathname ===
            USER_URLS.ONBOARDING_COURSE_ID.URL.replace(':id', lp.courseId) ||
          currentCourse?.courseIdMaster === lp.courseId ||
          window.location.pathname.includes(USER_URLS.PROFILE_MY_PROGRESS.URL) ||
          window.location.pathname.includes(USER_URLS.BUILD_YOUR_CAREER.URL)
        ) {
          canOpenCourse = true;
        }
      });
    }

    if (canOpenCourse) {
      if (canCourseBeAccessed(course) && !notificationModalIsOpen) {
        dispatch(getCourseDetails(course));
      }
      dispatch(toggleModal(true, course, false, false, true));
      dispatch(setIsCourseDetailOpenedByUrl(true));
    } else {
      window.location.search = '';
    }
  };
};

export const setIsCourseDetailOpenedByUrl = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_IS_COURSE_DETAIL_OPENED_BY_URL,
      isCourseDetailOpenedByUrl: value,
    });
  };
};

export const toggleTeacherModal = (showTeacherModal, teacher = null) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.TOGGLE_TEACHER_MODAL,
      showTeacherModal: showTeacherModal,
      teacherModal: teacher,
    });
  };
};

export const toggleRatingModal = (value, course) => {
  return dispatch => {
    dispatch({
      type: actionTypes.TOGGLE_RATING_MODAL,
      value,
      course,
    });
  };
};
export const toggleNextUpModal = (value, course) => {
  return dispatch => {
    dispatch({
      type: actionTypes.TOGGLE_NEXT_UP_MODAL,
      value,
      course,
    });
  };
};

export const openConfirmBookingModal = (courseId, sessionId, isSessionSignUp) => {
  return dispatch => {
    dispatch({
      type: actionTypes.OPEN_CONFIRM_BOOKING_MODAL,
      bookingModalParams: {
        open: true,
        courseId,
        sessionId,
        isSessionSignUp,
      },
    });
  };
};

export const closeConfirmBookingModal = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLOSE_CONFIRM_BOOKING_MODAL,
    });
  };
};

export const toggleDownloadLinkModal = (showModal, link, courseId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_DOWNLOAD_LINK_MODAL,
      showModal,
      link, 
      courseId
    })
  }
}

export const getYouMayAlsoLike = courseIdMaster => {
  return (dispatch, getState) => {
    // console.log('courseIdMaster', courseIdMaster)
    if (!courseIdMaster && typeof courseIdMaster !== 'string') {
      return;
    }

    let url = urlList.GET_YMAL_URL;
    dispatch(request());

    axios(url, {
      method: 'GET',
      headers: getHeaders(),
      params: {
        courseId: courseIdMaster,
      },
      headers: getHeaders(),
    })
      .then(response => {
        // console.log('getYouMayAlsoLike', response.data)
        let coursesIdYmal = response.data?.courseIds;
        let coursesMap = { ...getState().course.coursesMap },
          ymal = coursesIdYmal
            .map(courseIdMaster => coursesMap?.[courseIdMaster])
            .filter(course => !!course && isCourseVisible(course));
        dispatch(success(courseIdMaster, ymal));
      })
      .catch(err => {
        console.error('Error during You May Also Like request', err);
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_YMAL_REQUEST,
    };
  }

  function success(courseId, ymal) {
    return {
      type: actionTypes.GET_YMAL_SUCCESS,
      courseId,
      ymal,
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_YMAL_FAILURE,
    };
  }
};

export const cleanCompletedCourses = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLEAN_COMPLETED_COURSES,
    });
  };
};

export const setPreviewVideoUrl = previewVideoUrl => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_PREVIEW_VIDEO_URL,
      previewVideoUrl,
    });
  };
};

export const enrollLp = (enroll, courseIdMaster) => {
  return (dispatch, getState) => {
    dispatch(request());
    let url = urlList.LP_ENROLL_URL;

    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
      params: {
        courseId: courseIdMaster,
        subscribe: enroll,
      },
    })
      .then(response => {
        let course = response.data;

        dispatch(updateCourseInCatalog(course, courseIdMaster, dispatch, getState));

        dispatch(success());
      })
      .catch(err => {
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.LP_ENROLL_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.LP_ENROLL_SUCCESS,
    };
  }

  function failure() {
    return {
      type: actionTypes.LP_ENROLL_FAILURE,
    };
  }
};

const isVideoOrPodcastActivity = activity => {
  let isVideoOrPod = false;

  if (activity.type === 'url') {
    const tags = activity.tags;

    if (!tags) {
      return isVideoOrPod;
    }

    tags.forEach(tag => {
      if (tag.includes(TAGS.isActivityVideo) || tag.includes(TAGS.isActivityPodcast)) {
        isVideoOrPod = true;
      }
    });
  }

  return isVideoOrPod;
};

export const handleCloseIframe = (course, avoidWhatsNext = false, isCompleted) => {
  return (dispatch, getState) => {
    //open course rating
    //if course already rated --> check what's next modal
    const coursesMap = getState().course.coursesMap;
    let learningPath = coursesMap[course?.parentLP[0]?.courseId];
    getCompletedCourseforLP(learningPath, coursesMap, true);
    let isOnboardingPage = window.location.href.includes(USER_URLS.ONBOARDING_COURSE.URL);
    // console.log("isCourseToBeRated(course)", isCourseToBeRated(course));
    // console.log("isLearningPath(learningPath)", isLearningPath(learningPath));
    // console.log("!isMultiActivity(course)", !isMultiActivity(course));
    // console.log("isLPCompletedForFeedback(learningPath)", isLPCompletedForFeedback(learningPath));

    //handle show survey
    let showRatingModal = false;
    /*if (isCourseToBeRated(course)) {
      if (isMultiActivity(course)) {
        //MULTIACTIVITY
        //check if multiactivity is completed or all its activities are completed
        if (isMultiActivityCompletedForFeedback(course)) {
          dispatch(toggleRatingModal(true, course));
          showRatingModal = true;
        }
      } else if (isLearningPath(learningPath) && isLPCompletedForFeedback(learningPath)) {
        //check if LP is completed or all its lessons are completed
        //LEARNING PATH
        dispatch(toggleRatingModal(true, learningPath));
        showRatingModal = true;
      } else if (isVideoCourse(course) || isPodcast(course)) {
        //VIDEO OR PODCAST
        //check if video is completed or the user just saw 80% or more
        if (isCompleted || isCompletedCourse(course)) {
          dispatch(toggleRatingModal(true, course));
          showRatingModal = true;
        }
      } else {
        //in other cases --> to be always shown
        dispatch(toggleRatingModal(true, course));
        showRatingModal = true;
      }
    }*/

    //se la survey non è stata mostrata
    if (!showRatingModal && !avoidWhatsNext) {
      dispatch(handleWhatsNextModal(course));
    }

    // reset this check every time IframePopup get closed
    dispatch(setAutoManageHistoryIframeOnClose(true))
    // if (isOnboardingPage) {
    //     window.location.search = '';
    // }
  };
};

export const handleWhatsNextModal = lesson => {
  return (dispatch, getState) => {
    if (!lesson) {
      return;
    }

    const coursesMap = getState().course.coursesMap;
    const currentCourse = getState().course.currentCourse;

    //open only if currentCourse is an enrolled LP
    if (isLpEnrolled(currentCourse)) {
      // find next not completed lesson in course
      let lpLessons = getCoursesWithinLP(currentCourse, coursesMap);
      const indexRatingLesson = lpLessons.findIndex(
        a => a.courseIdMaster === lesson.courseIdMaster
      );
      if (indexRatingLesson > -1) {
        const nextLesson = lpLessons[indexRatingLesson + 1];
        if (nextLesson && !nextLesson.isNotYetAvailable) {
          dispatch(toggleNextUpModal(true, [nextLesson]));
        }
      }
    }
  };
};

export const openActivity = (activity, course, subtitle) => {
  return (dispatch, getState) => {
    let iOS = isMobile.iOS() && !window.MSStream;

    // if(iOS && data.ctype[0] === "pdf"){
    if (
      (iOS && activity.type && activity.type.toLowerCase() === 'resource') ||
      (activity.type &&
        activity.type.toLowerCase() === 'url' &&
        !isVideoOrPodcastActivity(activity))
    ) {
      //open course in another tab
      window.open(activity.url);

      dispatch(handleCloseIframe(course));
    } else if (isVideoOrPodcastActivity(activity)) {
      console.log('subtitle?.code', subtitle?.code);
      let url = activity.url;
      let urlToCheck = null;
      if (activity.tags?.includes(TAGS.publishDirect)) {
        url = activity.directUrl;
        urlToCheck = activity.url;
      }

      //prevent relative path
      if (!isPathAbsolute(url)) {
        url = '';
      }

      dispatch(
        toggleIframePopup(
          true,
          course,
          addSubtitleParam(url, subtitle?.code),
          urlToCheck,
          true,
          null,
          activity.moduleId,
          subtitle?.code
        )
      );
    } else {
      dispatch(
        toggleIframePopup(
          true,
          course,
          activity.url,
          null,
          false,
          null,
          activity.moduleId,
          subtitle?.code
        )
      );
    }

    if (course.userCourseStatus === 'available') {
      dispatch(courseStarted(course.courseIdMaster));
    }
  };
};

export const toggleModalCourseById = (
  courseIdMaster,
  redirectTo404 = false,
  forceOnBoarding = false
) => {
  return (dispatch, getState) => {
    const coursesMap = getState().course.coursesMap;
    if (!courseIdMaster || !coursesMap) {
      return;
    }

    const course = coursesMap[courseIdMaster];

    if (course) {
      if (isCourseOnBoarding(course)) {
        //go to onboarding page and close every course detail modal
        if (
          !window.location.pathname.includes(USER_URLS.ONBOARDING_COURSE_ID.NORMAL_URL) ||
          forceOnBoarding
        ) {
          history.push(USER_URLS.ONBOARDING_COURSE_ID.URL.replace(':id', course.courseIdMaster));
        }
        dispatch(toggleModal(false, null, true));
      } else {
        dispatch(toggleModal(true, course));
      }
    } else {
      console.error(`course not found ${courseIdMaster}`);

      if (redirectTo404) {
        history.push(USER_URLS.NOT_FOUND.URL);
      }
    }
  };
};

export const createSessionIcs = (courseIdMaster, sessionId = '') => {
  return (dispatch, getState) => {
    const coursesMap = getState().course.coursesMap;
    const userProfile = getState().user.userProfile;
    const lang = getState().utils.lang;

    createIcs(courseIdMaster, sessionId, coursesMap, userProfile?.timezone, lang);
  };
};

export const sendLivestreamCompletion = (course, duration) => {
  return (dispatch, getState) => {
    if (!course || !isLivestreamCourse(course) || !course.liveInfo?.[0]?.moduleId) {
      return;
    }

    let url = urlList.LIVESTREAM_COMPLETION;
    url += '?moduleId=' + course.liveInfo[0].moduleId;
    url += '&courseId=' + course.courseIdMaster;
    url += '&duration=' + duration;

    // dispatch(request());

    axios(url, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        let newCourse = response.data;
        dispatch(updateCourseInCatalog(newCourse, newCourse.courseIdMaster, '', true));

        // dispatch(success());
      })
      .catch(err => {
        console.error('Error during sendLivestreamCompletion', err);
        // dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.SEND_LIVE_COMPLETION_REQUEST,
    };
  }

  function success() {
    return {
      type: actionTypes.SEND_LIVE_COMPLETION_SUCCESS,
    };
  }

  function failure() {
    return {
      type: actionTypes.SEND_LIVE_COMPLETION_FAILURE,
    };
  }
};

export const handleClickL1Card = (type, l1Value) => {
  return dispatch => {
    dispatch(addPreviouslySearched());

    goToBrandPage(history, type, l1Value);
  };
};

export const triggerAppJsUpdate = () => {
  return {
    type: actionTypes.TRIGGER_APPJS_UPDATE,
  };
};

export const completeCertificateActivity = courseId => {
  if (!courseId) {
    console.error('completeCertificateActivity - courseId not found');
    return;
  }

  let url = urlList.COMPLETE_CERTIFICATE_URL;

  console.log('Getting course overview for certificate ' + courseId);

  axios
    .get(url, {
      headers: getHeaders(),
      params: {
        courseId: courseId,
      },
    })
    .then(response => {
      console.log('Certificate ' + courseId + ' - request completion sent');
    });
};

export const getInProgressCourses = () => {

  return dispatch => {

    dispatch(request());

    axios({
      url: urlList?.GET_COURSE_USER_PROGRESS_URL,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        if (!response || !response.data || response?.data?.length < 1) {
          dispatch(failure());
        }
        dispatch(success(response.data))
      })
      .catch(err => {
        dispatch(failure());
        dispatch(showError(err));
      })
  };

  function request() {
    return {
      type: actionTypes.GET_IN_PROGRESS_COURSES_REQUEST,
    };
  }

  function success(inProgressCourses) {
    return {
      type: actionTypes.GET_IN_PROGRESS_COURSES_SUCCESS,
      inProgressCourses: inProgressCourses
    };
  }

  function failure() {
    return {
      type: actionTypes.GET_IN_PROGRESS_COURSES_FAILURE,
    };
  }
};
