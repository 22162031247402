import { useState, useEffect } from "react";
import { isActivityBlocked, isMultiActivity, printActivityType } from "@utility/Api";
import { Course, CourseActivity, CoursesMap, LangMap } from "@model/CoursesClass";
import { useSelector } from "./redux";
import { openActivity } from "@redux-actions/";
import { getActivityCompletionPercentage, getActivityDuration } from "@utility/activityUtility";
import { useDispatch } from "react-redux";
import { isCourseWithVersioning } from "@utility/CourseUtility";
import { openContentLanguageModal } from "@utility/ContentLanguage/contentLanguageUtility";

export class DetailMultiactivityClass {
  id: string;
  name: string;
  description?: string;
  ctypeName: string;
  duration: string;
  valueBar: number;
  onClickPlayIcon: () => void;
  isBlocked: boolean;
  orderNumber: number;
  instance: string;
}

const useGetDetailsMultiactivity = (course: Course, subtitle: string): DetailMultiactivityClass[] => {
  const dispatch = useDispatch();

  const lang: LangMap = useSelector((state) => state.utils.lang);
  const coursesMap: CoursesMap = useSelector((state) => state.course.coursesMap);

  const [detailsMultiactivity, setDetailsMultiactivity] = useState([]);

  const handleOnClickPlayIcon = (activity: CourseActivity) => {
    if(isCourseWithVersioning(coursesMap, course.courseIdMaster)) {
      return openContentLanguageModal(dispatch)
    }
    return dispatch(openActivity(activity, course, { code: subtitle }))
  }

  useEffect(() => {
    const detailsTemp = [];

    if (isMultiActivity(course) && course?.courseDetails) {
      let orderNum = 1;

      course?.courseDetails
        .filter(section => section.sectionName !== "completed")
        .sort((a, b) => a.sectionPosition - b.sectionPosition)
        .forEach(section => {
          section.content
            ?.filter(activity => activity.type !== "customcert" && activity.type !== "feedback")
            .sort((a, b) => a.activityPosition - b.activityPosition)
            .forEach(activity => {
              detailsTemp.push({
                id: activity.moduleId,
                name: activity.name,
                description: activity?.description || "",
                ctypeName: printActivityType(activity, lang),
                duration: getActivityDuration(activity.tags),
                valueBar: getActivityCompletionPercentage(activity),
                onClickPlayIcon: () => handleOnClickPlayIcon(activity),
                isBlocked: isActivityBlocked(activity, section),
                orderNumber: orderNum++,
                instance: activity.instance,
              });
            })
        })
    }

    setDetailsMultiactivity(detailsTemp);
  }, [course?.courseDetails, lang, subtitle])

  return detailsMultiactivity;
};
export default useGetDetailsMultiactivity;
