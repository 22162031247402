import {Course, CourseState, CoursesMap, LangMap} from '@model/CoursesClass';
import { UserProfile } from '@model/User';
import {
  getExtendedMonthToPrint,
  isBookedCourse,
  isCompletedCourse,
  isCourseExpiring,
  isCourseVisible,
  isFaceToFace,
  isLearningPath,
  isLivestreamCourse,
  isMandatory,
  isMissedCourseForBadge,
  isNewCondition,
  isOverdueCourse,
  isStartedCourse,
  isTagPresent,
  isVirtualClassroom,
  orderToDos,
  printDate,
} from './Api';
import { BADGE, BADGE_PRIORITY, BADGE_PROGRAM, BADGE_PROGRAM_PRIORITY, COOKIE, TAGS, ctype } from './const';
import { isPurchased } from './ecommerceUtility';
import { isProgramSoldOut } from './EducationalPathsUtility';
import {
  isAssignmentLesson,
  isOneToOneLesson,
  isSupportMaterialLesson,
  isUserInWaitingListMaster,
} from './onBoardingCourseUtility';
import { getCookie } from './cookie';

export class BadgeCourseClass {
  label: string = '';
  color: string = '';
  priority: number;
}
export const getBadgeCourse = (
  course: Course,
  variant: string,
  lang: LangMap
): BadgeCourseClass => {
  let badgeLabel = '';
  let badgeColor = '';
  let badgePriority = undefined;

  if (course && lang) {
    const badges = getBadgesCourse(course);

    //for onboarding, the default color is white
    if (variant === 'onboarding') {
      badgeColor = 'white';
    }

    if (badges?.length > 0) {
      badgePriority = BADGE_PRIORITY[badges[0]];

      switch (badges[0]) {
        //3. done
        case BADGE.done:
          badgeColor = 'evolution-primary';
          badgeLabel = lang.DONE_LABEL;
          break;
        //4. overdue
        case BADGE.overdue:
          badgeColor = 'red';
          badgeLabel = lang.OVERDUE_LABEL;
          break;
        //5. missed
        case BADGE.missed:
          badgeColor = 'red';
          badgeLabel = lang.MISSED_LABEL;
          break;
        //6. expiring
        case BADGE.expiring:
          badgeColor = 'orange';
          badgeLabel = lang.EXPIRING_LABEL;
          break;
        //7. due soon
        case BADGE.duesoon:
          badgeColor = 'orange';
          badgeLabel = lang.DUESOON_LABEL;
          break;
        //10. booked
        case BADGE.booked:
          badgeLabel = lang.BOOKED;
          break;
        //11. mandatory
        case BADGE.mandatory:
          badgeLabel = lang.MANDATORY_LABEL;
          break;
        //12. new HIDDEN TEMPORALLY FOR TICKET LEON 2695
        /* case BADGE.new:
                    badgeLabel = lang.NEW_LABEL;
                    break;*/
        //13. coming soon
        case BADGE.coming_soon: 
          badgeLabel = lang.COMING_SOON;
          break            
      }
    }
  }

  return {
    label: badgeLabel,
    color: badgeColor,
    priority: badgePriority,
  };
};

export const getBadgesCourse = course => {
  if (!course) {
    return [];
  }

  // tutti i possibili badge delle card sono descritti nella sezione "Ribbon (ex badge) dentro le card"
  const badges = [];

  //3. done
  if (isCompletedCourse(course)) {
    badges.push(BADGE.done);
  }

  //4. overdue
  if (isOverdueCourse(course)) {
    badges.push(BADGE.overdue);
  }

  //5. missed
  if (isMissedCourseForBadge(course)) {
    badges.push(BADGE.missed);
  }

  //6. expiring
  if (!isMandatory(course) && isCourseExpiring(course)) {
    badges.push(BADGE.expiring);
  }

  //7. due soon
  if (isMandatory(course) && isCourseExpiring(course)) {
    badges.push(BADGE.duesoon);
  }

  //10. booked
  if (isBookedCourse(course) && (window.location.href.includes('upcoming') || window.location.href.includes('profile') || window.location.href.includes('courseDetail'))) {
    badges.push(BADGE.booked);
  }

  //11. mandatory
  if (isMandatory(course)) {
    badges.push(BADGE.mandatory);
  }

  //12. new
  if (isNewCondition(course)) {
    badges.push(BADGE.new);
  }

  //13. coming soon
  if(isCourseComingSoon(course)) {
    badges.push(BADGE.coming_soon)
  }

  //sort badges by priority
  const sortedBadges = badges.sort((a, b) => BADGE_PRIORITY[a] - BADGE_PRIORITY[b]);

  return sortedBadges;
};

export const getBadgeProgram = (
  course: Course,
  lang: LangMap,
  userProfile: UserProfile
): BadgeCourseClass => {
  let badgeLabel = '';
  let badgeColor = '';
  let badgePriority = undefined;

  if (course && lang) {
    const badges = getBadgesProgram(course, userProfile);

    if (badges?.length > 0) {
      badgePriority = BADGE_PROGRAM_PRIORITY[badges[0]];

      switch (badges[0]) {
        case BADGE_PROGRAM.purchased:
          badgeColor = 'evolution-primary';
          badgeLabel = lang.PURCHASED;
          break;
        case BADGE_PROGRAM.request_sent:
          badgeColor = 'white';
          badgeLabel = lang.REQUEST_SENT_LABEL;
          break;
        case BADGE_PROGRAM.sold_out:
          badgeColor = 'red';
          badgeLabel = lang.SOLD_OUT;
          break;
        case BADGE_PROGRAM.coming_soon:
          badgeColor = 'white';
          badgeLabel = lang.COMING_SOON;
          break;
      }
    }
  }

  return {
    label: badgeLabel,
    color: badgeColor,
    priority: badgePriority,
  };
};

export const getBadgesProgram = (course: Course, userProfile: UserProfile) => {
  if (!course) {
    return [];
  }
  const badges = [];

  if (isPurchased(course)) {
    badges.push(BADGE_PROGRAM.purchased);
  }
  if (isProgramSoldOut(course)) {
    badges.push(BADGE_PROGRAM.sold_out);
  }
  if (isUserInWaitingListMaster(course, userProfile)) {
    badges.push(BADGE_PROGRAM.request_sent);
  }
  if(isCourseComingSoon(course)) {
    badges.push(BADGE.coming_soon)
  }
  //sort badges by priority
  const sortedBadges = badges.sort((a, b) => BADGE_PROGRAM_PRIORITY[a] - BADGE_PROGRAM_PRIORITY[b]);

  return sortedBadges;
};

// export const getCourseStatus = (course, lang): string => {
//     //DEFINE ALL POSSIBLE STATUSES
//     //FOR NOW THE CASES ARE overdue, missed and booked
//     if (course && lang) {
//         if (isOverdueCourse(course)) {
//             return lang.OVERDUE_LABEL;
//         } else if (isMissedCourse(course)) {
//             return lang.MISSED_LABEL;
//         }

//         if (isBookedCourse(course)) {
//             return lang.BOOKED;
//         }
//     }

//     return '';
// }

export const hasCertificates = (course: Course) => {
  return course?.certificateLinks?.length > 0;
};

export const getLessonCompletionPercentage = (course: Course): number => {
  if (isCompletedCourse(course)) {
    return 100;
  } else if (isStartedCourse(course)) {
    return 50;
  } else {
    return 0;
  }
};

export const getCourseFullName = (crs: Course, lang: LangMap, careerProgramType?: string): string => {
  if (!crs) {
    return '';
  }
  // assignment, one to one, material support will have fixed name?
  // if (isAssignmentLesson(crs)) {
  //     return lang.ASSIGNMENT_TITLE;
  // }
  // if (isOneToOneLesson(crs)) {
  //     return lang.ONE_TO_ONE_TITLE;
  // }
  // if (isSupportMaterialLesson(crs)) {
  //     return lang.SUPPORT_MATERIAL_TITLE;
  // }
  if(careerProgramType) {
    return lang?.ROLE_SPECIFIC_PROGRAM_CONGRATULATIONS_DOWNLOAD_CERTIFICATE.replace("{role}", careerProgramType)
  }

  return crs.courseFullName;
};

export const printRangeDate = ({
  startDate,
  endDate,
  lang,
}: {
  startDate: string;
  endDate: string;
  lang: LangMap;
}) => {
  const startDateString = printDate(startDate, lang);
  const endDateString = printDate(endDate, lang);
  return startDateString + (endDateString ? ' - ' + endDateString : '');
};

export const isNoStandalone = (course: Course): boolean => {
  return isTagPresent(course?.tags, TAGS.noStandalone);
};

export const getMasterStartEndDate = (mcStartDate?: string, mcEndDate?: string, lang?: LangMap) => {
  if (mcStartDate == null || mcEndDate == null) return null;

    const getDayAndMonth = (stringDate: string) => {
        const date = new Date(stringDate);
        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString();
        month = getExtendedMonthToPrint(month, lang)?.toLowerCase();
        return [`${day} ${month}`, date.getFullYear()];
    }

  const [startDayMonth, startYear] = getDayAndMonth(mcStartDate);
  const [endDayMonth, endYear] = getDayAndMonth(mcEndDate);

  return `${startDayMonth} ${startYear} - ${endDayMonth} ${endYear}`;
};

export const isCourseEl360 = (course: Course): boolean => {
    return +course.price !== +course.fullPrice && +course.fullPrice > 0
    }
export const isCourseComingSoon = (course: Course) => {
  return isTagPresent(course?.tags, TAGS.isComingSoon);
};

export const isCourseGuru = (course: Course) => {
  return course.complexity.complexity === 'C4' && course.ctype.includes("self_learning_lesson")
}

export const isCourseAssessment = (course: Course) => {
  return (course?.ctype?.includes(ctype.ONLINE_COURSE) && (course?.complexity?.complexity === "C3" || course?.complexity?.id === "C3" ))
}

export const checkCourseState = (course:Course, state: CourseState )=>{
  return course?.courseStates.includes(state)
}


/**
 * Check if exists a cookie with that is still saving its progress (waiting for moodle to set as complete).
 * @see saveProgressInCookie
*/
export const isCourseInSavingProgress = (course: Course) : boolean =>{
  return getCookie(`${COOKIE?.COURSES_PROGRESS}_${course?.courseId}`) !== ""
}

export const isCourseRecommendedInToDo = (course: Course): boolean => {
  return !!course?.recommended
}

export const filterByRecommendedInToDo = (coursesMap: CoursesMap, ignoreCompleted = false): Course[] => {
  let filteredCoursesByRecommendedInToDo = [];

  for(let id in coursesMap) {
    const course = coursesMap[id];
    if(isCourseRecommendedInToDo(course) && isCourseVisible(course)) {

      if(ignoreCompleted) {
        if(!isCompletedCourse(course)) {
          filteredCoursesByRecommendedInToDo.push(course)
        }
      } else {
        filteredCoursesByRecommendedInToDo.push(course)
      }

    }
  }
  //use same sorting of toDos
  return orderToDos(filteredCoursesByRecommendedInToDo);
}

/**
 * Function useful to know when a course is with versioning or not giving coursesMap and courseId
 * @param catalogCourses the list of courses that user has in its catalog (CoursesMap)
 * @param courseIdMaster course id of the master course (this because the versioning attribute is set on the course master and not on childs)
 * @returns true IF course has the attribute versioning OTHERWISE false (we expect to not receive the attribute if not true)
 */
export const isCourseWithVersioning = (
  catalogCourses: CoursesMap,
  courseIdMaster: string
): boolean => {
  if(!catalogCourses || !courseIdMaster) return false
  const parentCourse = catalogCourses[courseIdMaster]
  // EXCLUDE LearningPath, VC (include F2F) and Live Stream
  if(isLearningPath(parentCourse) || isVirtualClassroom(parentCourse) || isLivestreamCourse(parentCourse)) {
    return false
  }
  return !!parentCourse?.versioning
}