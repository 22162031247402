import React from "react";

interface InformationBoxProps {
  text: string;
}

const InformationBox: React.FC<InformationBoxProps> = ({ text }) => {
  return (
    <div className="information-box">
      <p className="information-box__text">{text}</p>
    </div>
  );
};

export default InformationBox;