import React, { FC } from "react";
import { ErrorMessageV2Props, ErrorTypes } from "../../types/UI/ErrorMessageV2";

const ErrorMessageV2: FC<ErrorMessageV2Props> = ({
    className,
    error
}) => {

    if (!error || !error.type) return null;

    return (
        <>
            {/* use role="alert" to announce the error message */}
            {error.type === ErrorTypes.FIELD_REQUIRED &&
                <span role="alert" className={className}>
                    {error.message && error.message}
                </span>
            }
        </>
    );
};

export default ErrorMessageV2;