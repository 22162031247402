import React from 'react';
import CommunityIcon from '@svg-icons/manager-assignment/Community.svg';
import ArrowIcon from '@svg-icons/manager-assignment/ArrowRound.svg';
import BellIcon from '@svg-icons/manager-assignment/Notification.svg';
import { useSelector } from 'react-redux';
import { getManagerAssignmentText } from '@utility/Api';

/**
 * Function to return if user is a manager
 * @param role 
 * @returns true if the user has the role manager
 */
export const isUserManager = (role: string[]): boolean => {
    return role && role.includes('manager')
}

export const getTutorialAssignmentBoxesItems = (lang: any): {icon: React.ReactNode, text: string}[] => {
    const managerAssignCategory = useSelector(state => state.user.userProfile.managerAssignmentCategory);

    const tutorialAssignmentBoxesItems = [
        { icon: <CommunityIcon /> , text: getManagerAssignmentText(managerAssignCategory, lang, "MANAGER_ASSIGNMENT_BOX_1") },
        { icon: <ArrowIcon />, text: getManagerAssignmentText(managerAssignCategory, lang, "MANAGER_ASSIGNMENT_BOX_2") },
        { icon: <BellIcon />, text: getManagerAssignmentText(managerAssignCategory, lang, "MANAGER_ASSIGNMENT_BOX_3")},
    ];

    return tutorialAssignmentBoxesItems

}