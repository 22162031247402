/* COURSES */
export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAILURE = 'GET_COURSES_FAILURE';

export const GET_COURSE_REQUEST = 'GET_COURSE_REQUEST';
export const GET_COURSE_END = 'GET_COURSE_END';

export const CHANGE_COURSE_MAP = 'CHANGE_COURSE_MAP';
export const SET_VISIBILITY_SEARCH = 'SET_VISIBILITY_SEARCH';
export const CHANGE_TAB_HOME = 'CHANGE_TAB_HOME';

export const UPDATE_COURSE_ID_SUBARRAY_MAP = 'UPDATE_COURSE_ID_SUBARRAY_MAP';

export const GET_IN_PROGRESS_COURSES_REQUEST = 'GET_IN_PROGRESS_COURSES_REQUEST'
export const GET_IN_PROGRESS_COURSES_SUCCESS = 'GET_IN_PROGRESS_COURSES_SUCCESS'
export const GET_IN_PROGRESS_COURSES_FAILURE = 'GET_IN_PROGRESS_COURSES_FAILURE'
export const RESET_IN_PROGRESS_COURSES = 'RESET_IN_PROGRESS_COURSES'

/* COURSES SUBARRAY */
/* If the name of the array is arrayName, the actiontype should be composed like this: GET_ + ARRAY_NAME */
/* where the camel case gets replaced with underscore */
export const GET_COMPLETED_COURSES = 'GET_COMPLETED_COURSES';
export const SET_TIME_ON_TRAINING = 'SET_TIME_ON_TRAINING';
export const GET_WISHLIST_REQUEST = 'GET_WISHLIST_REQUEST';
export const GET_WISHLIST_SUCCESS = 'GET_WISHLIST_SUCCESS';
export const GET_WISHLIST_FAILED = 'GET_WISHLIST_FAILED';
export const SET_SUGGESTION_INPUT = 'SET_SUGGESTION_INPUT';
export const GET_SUBSCRIBED_COURSES = 'GET_SUBSCRIBED_COURSES';
export const GET_JOINING_COURSES = 'GET_JOINING_COURSES';
export const GET_JOINING_LIVESTREAM = 'GET_JOINING_LIVESTREAM';
export const GET_UPCOMING_EVENTS = 'GET_UPCOMING_EVENTS';
export const GET_LIVESTREAMS = 'GET_LIVESTREAMS';

export const CLEAN_COMPLETED_COURSES = 'CLEAN_COMPLETED_COURSES';

export const GET_COURSE_COUNTER_VIRTUAL = 'GET_COURSE_COUNTER_VIRTUAL';
export const GET_COURSE_COUNTER_ONLINE = 'GET_COURSE_COUNTER_ONLINE';

export const UPDATE_WISHLIST_REQUEST = 'UPDATE_WISHLIST_REQUEST';
export const UPDATE_WISHLIST_SUCCESS = 'UPDATE_WISHLIST_SUCCESS';
export const UPDATE_WISHLIST_FAILURE = 'UPDATE_WISHLIST_FAILURE';

export const BOOK_SESSION_REQUEST = 'BOOK_SESSION_REQUEST';
export const BOOK_SESSION_SUCCESS = 'BOOK_SESSION_SUCCESS';
export const BOOK_SESSION_FAILURE = 'BOOK_SESSION_FAILURE';

export const TOGGLE_LIKE_REQUEST = 'TOGGLE_LIKE_REQUEST';
export const TOGGLE_LIKE_SUCCESS = 'TOGGLE_LIKE_SUCCESS';
export const TOGGLE_LIKE_FAILURE = 'TOGGLE_LIKE_FAILURE';

export const GET_COURSE_0_COMPLETED = 'GET_COURSE_0_COMPLETED';
export const GET_COURSES_0_REQUEST = 'GET_COURSES_0_REQUEST';
export const GET_COURSES_0_FAILURE = 'GET_COURSES_0_FAILURE';
export const GET_COURSE_COMPLETION_REQUEST = 'GET_COURSE_COMPLETION_REQUEST';
export const SET_IS_COURSE_COMPLETION_COMPLETED = 'SET_IS_COURSE_COMPLETION_COMPLETED';
export const GET_COURSE_COMPLETION_COMPLETED = 'GET_COURSE_COMPLETION_COMPLETED';
export const GET_COURSE_COMPLETION_BY_ID_REQUEST = 'GET_COURSE_COMPLETION_BY_ID_REQUEST';
export const GET_COURSE_COMPLETION_BY_ID_SUCCESS = 'GET_COURSE_COMPLETION_BY_ID_SUCCESS';
export const GET_COURSE_COMPLETION_BY_ID_FAILURE = 'GET_COURSE_COMPLETION_BY_ID_FAILURE';

export const RESET_VIRTUAL = 'RESET_VIRTUAL';

export const OPEN_CONFIRM_BOOKING_MODAL = 'OPEN_CONFIRM_BOOKING_MODAL';
export const CLOSE_CONFIRM_BOOKING_MODAL = 'CLOSE_CONFIRM_BOOKING_MODAL';

// YOU MAY ALSO LIKE
export const GET_YMAL_REQUEST = 'GET_YMAL_REQUEST';
export const GET_YMAL_SUCCESS = 'GET_YMAL_SUCCESS';
export const GET_YMAL_FAILURE = 'GET_YMAL_FAILURE';

/* SUBCATALOGUE PAGES */
export const GET_FILTERS = 'GET_FILTERS';
export const RESET_FILTERED_COURSES = 'RESET_FILTERED_COURSES';
export const SET_CATALOGUE_COURSES = 'SET_CATALOGUE_COURSES';
export const RESET_CATALOGUE_COURSES = 'RESET_CATALOGUE_COURSES';
export const SET_CATALOGUE_LP = 'SET_CATALOGUE_LP';
export const RESET_CATALOGUE_LP = 'RESET_CATALOGUE_LP';

export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const RESET_SEARCH = 'RESET_SEARCH';

/* USER */
export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const GET_USER_PROFILE_UPDATE_REQUEST = 'GET_USER_PROFILE_UPDATE_REQUEST';
export const GET_USER_PROFILE_UPDATE_SUCCESS = 'GET_USER_PROFILE_UPDATE_SUCCESS';
export const GET_USER_PROFILE_UPDATE_FAILURE = 'GET_USER_PROFILE_UPDATE_FAILURE';
export const RESET_USER_PROFILE_UPDATE = 'RESET_USER_PROFILE_UPDATE';

export const GET_LEADERBOARD_REQUEST = 'GET_LEADERBOARD_REQUEST';
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_FAILURE = 'GET_LEADERBOARD_FAILURE';

export const GET_LEADERBOARDCY_REQUEST = 'GET_LEADERBOARDCY_REQUEST';
export const GET_LEADERBOARDCY_SUCCESS = 'GET_LEADERBOARDCY_SUCCESS';
export const GET_LEADERBOARDCY_FAILURE = 'GET_LEADERBOARDCY_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const UPDATE_LEARNER_NAME_AND_DOWNLOAD_CERT_REQUEST = "UPDATE_LEARNER_NAME_AND_DOWNLOAD_CERT_REQUEST";
export const UPDATE_LEARNER_NAME_AND_DOWNLOAD_CERT_SUCCESS = "UPDATE_LEARNER_NAME_AND_DOWNLOAD_CERT_SUCCESS";
export const UPDATE_LEARNER_NAME_AND_DOWNLOAD_CERT_FAILURE = "UPDATE_LEARNER_NAME_AND_DOWNLOAD_CERT_FAILURE";

//SMART CERT
export const SET_LEARNER_NAME = "SET_LEARNER_NAME";

//ECOMMERCE MODALS HANDLED BY COOKIES
export const SET_NO_SHOW_MODAL_CART = 'SET_NO_SHOW_MODAL_CART';
export const SET_NO_SHOW_MODAL_EXPIRATION = 'SET_NO_SHOW_MODAL_EXPIRATION';

// FOLLOW BRAND OR CHANNEL
// export const FOLLOW_L1_REQUEST = "FOLLOW_L1_REQUEST";
// export const FOLLOW_L1_SUCCESS = "FOLLOW_L1_SUCCESS";
// export const FOLLOW_L1_FAILURE = "FOLLOW_L1_FAILURE";

/* REPORT MANAGER */
export const GET_MY_TEAM_REQUEST = 'GET_MY_TEAM_REQUEST';
export const GET_MY_TEAM_SUCCESS = 'GET_MY_TEAM_SUCCESS';
export const GET_MY_TEAM_FAILURE = 'GET_MY_TEAM_FAILURE';

export const GET_REFRESH_REQUEST = 'GET_REFRESH_REQUEST';
export const GET_REFRESH_SUCCESS = 'GET_REFRESH_SUCCESS';
export const GET_REFRESH_FAILURE = 'GET_REFRESH_FAILURE';
export const SET_REFRESH_REQUESTED = 'SET_REFRESH_REQUESTED';

export const GET_MY_TEAM_WITH_COURSE_SUCCESS = 'GET_MY_TEAM_WITH_COURSE_SUCCESS';

export const GET_REPORT_EXCEL_REQUEST = 'GET_REPORT_EXCEL_REQUEST';
export const GET_REPORT_EXCEL_SUCCESS = 'GET_REPORT_EXCEL_SUCCESS';
export const GET_REPORT_EXCEL_FAILURE = 'GET_REPORT_EXCEL_FAILURE';

export const GET_MY_STATS = 'GET_MY_STATS';

//my team user detail
export const GET_MY_TEAM_DETAIL_REQUEST = 'GET_MY_TEAM_DETAIL_REQUEST';
export const GET_MY_TEAM_DETAIL_END = 'GET_MY_TEAM_DETAIL_END';

/* UTILS */
export const SET_SENDING_ANDALYTICS = 'SET_SENDING_ANDALYTICS';
export const SET_FORCE_ANALYTICS = 'SET_FORCE_ANALYTICS';
export const SET_ANALYTICS_COURSE_ID = 'SET_ANALYTICS_COURSE_ID';
export const GET_LABEL_SUCCESS = 'GET_LABEL_SUCCESS';
export const GET_LABEL_NOTIF_SUCCESS = 'GET_LABEL_NOTIF_SUCCESS';
export const GET_LABEL_L0_SUCCESS = 'GET_LABEL_L0_SUCCESS';
export const GET_LABEL_L1_SUCCESS = 'GET_LABEL_L1_SUCCESS';
export const GET_LABEL_L2_SUCCESS = 'GET_LABEL_L2_SUCCESS';
export const GET_LABEL_LTTAG_SUCCESS = 'GET_LABEL_LTTAG_SUCCESS';
export const GET_LABEL_COMPLEXITY_SUCCESS = 'GET_LABEL_COMPLEXITY_SUCCESS';
export const GET_LABEL_ONBOARDING_TOPICS_SUCCESS = 'GET_LABEL_ONBOARDING_TOPICS_SUCCESS';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';

export const SET_LOCATION_SEARCH_QUERY = 'SET_LOCATION_SEARCH_QUERY';

export const GET_TIMEZONE_REQUEST = 'GET_TIMEZONE_REQUEST';
export const GET_TIMEZONE_SUCCESS = 'GET_TIMEZONE_SUCCESS';
export const GET_TIMEZONE_FAILURE = 'GET_TIMEZONE_FAILURE';

export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE';

//PERSONALIZATION
export const SHOW_PERSONALIZATION = 'SHOW_PERSONALIZATION';

export const GET_PERSONALIZATION_REQ = 'GET_PERSONALIZATION_REQ';
export const GET_PERSONALIZATION_SUCCESS = 'GET_PERSONALIZATION_SUCCESS';
export const GET_PERSONALIZATION_FAILURE = 'GET_PERSONALIZATION_FAILURE';

export const SEND_PERSONALIZATION_REQ = 'SEND_PERSONALIZATION_REQ';
export const SEND_PERSONALIZATION_SUCCESS = 'SEND_PERSONALIZATION_SUCCESS';
export const SEND_PERSONALIZATION_FAILURE = 'SEND_PERSONALIZATION_FAILURE';

//RATING SURVEY
export const TOGGLE_RATING_MODAL = 'TOGGLE_RATING_MODAL';
//NEXT UP LESSON
export const TOGGLE_NEXT_UP_MODAL = 'TOGGLE_NEXT_UP_MODAL';

export const GET_RATING_REQ = 'GET_RATING_REQ';
export const GET_RATING_SUCCESS = 'GET_RATING_SUCCESS';
export const GET_RATING_FAILURE = 'GET_RATING_FAILURE';

export const SEND_RATING_REQ = 'SEND_RATING_REQ';
export const SEND_RATING_SUCCESS = 'SEND_RATING_SUCCESS';
export const SEND_RATING_FAILURE = 'SEND_RATING_FAILURE';

export const RESET_RATING_SURVEY = 'RESET_RATING_SURVEY';

//REVIEW SURVEY
export const GET_REVIEW_REQ = 'GET_REVIEW_REQ';
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
export const GET_REVIEW_FAILURE = 'GET_REVIEW_FAILURE';

export const SEND_REVIEW_REQ = 'SEND_REVIEW_REQ';
export const SEND_REVIEW_SUCCESS = 'SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_FAILURE = 'SEND_REVIEW_FAILURE';

export const RESET_REVIEW_SURVEY = 'RESET_REVIEW_SURVEY';

//MENU OPEN CLOSE
export const SET_OPEN_MENU = 'SET_OPEN_MENU';

export const SHOW_NOTICE = 'SHOW_NOTICE';
export const HIDE_NOTICE = 'HIDE_NOTICE';

export const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_FAILURE = 'GET_LANGUAGES_FAILURE';

export const SET_SUB_TAB_HOME = 'SET_SUB_TAB_HOME';
export const SET_TAB_HOME = 'SET_TAB_HOME';

export const SET_SUBSCRIPTION_TIMEOUT_FUNC = 'SET_SUBSCRIPTION_TIMEOUT_FUNC';
export const CLEAR_SUBSCRIPTION_TIME_OUT = 'CLEAR_SUBSCRIPTION_TIME_OUT';

export const NOTICE_ARRIVED = 'NOTICE_ARRIVED';
export const CLEAR_NOTICE_ARRIVED = 'CLEAR_NOTICE_ARRIVED';

export const TOGGLE_IFRAME_POPUP = 'TOGGLE_IFRAME_POPUP';
/* CMS */
export const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAILURE = 'GET_FAQ_FAILURE';

export const GET_FAQ_ECOMMERCE_REQUEST = 'GET_FAQ_ECOMMERCE_REQUEST';
export const GET_FAQ_ECOMMERCE_SUCCESS = 'GET_FAQ_ECOMMERCE_SUCCESS';
export const GET_FAQ_ECOMMERCE_FAILURE = 'GET_FAQ_ECOMMERCE_FAILURE';

export const GET_FAQ_PLAN_REQUEST = 'GET_FAQ_PLAN_REQUEST';
export const GET_FAQ_PLAN_SUCCESS = 'GET_FAQ_PLAN_SUCCESS';
export const GET_FAQ_PLAN_FAILURE = 'GET_FAQ_PLAN_FAILURE';

export const GET_FAQ_CHECKOUT_REQUEST = 'GET_FAQ_CHECKOUT_REQUEST';
export const GET_FAQ_CHECKOUT_SUCCESS = 'GET_FAQ_CHECKOUT_SUCCESS';
export const GET_FAQ_CHECKOUT_FAILURE = 'GET_FAQ_CHECKOUT_FAILURE';

export const GET_FAQ_EDUCATIONAL_PATHS_REQUEST = 'GET_FAQ_EDUCATIONAL_PATHS_REQUEST';
export const GET_FAQ_EDUCATIONAL_PATHS_SUCCESS = 'GET_FAQ_EDUCATIONAL_PATHS_SUCCESS';
export const GET_FAQ_EDUCATIONAL_PATHS_FAILURE = 'GET_FAQ_EDUCATIONAL_PATHS_FAILURE';

export const GET_CONTACT_US_SUCCESS = 'GET_CONTACT_US_SUCCESS';
export const GET_CONTACT_US_CART_SUCCESS = 'GET_CONTACT_US_CART_SUCCESS';

export const GET_CONTACT_US_CHECKOUT_REQUEST = 'GET_CONTACT_US_CHECKOUT_REQUEST';
export const GET_CONTACT_US_CHECKOUT_SUCCESS = 'GET_CONTACT_US_CHECKOUT_SUCCESS';
export const GET_CONTACT_US_CHECKOUT_FAILURE = 'GET_CONTACT_US_CHECKOUT_FAILURE';

export const GET_PERSONALIZATION_INFO = 'GET_PERSONALIZATION_INFO';

export const GET_BRAND_PAGE_INFO_SUCCESS = 'GET_BRAND_PAGE_INFO_SUCCESS';

export const GET_L1_IMAGE_REQUEST = 'GET_L1_IMAGE_REQUEST';
export const GET_L1_IMAGE_SUCCESS = 'GET_L1_IMAGE_SUCCESS';
export const GET_L1_IMAGE_FAILURE = 'GET_L1_IMAGE_FAILURE';

export const GET_HOWTO_REQUEST = 'GET_HOWTO_REQUEST';
export const GET_HOWTO_SUCCESS = 'GET_HOWTO_SUCCESS';
export const GET_HOWTO_FAILURE = 'GET_HOWTO_FAILURE';

//HP BEANS
export const GET_BEANS_REQUEST = 'GET_BEANS_REQUEST';
export const GET_BEANS_SUCCESS = 'GET_BEANS_SUCCESS';
export const GET_BEANS_FAILURE = 'GET_BEANS_FAILURE';

//TRAINING PILLS
export const GET_TP_HP_REQUEST = 'GET_TP_HP_REQUEST';
export const GET_TP_HP_SUCCESS = 'GET_TP_HP_SUCCESS';
export const GET_TP_HP_FAILURE = 'GET_TP_HP_FAILURE';

export const GET_TP_DETAIL_REQUEST = 'GET_TP_DETAIL_REQUEST';
export const GET_TP_DETAIL_SUCCESS = 'GET_TP_DETAIL_SUCCESS';
export const GET_TP_DETAIL_FAILURE = 'GET_TP_DETAIL_FAILURE';

export const GET_TP_CLOSEUP_REQUEST = 'GET_TP_CLOSEUP_REQUEST';
export const GET_TP_CLOSEUP_REQUEST_SUCCESS = 'GET_TP_CLOSEUP_REQUEST_SUCCESS';
export const GET_TP_CLOSEUP_REQUEST_FAILURE = 'GET_TP_CLOSEUP_REQUEST_FAILURE';

export const GET_TP_ADVCAMPAIGN_REQUEST = 'GET_TP_ADVCAMPAIGN_REQUEST';
export const GET_TP_ADVCAMPAIGN_REQUEST_SUCCESS = 'GET_TP_ADVCAMPAIGN_REQUEST_SUCCESS';
export const GET_TP_ADVCAMPAIGN_REQUEST_FAILURE = 'GET_TP_ADVCAMPAIGN_REQUEST_FAILURE';

export const GET_TP_BRAND_REQUEST = 'GET_TP_BRAND_REQUEST';
export const GET_TP_BRAND_SUCCESS = 'GET_TP_BRAND_SUCCESS';
export const GET_TP_BRAND_FAILURE = 'GET_TP_BRAND_FAILURE';
export const RESET_TP_BRAND = 'RESET_TP_BRAND';

export const SET_TP_CATALOGUE_TITLE = 'SET_TP_CATALOGUE_TITLE';

export const GET_TP_CATALOGUE_REQUEST = 'GET_TP_CATALOGUE_REQUEST';
export const GET_TP_CATALOGUE_SUCCESS = 'GET_TP_CATALOGUE_SUCCESS';
export const GET_TP_CATALOGUE_FAILURE = 'GET_TP_CATALOGUE_FAILURE';
export const RESET_TP_CATALOGUE = 'RESET_TP_CATALOGUE';

//HANDLE NOVELTIES CATALOGUE DATA
export const GET_NOVELTIES_CATALOGUE_REQUEST = 'GET_NOVELTIES_CATALOGUE_REQUEST';
export const GET_NOVELTIES_CATALOGUE_SUCCESS = 'GET_NOVELTIES_CATALOGUE_SUCCESS';
export const GET_NOVELTIES_CATALOGUE_FAILURE = 'GET_NOVELTIES_CATALOGUE_FAILURE';
export const RESET_NOVELTIES_CATALOGUE = 'RESET_NOVELTIES_CATALOGUE';

export const GET_TP_SHIPMENT_DATES_REQUEST = 'GET_TP_SHIPMENT_DATES_REQUEST';
export const GET_TP_SHIPMENT_WEEKLY_SUMMARY_SUCCESS = 'GET_TP_SHIPMENT_WEEKLY_SUMMARY_SUCCESS';
export const GET_TP_SHIPMENT_WEEKLY_SUMMARY_FAILURE = 'GET_TP_SHIPMENT_WEEKLY_SUMMARY_FAILURE';

export const SET_OPEN_TRAINING_PILL_DETAIL_MODAL = 'SET_OPEN_TRAINING_PILL_DETAIL_MODAL';

export const UPDATE_TP = 'UPDATE_TP';

export const FORCE_FILTER_UPDATE = 'FORCE_FILTER_UPDATE';

//TP WISHLIST CAROUSEL
export const GET_TP_WISH_CAROUSEL_REQUEST = 'GET_TP_WISH_CAROUSEL_REQUEST';
export const GET_TP_WISH_CAROUSEL_SUCCESS = 'GET_TP_WISH_CAROUSEL_SUCCESS';
export const GET_TP_WISH_CAROUSEL_FAILURE = 'GET_TP_WISH_CAROUSEL_FAILURE';

/* AUTHENTICATION */
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

//TOGGLE PASS EXPIRED POPUP
export const TOGGLE_PASS_EXPIRED_POPUP = 'TOGGLE_PASS_EXPIRED_POPUP';

//TOGGLE MYL KO POPUP
export const TOGGLE_MYL_KO_POPUP = 'TOGGLE_MYL_KO_POPUP';

// REGISTRATION
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_RESET = 'REGISTER_RESET';

//SEND CHANGE PSW EMAIL
export const CHANGE_PSW_EMAIL_REQUEST = 'CHANGE_PSW_EMAIL_REQUEST';
export const CHANGE_PSW_EMAIL_SUCCESS = 'CHANGE_PSW_EMAIL_SUCCESS';
export const CHANGE_PSW_EMAIL_FAILURE = 'CHANGE_PSW_EMAIL_FAILURE';

//ACTIVATE USER
export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE';

//RESET PASSWORD PUBLIC
export const RESET_PSW_PUBLIC_REQUEST = 'RESET_PSW_PUBLIC_REQUEST';
export const RESET_PSW_PUBLIC_SUCCESS = 'RESET_PSW_PUBLIC_SUCCESS';
export const RESET_PSW_PUBLIC_FAILURE = 'RESET_PSW_PUBLIC_FAILURE';

//RESET PASSWORD LOGGED
export const RESET_PSW_LOGGED_REQUEST = 'RESET_PSW_LOGGED_REQUEST';
export const RESET_PSW_LOGGED_SUCCESS = 'RESET_PSW_LOGGED_SUCCESS';
export const RESET_PSW_LOGGED_FAILURE = 'RESET_PSW_LOGGED_FAILURE';
export const RESET_PSW_LOGGED_RESET = 'RESET_PSW_LOGGED_RESET';

//GET COUNTRIES
export const GET_COUNTRIES_START = 'GET_COUNTRIES_START';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

export const SET_GA_CHECK = 'SET_GA_CHECK';
export const CLEAN_ERROR_LOGIN = 'CLEAN_ERROR_LOGIN';

export const FIREBASE_TOKEN_SUCCESS = 'FIREBASE_TOKEN_SUCCESS';
export const FIREBASE_TOKEN_FAILURE = 'FIREBASE_TOKEN_FAILURE';

//GET STATE OF RESIDENCE
export const GET_STATE_OF_RESIDENCE_START = 'GET_STATE_OF_RESIDENCE_START';
export const GET_STATE_OF_RESIDENCE_SUCCESS = 'GET_STATE_OF_RESIDENCE_SUCCESS';
export const GET_STATE_OF_RESIDENCE_FAILURE = 'GET_STATE_OF_RESIDENCES_FAILURE';

/* COURSE MODAL */
export const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE';
export const OPEN_SUB_COURSE_IN_LP = 'OPEN_SUB_COURSE_IN_LP';
export const TOGGLE_COURSE_MODAL = 'TOGGLE_COURSE_MODAL';

export const RESET_SCROLL_SESSIONS = 'RESET_SCROLL_SESSIONS';

export const GET_COURSE_DETAILS_REQUEST = 'GET_COURSE_DETAILS_REQUEST';
export const GET_COURSE_DETAILS_SUCCESS = 'GET_COURSE_DETAILS_SUCCESS';
export const GET_COURSE_DETAILS_FAILURE = 'GET_COURSE_DETAILS_FAILURE';

export const SET_IS_COURSE_DETAIL_OPENED_BY_URL = 'SET_IS_COURSE_DETAIL_OPENED_BY_URL';

// TEACHER MODAL
export const TOGGLE_TEACHER_MODAL = 'TOGGLE_TEACHER_MODAL';

/* SORT BY RELEVANCE */
export const GET_SORT_BY_RELEVANCE_REQUEST = 'GET_SORT_BY_RELEVANCE_REQUEST';
export const GET_SORT_BY_RELEVANCE_SUCCESS = 'GET_SORT_BY_RELEVANCE_SUCCESS';
export const GET_SORT_BY_RELEVANCE_FAILURE = 'GET_SORT_BY_RELEVANCE_FAILURE';

// REDIRECT AFTER LOGIN
export const SET_URL_REDIRECT_AFTER_LOGIN = 'SET_URL_REDIRECT_AFTER_LOGIN';

// CHANGE COLOR PICKER VALUE (AND GRADIENT)
export const SET_COLOR_PICKER = 'SET_COLOR_PICKER';
export const SET_GRADIENT_STYLE = 'SET_GRADIENT_STYLE';
export const SET_LEADERBOARD_PRESENCE = 'SET_LEADERBOARD_PRESENCE';
export const SET_NOTIFICATION_CHANNELS = 'SET_NOTIFICATION_CHANNELS';

// HANDLE DOCUMENT TITLE
export const SET_CATALOGUE_DOC_TITLE = 'SET_CATALOGUE_DOC_TITLE';

// FIREBASE
export const FIREBASE_LOGIN_REQUEST = 'FIREBASE_LOGIN_REQUEST';
export const FIREBASE_LOGIN_SUCCESS = 'FIREBASE_LOGIN_SUCCESS';
export const FIREBASE_LOGIN_FAILURE = 'FIREBASE_LOGIN_FAILURE';

export const FIREBASE_LOGOUT_REQUEST = 'FIREBASE_LOGOUT_REQUEST';
export const FIREBASE_LOGOUT_SUCCESS = 'FIREBASE_LOGOUT_SUCCESS';
export const FIREBASE_LOGOUT_FAILURE = 'FIREBASE_LOGOUT_FAILURE';

export const FIREBASE_SNAPSHOT_REQUEST = 'FIREBASE_SNAPSHOT_REQUEST';
export const FIREBASE_SNAPSHOT_FAILURE = 'FIREBASE_SNAPSHOT_FAILURE';

export const FIREBASE_GET_NOTIF_REQUEST = 'FIREBASE_GET_NOTIF_REQUEST';
export const FIREBASE_GET_NOTIF_SUCCESS = 'FIREBASE_GET_NOTIF_SUCCESS';
export const FIREBASE_GET_NOTIF_FAILURE = 'FIREBASE_GET_NOTIF_FAILURE';

export const FIREBASE_SAVE_NOTIF = 'FIREBASE_SAVE_NOTIF';

export const FIREBASE_WRITE_DB_REQUEST = 'FIREBASE_WRITE_DB_REQUEST';
export const FIREBASE_WRITE_DB_SUCCESS = 'FIREBASE_WRITE_DB_SUCCESS';
export const FIREBASE_WRITE_DB_FAILURE = 'FIREBASE_WRITE_DB_FAILURE';

export const FIREBASE_SET_SHOW_NOTIF_ANIMATION = 'FIREBASE_SET_SHOW_NOTIF_ANIMATION';

export const FIREBASE_SET_SNAPSHOT_SUBSCRIBERS = 'FIREBASE_SET_SNAPSHOT_SUBSCRIBERS';

export const FIREBASE_PUSH_NOTIFICATION_PREVIEW = 'FIREBASE_PUSH_NOTIFICATION_PREVIEW';
export const FIREBASE_POP_NOTIFICATION_PREVIEW = 'FIREBASE_POP_NOTIFICATION_PREVIEW';

export const FIREBASE_SET_SHOW_NOTIF_PANEL = 'FIREBASE_SET_SHOW_NOTIF_PANEL';

//SEARCH - SUGGESTED TERMS
export const GET_SUGGESTED_TERMS_REQ = 'GET_SUGGESTED_TERMS_REQ';
export const GET_SUGGESTED_TERMS_SUCCESS = 'GET_SUGGESTED_TERMS_SUCCESS';
export const GET_SUGGESTED_TERMS_FAILURE = 'GET_SUGGESTED_TERMS_FAILURE';

//BROWSE BY TOPIC INFO
export const GET_BROWSE_BY_TOPIC_REQ = 'GET_BROWSE_BY_TOPIC_REQ';
export const GET_BROWSE_BY_TOPIC_SUCCESS = 'GET_BROWSE_BY_TOPIC_SUCCESS';
export const GET_BROWSE_BY_TOPIC_FAILURE = 'GET_BROWSE_BY_TOPIC_FAILURE';

//SEARCH - GET COURSES
export const GET_SEARCH_COURSES_REQ = 'GET_SEARCH_COURSES_REQ';
export const GET_SEARCH_COURSES_SUCCESS = 'GET_SEARCH_COURSES_SUCCESS';
export const GET_SEARCH_COURSES_FAILURE = 'GET_SEARCH_COURSES_FAILURE';
export const RESET_SEARCH_COURSES = 'RESET_SEARCH_COURSES';

export const ADD_PREVIOUSLY_SEARCHED = 'ADD_PREVIOUSLY_SEARCHED';

//MODAL VIDEO PREVIEW
export const SET_PREVIEW_VIDEO_URL = 'SET_PREVIEW_VIDEO_URL';

//HOT CHANNELS
export const GET_HOT_L1_REQUEST = 'GET_HOT_L1_REQUEST';
export const GET_HOT_L1_SUCCESS = 'GET_HOT_L1_SUCCESS';
export const GET_HOT_L1_FAILURE = 'GET_HOT_L1_FAILURE';

//L1 MAP
export const GET_L1_MAP = 'GET_L1_MAP';

//SHOW MODAL MANAGE COOKIES
export const SET_SHOW_MODAL_MANAGE_COOKIES = 'SET_SHOW_MODAL_MANAGE_COOKIES';

//ENROLL LP
export const LP_ENROLL_REQUEST = 'LP_ENROLL_REQUEST';
export const LP_ENROLL_SUCCESS = 'LP_ENROLL_SUCCESS';
export const LP_ENROLL_FAILURE = 'LP_ENROLL_FAILURE';

//NOTIFY LIVESTREAM RECORDING
export const GET_NOTIFY_RECORDING_LIVESTREAM_REQUEST = 'GET_NOTIFY_RECORDING_LIVESTREAM_REQUEST';
export const GET_NOTIFY_RECORDING_LIVESTREAM_SUCCESS = 'GET_NOTIFY_RECORDING_LIVESTREAM_SUCCESS';
export const GET_NOTIFY_RECORDING_LIVESTREAM_FAILURE = 'GET_NOTIFY_RECORDING_LIVESTREAM_FAILURE';

//NOTIFY LIVESTREAM STARTING
export const SEND_LIVE_COMPLETION_REQUEST = 'SEND_LIVE_COMPLETION_REQUEST';
export const SEND_LIVE_COMPLETION_SUCCESS = 'SEND_LIVE_COMPLETION_SUCCESS';
export const SEND_LIVE_COMPLETION_FAILURE = 'SEND_LIVE_COMPLETION_FAILURE';

//GENERATE LOCAL NOTIFICATIONS
export const GET_LOCAL_NOTIFICATIONS = 'GET_LOCAL_NOTIFICATIONS';

//GET ORDER HISTORY
export const GET_ORDER_HISTORY_REQUEST = 'GET_ORDER_HISTORY_REQUEST';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAILURE = 'GET_ORDER_HISTORY_FAILURE';

//GET ORDER BY ID
export const GET_ORDER_BY_ID_REQUEST = 'GET_ORDER_BY_ID_REQUEST';
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';
export const GET_ORDER_BY_ID_FAILURE = 'GET_ORDER_BY_ID_FAILURE';

//GET ENRICHED ORDER BY ID
export const GET_ORDER_ENRICHMENT_BY_ID_REQUEST = 'GET_ORDER_ENRICHMENT_BY_ID_REQUEST';
export const GET_ORDER_ENRICHMENT_BY_ID_SUCCESS = 'GET_ORDER_ENRICHMENT_BY_ID_SUCCESS';
export const GET_ORDER_ENRICHMENT_BY_ID_FAILURE = 'GET_ORDER_ENRICHMENT_BY_ID_FAILURE';
//GET ENRICHED ORDER BY ID WITH CREDIT CARD INFOS
export const GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_REQUEST = 'GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_REQUEST';
export const GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_SUCCESS = 'GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_SUCCESS';
export const GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_FAILURE = 'GET_ORDER_ENRICHMENT_CONFIRMATION_BY_ID_FAILURE';

//GET CART
export const GET_CART_REQUEST = 'GET_CART_REQUEST';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILURE = 'GET_CART_FAILURE';

//MODAL PLAN
export const SET_SHOW_MODAL_PLAN = 'SET_SHOW_MODAL_PLAN';
//MODAL COURSE ALREADY IN PLAN
export const SET_SHOW_MODAL_COURSE_ALREADY_PLAN = 'SET_SHOW_MODAL_COURSE_ALREADY_PLAN';
//MODAL COURSE ONLY 1 MONTH
export const SET_SHOW_MODAL_COURSE_ONLY_1_MONTH = 'SET_SHOW_MODAL_COURSE_ONLY_1_MONTH';
//MODAL LESSON IS PART OF A COURSE
export const SET_SHOW_MODAL_LESSON_PART_COURSE = 'SET_SHOW_MODAL_LESSON_PART_COURSE';
//MODAL CART
export const SET_SHOW_MODAL_CART = 'SET_SHOW_MODAL_CART';
//MODAL CONTENT EXPIRATION
export const SET_SHOW_MODAL_CONTENT_EXPIRATION = 'SET_SHOW_MODAL_CONTENT_EXPIRATION';
//MODAL STOP RENEWAL CONFIMATION
export const SET_SHOW_MODAL_STOP_RENEWAL_CONFIRMATION = 'SET_SHOW_MODAL_STOP_RENEWAL_CONFIRMATION';
//MODAL START RENEWAL CONFIMATION
export const SET_SHOW_MODAL_START_RENEWAL_CONFIRMATION =
  'SET_SHOW_MODAL_START_RENEWAL_CONFIRMATION';
//MODAL ACTIVATE PLAN
export const SET_SHOW_MODAL_ACTIVATE_PLAN = "SET_SHOW_MODAL_ACTIVATE_PLAN";
//MODAL ALERT CANT ADD TO CART
export const SET_SHOW_MODAL_ALERT_ADD_CART_PLAN = 'SET_SHOW_MODAL_ALERT_ADD_CART_PLAN';
//MODAL ALERT CANT PLAY EXPIRED PLAN LESSON
export const SET_SHOW_MODAL_EXPIRED_IN_PLAN = 'SET_SHOW_MODAL_EXPIRED_IN_PLAN';
//ACTIVATE USER MODAL
export const SET_ACTIVATE_LICENSE_MODAL = 'ACTIVATE_LICENSE_REQUEST';
//MODAL RECOVERING LICENSE
export const SET_RECOVERING_LICENSE_MODAL = 'SET_RECOVERING_LICENSE_MODAL';
export const SET_RECOVERING_LICENSE_CONFIRMATION = 'SET_RECOVERING_LICENSE_CONFIRMATION';

//MODAL DOWNLOAD LINK
export const TOGGLE_DOWNLOAD_LINK_MODAL = 'TOGGLE_DOWNLOAD_LINK_MODAL';

//PLAN
export const GET_PLAN_REQUEST = 'GET_PLAN_REQUEST';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'GET_PLAN_FAILURE';

// PLAN RENEWAL
export const SET_PLAN_RENEWAL = 'SET_PLAN_RENEWAL';

//SHIPMENT INFO
export const GET_SHIPPING_INFO_REQUEST = 'GET_SHIPPING_INFO_REQUEST';
export const GET_SHIPPING_INFO_SUCCESS = 'GET_SHIPPING_INFO_SUCCESS';
export const GET_SHIPPING_INFO_FAILURE = 'GET_SHIPPING_INFO_FAILURE';
export const SET_SHIPPING_INFO = 'SET_SHIPPING_INFO';

//PAYMENT INFO
export const GET_PAYMENT_INFO_REQUEST = 'GET_PAYMENT_INFO_REQUEST';
export const GET_PAYMENT_INFO_SUCCESS = 'GET_PAYMENT_INFO_SUCCESS';
export const GET_PAYMENT_INFO_FAILURE = 'GET_PAYMENT_INFO_FAILURE';

//PLAN CAROUSEL INFO FROM CMS
export const GET_PLAN_CAROUSEL_INFO_REQUEST = 'GET_PLAN_CAROUSEL_INFO_REQUEST';
export const GET_PLAN_CAROUSEL_INFO_SUCCESS = 'GET_PLAN_CAROUSEL_INFO_SUCCESS';
export const GET_PLAN_CAROUSEL_INFO_FAILURE = 'GET_PLAN_CAROUSEL_INFO_FAILURE';

//PLAN PAGE INFOS FROM CMS
export const GET_PLAN_PAGE_INFOS_REQUEST = 'GET_PLAN_PAGE_INFOS_REQUEST';
export const GET_PLAN_PAGE_INFOS_SUCCESS = 'GET_PLAN_PAGE_INFOS_SUCCESS';
export const GET_PLAN_PAGE_INFOS_FAILURE = 'GET_PLAN_PAGE_INFOS_FAILURE';

//PLAN SUBSCRIPTION LIST
export const GET_SUBSCRIPTION_LIST_REQUEST = 'GET_SUBSCRIPTION_LIST_REQUEST';
export const GET_SUBSCRIPTION_LIST_SUCCESS = 'GET_SUBSCRIPTION_LIST_SUCCESS';
export const GET_SUBSCRIPTION_LIST_FAILURE = 'GET_SUBSCRIPTION_LIST_FAILURE';

export const GET_ORDER_TOKENS_REQUEST = 'GET_ORDER_TOKENS_REQUEST';
export const GET_ORDER_TOKENS_SUCCESS = 'GET_ORDER_TOKENS_SUCCESS';
export const GET_ORDER_TOKENS_FAILURE = 'GET_ORDER_TOKENS_FAILURE';

export const GET_ALL_TOKENS_REQUEST = 'GET_ALL_TOKENS_REQUEST';
export const GET_ALL_TOKENS_SUCCESS = 'GET_ALL_TOKENS_SUCCESS';
export const GET_ALL_TOKENS_FAILURE = 'GET_ALL_TOKENS_FAILURE';

//ENABLE OR DISABLE ECOMMERCE
export const SET_ECOMMERCE_ENABLE = 'SET_ECOMMERCE_ENABLE';

//REMOVE ITEM CART
export const REMOVE_ITEM_CART_REQUEST = 'REMOVE_ITEM_CART_REQUEST';
export const REMOVE_ITEM_CART_RESPONSE = 'REMOVE_ITEM_CART_RESPONSE';

//HERO BANNER CAROUSEL INFO FROM CMS
export const GET_HERO_BANNER_INFO_REQUEST = 'GET_HERO_BANNER_INFO_REQUEST';
export const GET_HERO_BANNER_INFO_SUCCESS = 'GET_HERO_BANNER_INFO_SUCCESS';
export const GET_HERO_BANNER_INFO_FAILURE = 'GET_HERO_BANNER_INFO_FAILURE';

//TORTONA
export const GET_INFO_TORTONA_REQUEST = 'GET_INFO_TORTONA_REQUEST';
export const GET_INFO_TORTONA_SUCCESS = 'GET_INFO_TORTONA_SUCCESS';
export const GET_INFO_TORTONA_FAILURE = 'GET_INFO_TORTONA_FAILURE';

//PROMO
export const GET_PROMO_LIST_REQUEST = 'GET_PROMO_LIST_REQUEST';
export const GET_PROMO_LIST_SUCCESS = 'GET_PROMO_LIST_SUCCESS';
export const GET_PROMO_LIST_FAILURE = 'GET_PROMO_LIST_FAILURE';

//SHOW LABEL PREVIEW
export const SET_SHOW_LABEL_PREVIEW = 'SET_SHOW_LABEL_PREVIEW';
//SHOW CMS PREVIEW
export const SET_SHOW_CMS_PREVIEW = 'SET_SHOW_CMS_PREVIEW';

//SHOW MODAL SESSION EXPIRED
export const SET_SHOW_MODAL_SESSION_EXPIRED = 'SET_SHOW_MODAL_SESSION_EXPIRED';

//SHOW COLLECTION PREVIEW
export const SET_COLLECTION_PREVIEW = 'SET_COLLECTION_PREVIEW';

//SHOW PROMPT METHOD FOR PWA
export const SET_PROMPT_METHOD = 'SET_PROMPT_METHOD';

//ONBOARDING INFO CMS
export const GET_ONBOARDING_OVERVIEW_REQUEST = 'GET_ONBOARDING_OVERVIEW_REQUEST';
export const GET_ONBOARDING_OVERVIEW_SUCCESS = 'GET_ONBOARDING_OVERVIEW_SUCCESS';
export const GET_ONBOARDING_OVERVIEW_FAILURE = 'GET_ONBOARDING_OVERVIEW_FAILURE';

//ONBOARDIN TEACHERS CMS
export const GET_ONBOARDING_TEACHERS_REQUEST = 'GET_ONBOARDING_TEACHERS_REQUEST';
export const GET_ONBOARDING_TEACHERS_SUCCESS = 'GET_ONBOARDING_TEACHERS_SUCCESS';
export const GET_ONBOARDING_TEACHERS_FAILURE = 'GET_ONBOARDING_TEACHERS_FAILURE';

//ONBOARDING CELEBRATION MODAL
export const SET_SHOW_CELEBRATION_MODAL = 'SET_SHOW_CELEBRATION_MODAL';

//HANDLE ONBOARDING CERTIFICATE PDF DOWNLOAD
export const GET_ONBOARDING_CERTIFICATE_PDF_REQUEST = 'GET_ONBOARDING_CERTIFICATE_PDF_REQUEST';
export const GET_ONBOARDING_CERTIFICATE_PDF_END = 'GET_ONBOARDING_CERTIFICATE_PDF_END';

// ONBOARDING MODAL CONTACT CUSTOMER SERVICE
export const SET_SHOW_MODAL_CONTACT_CUSTOMER_SERVICE = 'SET_SHOW_MODAL_CONTACT_CUSTOMER_SERVICE';
// ONBOARDING MODAL REQUEST TO JOIN
export const SET_SHOW_MODAL_REQUEST_JOIN = 'SET_SHOW_MODAL_REQUEST_JOIN';

//ONBOARDING - LP SAVING IN PROGRESS
export const SET_LP_IN_SAVING_PROGRESS = 'SET_LP_IN_SAVING_PROGRESS'
export const UNSET_LP_IN_SAVING_PROGRESS = 'UNSET_LP_IN_SAVING_PROGRESS'

export const TRIGGER_APPJS_UPDATE = 'TRIGGER_APPJS_UPDATE';

//ONBOARDING - SHOW RELATED MANDATORY POPUP
export const SET_SHOW_MODAL_ONBOARDING_RELATED_MANDATORY = 'SET_SHOW_MODAL_ONBOARDING_RELATED_MANDATORY';
export const SET_CLICK_MODAL_ONBOARDING_RELATED_MANDATORY = 'SET_CLICK_MODAL_ONBOARDING_RELATED_MANDATORY';

//ONBOARDING - SHOW RELATED MANDATORY CAROUSEL
export const SET_SHOW_CAROUSEL_ONBOARDING_RELATED_MANDATORY = 'SET_SHOW_CAROUSEL_ONBOARDING_RELATED_MANDATORY';

//LATEST COLLECTIONS - BRAND MODAL
export const SET_COLLECTIONS_BRAND_MODAL_INFO = 'SET_COLLECTIONS_BRAND_MODAL_INFO';

//COLLECTION PAGE - STORY MODAL
export const SET_STORY_MODAL_INFO = 'SET_STORY_MODAL_INFO';

//GET LATEST COLLECTIONS
export const GET_LATEST_COLLECTIONS_REQUEST = 'GET_LATEST_COLLECTIONS_REQUEST';
export const GET_LATEST_COLLECTIONS_SUCCESS = 'GET_LATEST_COLLECTIONS_SUCCESS';
export const GET_LATEST_COLLECTIONS_FAILURE = 'GET_LATEST_COLLECTIONS_FAILURE';

//GET SINGLE COLLECTION
export const GET_COLLECTION_INFO_REQUEST = 'GET_COLLECTION_INFO_REQUEST';
export const GET_COLLECTION_INFO_SUCCESS = 'GET_COLLECTION_INFO_SUCCESS';
export const GET_COLLECTION_INFO_FAILURE = 'GET_COLLECTION_INFO_FAILURE';

export const GET_COLLECTION_STORIES_REQUEST = 'GET_COLLECTION_STORIES_REQUEST';
export const GET_COLLECTION_STORIES_SUCCESS = 'GET_COLLECTION_STORIES_SUCCESS';
export const GET_COLLECTION_STORIES_FAILURE = 'GET_COLLECTION_STORIES_FAILURE';

//GET LATEST COLLECTIONS CAROUSEL
export const GET_LATEST_COLLECTIONS_CAROUSEL_REQUEST = 'GET_LATEST_COLLECTIONS_CAROUSEL_REQUEST';
export const GET_LATEST_COLLECTIONS_CAROUSEL_SUCCESS = 'GET_LATEST_COLLECTIONS_CAROUSEL_SUCCESS';
export const GET_LATEST_COLLECTIONS_CAROUSEL_FAILURE = 'GET_LATEST_COLLECTIONS_CAROUSEL_FAILURE';

//GET LATEST COLLECTIONS CMS CAROUSEL
export const GET_LATEST_COLLECTIONS_CMS_CAROUSEL_REQUEST =
  'GET_LATEST_COLLECTIONS_CMS_CAROUSEL_REQUEST';
export const GET_LATEST_COLLECTIONS_CMS_CAROUSEL_SUCCESS =
  'GET_LATEST_COLLECTIONS_CMS_CAROUSEL_SUCCESS';
export const GET_LATEST_COLLECTIONS_CMS_CAROUSEL_FAILURE =
  'GET_LATEST_COLLECTIONS_CMS_CAROUSEL_FAILURE';

//GET PILLS FROM SAME COLLECTION
export const GET_PILLS_FROM_SAME_STORY_REQUEST = 'GET_PILLS_FROM_SAME_STORY_REQUEST';
export const GET_PILLS_FROM_SAME_STORY_SUCCESS = 'GET_PILLS_FROM_SAME_STORY_SUCCESS';
export const GET_PILLS_FROM_SAME_STORY_FAILURE = 'GET_PILLS_FROM_SAME_STORY_FAILURE';

//SHOW MODAL RIGHT CART
export const SET_SHOW_MODAL_CART_RIGHT_MODAL = 'SET_SHOW_MODAL_CART_RIGHT_MODAL';

//SHOW AREA OF EXPERTISE
export const SET_SHOW_AREA_OF_EXPERTISE = 'SET_SHOW_AREA_OF_EXPERTISE';

//SHOW VISIONCARE MENU 
export const SET_SHOW_VISIONE_CARE_MENU = 'SET_SHOW_VISION_CARE_MENU';

//SHOW COLLECTIONS MENU MODAL
export const SET_SHOW_COLLECTIONS_MENU_MODAL = 'SET_SHOW_COLLECTIONS_MENU_MODAL';

//SHOW EDUCATIONAL PATHS MENU MODAL
export const SET_SHOW_ED_PATH_MENU_MODAL = 'SET_SHOW_ED_PATH_MENU_MODAL';

//SHOW MODAL BY TYPE
export const SET_SHOW_MODAL_BY_TYPE = 'SET_SHOW_MODAL_BY_TYPE';

//SHOW AUTO MANAGE CLOSE OF IFRAME POPUP
export const SET_AUTO_MANAGE_HISTORY_IFRAME_ON_CLOSE = 'SET_AUTO_MANAGE_HISTORY_IFRAME_ON_CLOSE';

//SET TEACHER USERNAME
export const SET_TEACHER_USERNAME = 'SET_TEACHER_USERNAME';

//LATEST COLLECTIONS FILTERS AND CONTENT
export const GET_LATEST_COLLECTIONS_CATALOGUE_REQUEST = "GET_LATEST_COLLECTIONS_CATALOGUE_REQUEST";
export const GET_LATEST_COLLECTIONS_CATALOGUE_SUCCESS = "GET_LATEST_COLLECTIONS_CATALOGUE_SUCCESS";
export const GET_LATEST_COLLECTIONS_CATALOGUE_FAILURE = "GET_LATEST_COLLECTIONS_CATALOGUE_FAILURE";

//BRAND COLLECTION FILTERS AND CONTENT
export const GET_BRAND_COLLECTION_CATALOGUE_REQUEST = "GET_BRAND_COLLECTION_CATALOGUE_REQUEST";
export const GET_BRAND_COLLECTION_CATALOGUE_SUCCESS = "GET_BRAND_COLLECTION_CATALOGUE_SUCCESS";
export const GET_BRAND_COLLECTION_CATALOGUE_FAILURE = "GET_BRAND_COLLECTION_CATALOGUE_FAILURE";

//BUILD YOUR CAREER
export const GET_BUILD_YOUR_CAREER_REQUEST = "GET_BUILD_YOUR_CAREER_REQUEST";
export const GET_BUILD_YOUR_CAREER_SUCCESS = "GET_BUILD_YOUR_CAREER_SUCCESS";
export const GET_BUILD_YOUR_CAREER_FAILURE = "GET_BUILD_YOUR_CAREER_FAILURE";

//SHOW LABEL PERSONIFICATION
export const SET_SHOW_LABEL_PERSONIFICATION_BANNER = 'SET_SHOW_LABEL_PERSONIFICATION_BANNER';
export const SET_SHOW_DIALOG_USERGROUPSIMULATION_PAGE = 'SET_SHOW_DIALOG_USERGROUPSIMULATION_PAGE';
export const SET_USER_SIMULATED = 'SET_USER_SIMULATED';
export const SET_ALREADY_IN_SIMULATION = 'SET_ALREADY_IN_SIMULATION'
export const SET_HIDE_BANNER_WAS_CLICKED = 'SET_HIDE_BANNER_WAS_CLICKED'
export const SET_EXIT_BANNER_WAS_CLICKED = 'SET_EXIT_BANNER_WAS_CLICKED'
export const SET_SHOW_DIALOG_USERGROUPSIMULATION_HIDE = 'SET_SHOW_DIALOG_USERGROUPSIMULATION_HIDE'
export const GET_LINKS_HEADER_FROM_CMS = 'GET_LINKS_HEADER_FROM_CMS';

//CATALOG LIGHT
export const GET_USER_RELEVANT_SESSION_COMPLETED = 'GET_USER_RELEVANT_SESSION_COMPLETED';
export const GET_USER_RELEVANT_SESSION_BY_ID_COMPLETED = 'GET_USER_RELEVANT_SESSION_BY_ID_COMPLETED';
export const GET_USER_RELEVANT_SESSION_REQUEST = 'GET_USER_RELEVANT_SESSION_REQUEST';
export const GET_USER_RELEVANT_SESSION_BY_ID_REQUEST = 'GET_USER_RELEVANT_SESSION_BY_ID_REQUEST';
export const GET_USER_RELEVANT_SESSION_FAILED = 'GET_USER_RELEVANT_SESSION_FAILED';
export const GET_USER_RELEVANT_SESSION_BY_ID_FAILED = 'GET_USER_RELEVANT_SESSION_BY_ID_FAILED';
export const USER_RELEVANT_SESSION_BY_ID_COMPLETED_LOADED = 'USER_RELEVANT_SESSION_BY_ID_COMPLETED_LOADED';
export const USER_RELEVANT_SESSION_BY_ID_REQUEST_LOADED = 'USER_RELEVANT_SESSION_BY_ID_REQUEST_LOADED';
export const USER_RELEVANT_SESSION_BY_ID_FAILED_LOADED = 'USER_RELEVANT_SESSION_BY_ID_FAILED_LOADED';

//HP OPTIMIZATION W1

//COURSESUMMARY
export const GET_COURSE_SUMMARY_REQUEST = "GET_COURSE_SUMMARY_REQUEST";
export const GET_COURSE_SUMMARY_SUCCESS = "GET_COURSE_SUMMARY_SUCCESS";
export const GET_COURSE_SUMMARY_FAILURE = "GET_COURSE_SUMMARY_FAILURE";

//COLLECTION WAVE 3
export const GET_BRAND_CAMPAIGN_BY_MOCO_REQUEST = "GET_BRAND_CAMPAIGN_BY_MOCO_REQUEST"
export const GET_BRAND_CAMPAIGN_BY_MOCO_SUCCESS = "GET_BRAND_CAMPAIGN_BY_MOCO_SUCCESS"
export const GET_BRAND_CAMPAIGN_BY_MOCO_FAILURE = "GET_BRAND_CAMPAIGN_BY_MOCO_FAILURE"
//CMS NEW PAGES
export const GET_BUTTON_HEADER_FROM_CMS = 'GET_BUTTON_HEADER_FROM_CMS';
//COLLECTION WAVE 3
export const GET_BRAND_CAMPAIGNS_BY_COLLECTION_SUCCESS = "GET_BRAND_CAMPAIGNS_BY_COLLECTION_SUCCESS";
export const GET_BRAND_CAMPAIGNS_BY_COLLECTION_REQUEST = "GET_BRAND_CAMPAIGNS_BY_COLLECTION_REQUEST";
export const GET_BRAND_CAMPAIGNS_BY_COLLECTION_FAILURE = "GET_BRAND_CAMPAIGNS_BY_COLLECTION_FAILURE";

//LEON 4033
export const GET_CERTIFICATES_FILTER_COMPLETED = "GET_CERTIFICATES_FILTER_COMPLETED";
export const GET_CERTIFICATES_FILTER_REQUEST = "GET_CERTIFICATES_FILTER_REQUEST";
export const GET_CERTIFICATES_FILTER_FAILED = "GET_CERTIFICATES_FILTER_FAILED";

// MODALS V3
export const SET_MODALS_V3_OPEN = 'SET_MODALS_V3_OPEN';


//MANAGER ASSIGNMENT
export const GET_USERS_TO_ASSIGN_COMPLETED = "GET_USERS_TO_ASSIGN_COMPLETED";
export const GET_USERS_TO_ASSIGN_REQUESTED = "GET_USERS_TO_ASSIGN_REQUESTED";
export const GET_USERS_TO_ASSIGN_FAILED = "GET_USERS_TO_ASSIGN_FAILED";
export const GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_REQUESTED = "GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_REQUESTED";
export const GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_SUCCESS = "GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_SUCCESS";
export const GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_FAILED = "GET_ASSIGNMENTS_AND_RETAKES_COURSE_IDS_FAILED";
export const POST_USERS_TO_ASSIGN_COMPLETED = "POST_USERS_TO_ASSIGN_COMPLETED";
export const POST_USERS_TO_ASSIGN_REQUESTED = "POST_USERS_TO_ASSIGN_REQUESTED";
export const POST_USERS_TO_ASSIGN_FAILED = "POST_USERS_TO_ASSIGN_FAILED";

// CONTENT LANGUAGE
export const GET_COUNTRY_LABELS_REQUEST = 'GET_COUNTRY_LABELS_REQUEST';
export const GET_COUNTRY_LABELS_SUCCESS = 'GET_COUNTRY_LABELS_SUCCESS';
export const GET_COUNTRY_LABELS_FAILURE = 'GET_COUNTRY_LABELS_FAILURE';
export const GET_COUNTRY_LANGUAGES_MAPPING_REQUEST = 'GET_COUNTRY_LANGUAGES_MAPPING_REQUEST';
export const GET_COUNTRY_LANGUAGES_MAPPING_SUCCESS = 'GET_COUNTRY_LANGUAGES_MAPPING_SUCCESS';
export const GET_COUNTRY_LANGUAGES_MAPPING_FAILURE = 'GET_COUNTRY_LANGUAGES_MAPPING_FAILURE';
