import React, { FC, useEffect, useState } from "react";
import { ModalV3ButtonProps, ModalV3Props } from "src/types/UI/Modals/ModalV3";
import FocusTrap, { Props as FocusTrapProps } from 'focus-trap-react';


const ModalV3: FC<ModalV3Props> = ({
    className,
    show,
    title,
    children,
    buttons,
    focusTrapOptions,
    parentClassName
}) => {

    if(!show) return <></>

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        // Function to update scroll position
        if(parentClassName) {
            const parent = document.getElementsByClassName(parentClassName)?.[0]
            if(parent && parent.scrollTop)
                setScrollY(parent.scrollTop)
        }
        // Add scroll event listener
        // Needed for course_overview modal if it's open
        const modalCourseOverview = document.querySelector('.modal')
        if(modalCourseOverview) {
            // Add class to body to prevent scrolling when modal is open
            modalCourseOverview?.classList?.add('modalv3-open');
        }

        return () => {
            // Remove class from body when modal is closed
            modalCourseOverview?.classList?.remove('modalv3-open');
        };
    }, []);
    
    return (
        <FocusTrap
            active={show}
            focusTrapOptions={{
                fallbackFocus: 'body',
                allowOutsideClick: true,
                ...focusTrapOptions
            }}
        >
            <div 
                className={`modal-v3 ${className}`}
                style={{top: scrollY}}
            >
                <div className={`modal-v3__container ${className}`}>
                    {/* TITLE SECTION */}
                    {title && <span className="modal-v3__title">{title}</span>}
                    {/* CHILDREN SECTION (WHAT STANDS IN THE MIDDLE OF THE MODAL) */}
                    {children && children}
                    {/* BUTTONS SECTION */}
                    {buttons &&
                        <div className="modal-v3__buttons">
                            {buttons.map((button: ModalV3ButtonProps, index: number) =>
                                <React.Fragment key={index}>{button}</React.Fragment>)
                            }
                        </div>
                    }
                </div>
            </div>
        </FocusTrap>
    );
};

export default ModalV3;