export type ErrorMessageV2Props = { 
    error: ErrorObj; 
    className?: string; 
};

export enum ErrorTypes {
    FIELD_REQUIRED = "field-required", //used when a field is required
} 

export type ErrorObj = {
    type: ErrorTypes;
    message?: string;
}

