import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store"; 
import { CountryLanguagesMapping, DefaultSelection, DropdownOption } from "../../types/ContentLanguage/contentLanguage";
// import { useGetCountryLanguagesMapping } from "./useGetCountryLanguagesMapping";
import { getCountriesOptionsFromCountryCode, getLanguagesOptionsFromCountry } from "@utility/ContentLanguage/contentLanguageUtility";

/**
 * Custom hook used to handle country and languages dropdowns for Content Language Modal
 * @param courseIdMaster 
 * @returns country selected, set country in Select, selected language, set language in Select, list of countries option, list of language options, mapping of all countries with related language
 */
const useGetCountriesAndLanguages = (
    // courseIdMaster: string,
    countryLanguageMapping: CountryLanguagesMapping,
    defaultSelection: DefaultSelection
): {
    selectedCountry: DropdownOption | null,
    setSelectedCountry: React.Dispatch<React.SetStateAction<string | null>>,
    selectedLanguage: DropdownOption | null,
    setSelectedLanguage: React.Dispatch<React.SetStateAction<string | null>>,
    countryOptions: DropdownOption[] | null, 
    languageOptions: DropdownOption[] | null,
    // countryLanguageMapping: CountryLanguagesMapping | null
} => {

    const { 
        allLanguages,
        isLoadingGetCountryLabels,
        isLoadedGetCountryLabels,
        countryTranslations,
    } = useSelector((state: RootState) => ({
        allLanguages: state.utils.languages,
        isLoadingGetCountryLabels: state.contentLanguage.isLoadingGetCountryLabels,
        isLoadedGetCountryLabels: state.contentLanguage.isLoadedGetCountryLabels,
        countryTranslations: state.contentLanguage.countryTranslations,
    }));

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [countryOptions, setCountryOptions] = useState<DropdownOption[] | null>(null);
    const [languageOptions, setLanguageOptions] = useState<DropdownOption[] | null>(null);
    
    // const {
    //     countryLanguageMapping,
    //     defaultSelection,
    //     chipsMap,
    //     isLoadingCountryLanguagesMapping,
    //     isLoadedCountryLanguagesMapping
    // } = useGetCountryLanguagesMapping(courseIdMaster);

    // CHECK when API calls are finished in order to setCountryOptions and eventually auto-fill dropdown
    useEffect(() => {
        if(!isLoadingGetCountryLabels 
            && isLoadedGetCountryLabels
            // && !isLoadingCountryLanguagesMapping
            // && isLoadedCountryLanguagesMapping
            && countryLanguageMapping
            && Object?.keys(countryLanguageMapping)?.length > 0
        ) {
            const countryKeys = Object?.keys(countryLanguageMapping)
            const countryOpts = getCountriesOptionsFromCountryCode(countryKeys, countryTranslations); 
            setCountryOptions(countryOpts);
            // IF country not selected yet and defaultSection present from the API call
            if(!selectedCountry && defaultSelection) {
                // IF defaultSection has country and language code
                if(defaultSelection.country && defaultSelection.language) {
                    const countryToAutoSelect = countryOpts.find((cOpt: DropdownOption) => cOpt.value === defaultSelection.country)
                    const countryLanguages = countryLanguageMapping?.[countryToAutoSelect?.value]?.map(item => item.language);
                    const languageOpts = getLanguagesOptionsFromCountry(countryLanguages, allLanguages);
                    const languageToAutoSelect = languageOpts.find((lOpt: DropdownOption) => lOpt.value === defaultSelection.language)
                    // IF countryToAutoSelect set country
                    if(countryToAutoSelect) setSelectedCountry(countryToAutoSelect)
                    // IF languageToAutoSelect set language
                    if(languageToAutoSelect) setSelectedLanguage(languageToAutoSelect)
                }
            } 
        }
    }, [
        isLoadingGetCountryLabels,
        isLoadedGetCountryLabels,
        countryLanguageMapping,
        // isLoadingCountryLanguagesMapping,
        // isLoadedCountryLanguagesMapping
    ])

    // CHECK when countryOptions change
    useEffect(() => {
        // AUTO FILL COUNTRY IF ONLY ONE COUNTRY AVAILABLE
        if(countryOptions && countryOptions.length === 1 && !defaultSelection) {
            setSelectedCountry(countryOptions[0]);
        }
    }, [countryOptions])

    // CHECK when selectedCountry change in order to change languages accordingly
    useEffect(() => {
        if(selectedCountry) {
            const countryLanguages = countryLanguageMapping?.[selectedCountry?.value]?.map(item => item.language);
            const languageOpts = getLanguagesOptionsFromCountry(countryLanguages, allLanguages);
            // RESTORE selectedLanguage when country change
            if(selectedLanguage && languageOpts && languageOpts.length !== 1) {
                setSelectedLanguage(null)
            }
            setLanguageOptions(languageOpts);
        }
    }, [selectedCountry,defaultSelection])

    // CHECK when languageOptions change
    useEffect(() => {
        // AUTO FILL language if country has only one language available
        if(languageOptions && languageOptions.length === 1) {
            setSelectedLanguage(languageOptions[0]);
        }
    }, [languageOptions])

    return {
        selectedCountry,
        setSelectedCountry,
        selectedLanguage,
        setSelectedLanguage,
        countryOptions, 
        languageOptions,
        // countryLanguageMapping
    };
}

export default useGetCountriesAndLanguages;