import React, { useState } from 'react';
import TextField from '@components/UI/TextField';
import { LangMap } from '@model/CoursesClass';
import { handleOnEnterKeyPress } from '@utility/Api';
import { useSelector } from '@hooks/redux';
import { searchTransitionTimeFromMenuToPage } from '@components/SearchMenu';

type Props = {
  handleChange?: (val: string) => void;
  searchVal?: string;
  goToSearchCatalogue?: (searchQuery?: string) => void;
  handleFocus?: React.InputHTMLAttributes<HTMLInputElement>['onFocus'];
  handleBlur?: React.InputHTMLAttributes<HTMLInputElement>['onBlur'];
  isMenu?: boolean;
};

const SearchSection = ({
  handleChange,
  searchVal,
  goToSearchCatalogue,
  handleFocus,
  handleBlur,
  isMenu,
}: Props) => {
  const lang: LangMap = useSelector(state => state.utils.lang);

  const isEnterPressed = event => {
    handleOnEnterKeyPress(event, goToSearchCatalogue);
  };

  const [disabled, setDisabled] = useState(false);

  const onFocus = e => {
    if (handleFocus) {
      if (isMenu) {
        setTimeout(() => {
          setDisabled(true);
        }, searchTransitionTimeFromMenuToPage - 100);
      }

      handleFocus(e);
    }
  };

  return (
    <div className="search__section">
      <div className="video-contain">
        <div className="overlay">
          <section className="overlay-container" aria-label={lang.SEARCH_PLACEHOLDER}>
            <div className="search__section__content">
              <div className="search__section__content__input">
                <TextField
                  id="search-section-input"
                  type="search"
                  className={!searchVal?.length ? 'empty' : ''}
                  name={new Date().getTime().toString()} //to avoid suggestions
                  placeholder={lang.SEARCH_PLACEHOLDER}
                  onChange={handleChange}
                  value={searchVal}
                  onKeyPress={isEnterPressed}
                  onClickSearchButton={goToSearchCatalogue}
                  onFocus={onFocus}
                  onBlur={handleBlur}
                  autoFocus={!handleFocus}
                  disabled={disabled}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
