import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {useHistory} from 'react-router-dom';
import { Course, CourseLanguage, l2SectionCourse } from '@model/CoursesClass';
import CardController from '@components/CardV3';
import Chip from '@components/UI/Chip';
import { defaultRatingItems } from '@components/UI/RadioButtonGroup';
import RadioButtonGroup from '@components/UI/RadioButtonGroup';
import DetailsSingleActivityButton from '@components/DetailsSingleActivityButton';
import ChipsList from '@components/ChipsList';
import clsx from '@utility/clsx';
import {
  canShowRating,
  getChipLanguageForNewLivestream,
  isAnEmptyLivestream,
  isAnEmptyVirtualClassroom,
  isAnEvent,
  isCompletedCourse,
  isCourseExpiring,
  isLearningPath,
  isLivestreamCourse,
  isLpEnrolled,
  isNewLivestream,
  isOnboardingRelatedMandatory,
  isOverdueCourse,
  isStartedCourse,
  printDate,
  scrollToActivities,
  showBoxCompleteEnroll,
  showBoxContinueEnroll,
  showPrimaryOrSecondaryBtn,
  truncateLabel,
} from '@utility/Api';
import CardLoader from '@components/UI/CardLoader';
import ButtonV2 from '@components/UI/ButtonV2';
import NotYetAvailableIcon from '@images/svg-icons/not-yet-available.svg';
import BottomLabelLive from '@components/CardV3/BottomLabelLive';
import {
  getLivestreamDate,
  getNewLivestreamDate,
  willBeNotifiedRecording,
  willLivestreamNotRecorded,
} from '@utility/LivestreamUtility';
import BuyLicenseSection from './BuyLicenseSection';
import {
  canCourseBeAccessed,
  isPurchasable,
  isPurchased,
  isPurchasedIndividually,
} from '@utility/ecommerceUtility';
import Accordion from '@components/UI/Accordion';
import EcpuIcon from '@images/svg-icons/ecpu.svg';
import AboIcon from '@images/svg-icons/Logo_ABO_official.svg';
import ExpiredDateOrLabel from './ExpiredDateOrLabel';
import ComingSoonList from '@components/ComingSoonList';
import CardActivitySkeleton from '@components/skeletons/CardActivitySkeleton';
import ActivitiesList from '@components/UI/ActivitiesList';
import { useSelector } from '@hooks/redux';
import ActivitiesListLP from '@components/UI/ActivityListLP';
import { enrollLp, toggleModalV3 } from '@redux-actions/index';
import useDownloadCertificate from '@hooks/useDownloadCertificate';
import LevelBreadcrumb from '@components/UI/LevelBreadcrumb';
import LazySVG from '@components/LazySvg';
import ModalShare from '@components/UI/ModalShare';
import ShareContent from './ShareContent';
import { getOnboardingC6Course, isCourseMaster } from '@utility/onBoardingCourseUtility';
import { ButtonHarvard, CoverHarvard, isCourseHarvard } from '@components/Harvard/Harvard';
import { isCourseEl360, isCourseWithVersioning } from '@utility/CourseUtility';
import { isUserEl360 } from '@utility/userUtility';
import TextSkeleton from '@components/skeletons/TextSkeleton';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import { camelCase } from 'lodash';
import { getCourseSummary } from '@redux-actions/';
import ButtonNewLivestream from '@components/UI/Buttons/ButtonNewLivestream';
import IconCtaAssignToTeam from '@svg-icons/manager-assignment/Community.svg'
import { USER_URLS } from '@components/link-utils';
import { handleOpenContentLanguageModalButtonLabel, openContentLanguageModal } from '@utility/ContentLanguage/contentLanguageUtility';
import { isUserManager } from '@utility/ManagerAssignment/managerAssignment.tsx';
import InformationBox from '@components/UI/Box/InformationBox';

type Props = {
  course: Course;
  compatibility: number;
  courseFullName: string;
  duration: string;
  credits: number;
  chips: Array<l2SectionCourse>;
  summary: { __html: string };
  publicRating: number;
  mentorRating: number;
  showLaunchButton: boolean;
  languages?: Array<CourseLanguage>;
  isLoadingLanguages: boolean;
  comingSoonLanguages: Array<CourseLanguage>;
  selectedLanguage: CourseLanguage;
  setLanguage: (language: CourseLanguage) => void;
  subtitles: Array<CourseLanguage>;
  selectedSubtitle: CourseLanguage;
  setSubtitle: (language: CourseLanguage) => void;
  toggleSectionChip: (sectionId) => void;
  complexityName: string;
  toggleModalCourseById: (
    courseId: string,
    redirectTo404?: boolean,
    forceOnBoarding?: boolean
  ) => void;
  isCurrentLpBlocked: boolean;
  blockingLps: Course[];
  isLiveStream: boolean;
  handleExpiringClick: (course: Course) => void;
  ecpu: boolean;
  abo: boolean;
  isLoadingCourse: { [courseId: string]: boolean };
  isMultiActivity: boolean;
  coursesMap?: Array<Course>;
  levels: { l0: string; l1: string; l2: string };
  liveLang: string;
};

const DisplayTopDesk = (props: Props): ReactElement => {
  const dispatch = useDispatch();
  const coursesMap = useSelector(state => state.course.coursesMap);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const lang = useSelector(state => state.utils.lang);
  const isLoadingDetails = useSelector(state => state.course.isLoadingDetails);
  const userProfile = useSelector(state => state.user.userProfile);
  const isLoadingCourseSummary = useSelector(state => state.course.isLoadingCourseSummary);
  const isLoadingCourse = useSelector(state => state.course.isLoadingCourse);
  const isLpEnrolling = useSelector(state => state.course.isLpEnrolling);
  const userEl360 = isUserEl360(userProfile);
  const languages = useSelector((state) => state.utils.languages);
  const courseEl360 = isCourseEl360(props.course);
  const history = useHistory();
  const hasOnboardingC6Course = getOnboardingC6Course(coursesMap);
  const showOnboardingRMCarousel = useSelector(state => state.utils.showOnboardingRMCarousel);

  const [handleDownloadClick, shouldShowDownloadButton] = useDownloadCertificate(
    props.course,
    true,
    "DisplayTopDesk"
  );


  // useEffect(() => { 
  //   dispatch(getCourseSummary([props.course], coursesMap));
  // },[])

  const CourseRating = () => {

    const showRating = (): boolean => {

      if(props.course 
        && !props.course.isStatic
        && props?.publicRating > 0
        && canShowRating(props.course, props.publicRating)
      )
        return true
      return false
    }

    if(!showRating()) return null

    return (
      <div className={`ratings top-desk__ratings-wrapper  multi-activity`}>
        <div className="rating" role="region" aria-label={lang.AUDIENCE_RATING}>
          <span className="text-rating">{lang.AUDIENCE_RATING}</span>
          <RadioButtonGroup
            items={defaultRatingItems}
            checked={props.publicRating}
            handleOnClick={() => { }}
            isRating={true}
            fontSize="14px"
            hideLabel
            readOnly
          />
        </div>
      </div>
    )
  };

  const handleAssignToTeamCta = () => {
    // GO TO ASSIGN TO YOUR TEAM PAGE
    history.push({
      pathname: USER_URLS.ASSIGN_TO_YOUR_TEAM_LOADING.URL,
      state: { courseId: props?.course?.courseIdMaster}
    });
    // ANALYTICS
    buildClickDataTrackingObject(
      "assignThisToYourTeam", props?.course?.courseIdMaster
    )
  }

  const topDeskCtaRowClassName = clsx('top-desk__cta-row', {
    'el360-program': userEl360 && courseEl360,
  });


  return (
    <div className="top-desk__wrapper">
      <div className="top-desk">
        {/* Course Card */}
        <CardController 
          course={props.course} 
          variant="overview" 
          noLazyLoadImage={true}
        />
        {/* Other info */}
        <div
          className="top-desk-info__wrapper"
          role="region"
          aria-labelledby="course-overtime-fullname"
        >
          {/* Breadcrumb */}
          <LevelBreadcrumb l0Id={props.levels.l0} l1Id={props.levels.l1} l2Id={props.levels.l2} />
          {/* Course Name */}
          {props.courseFullName && !props.isLoadingCourse?.[props.course.courseIdMaster] &&
            <span className="text-courseFullName" id="course-overtime-fullname">
              {truncateLabel(props.courseFullName, 50)}
            </span>
          }
          {/* Course Name - Skeleton */}
          {!props.courseFullName && props.isLoadingCourse?.[props.course.courseIdMaster] &&
            <TextSkeleton width={550} height={24} borderRadius={5} className='skeleton-text-desk__title' />
          }
          {/* C-type, duration and credits ROW */}
          <div className="ctype-duration-credits-row">
            <div className="row1">
              <div className="ctype-container">
                {!isAnEvent(props?.course) && (
                  <span className="text-ctype">
                    {!props.isLiveStream ? (
                      truncateLabel(props.complexityName, 32)
                    ) : (
                      <>
                        {!props.course.isPastEvent && !isNewLivestream(props.course) && (
                          <BottomLabelLive
                            {...getLivestreamDate(props.course)}
                            variant="overview"
                            lang={lang}
                            showDate
                            showLiveBadge={props.course.showLiveBadge}
                            showLiveCountdown={props.course.showLiveCountdown}
                            isNewLivestream={false}
                          />
                        )}
                      </>
                    )}
                  </span>
                )}
                {(!props.isLiveStream || (props.isLiveStream && props.course.isPastEvent)) && (
                  <ExpiredDateOrLabel
                    lang={lang}
                    course={props.course}
                    userProfile={userProfile}
                    handleExpiringClick={props.handleExpiringClick}
                  />
                )}
                {isAnEvent(props.course) ? (
                  <span className="text-14 text-duration__event">{props.duration}</span>
                ) : (
                  <span className="text-14 text-duration">{props.duration}</span>
                )}
                {props.credits && !isAnEvent(props.course) ? (
                  <span className="text-14 text-credits">
                    {props.credits +
                      ' ' +
                      (props.credits === 1 ? lang.CREDIT_LABEL : lang.CREDITS_LABEL)}
                  </span>
                ) : null}
                <div>
                  {props.course.expectedCompletion && !props.course.isPastEvent ? (
                    <span
                      className={clsx('text-14 text-expected-date', {
                        overdue: !!isOverdueCourse(props.course),
                        expiring: !!isCourseExpiring(props.course),
                      })}
                    >
                      {lang.DUE_DATE_LABEL
                        ? lang.DUE_DATE_LABEL +
                          ': ' +
                          printDate(props.course.expectedCompletion, lang)
                        : null}{' '}
                    </span>
                  ) : null}
                </div>
              </div>

              {/* <div className="flex align-center">
              </div> */}
            </div>

            {willLivestreamNotRecorded(props.course, userProfile) && (
              <span className="text-16 session-not-recorded">{lang.SESSION_NOT_RECORDED}</span>
            )}
          </div>
          
          {props.chips && !isAnEvent(props.course) && (
            <div className="chips">
              {props.chips.map(chip => (
                <Chip
                  label={chip.label}
                  variant="transparent"
                  key={chip.key}
                  toggle={() => { 
                    buildClickDataTrackingObject("chips", camelCase(chip.label));
                    props.toggleSectionChip(chip.key)
                  }}
                />
              ))}
            </div>
          )}
          {/* Course Summary */}
          <div
            className={`summary ${
              props.isMultiActivity || isLearningPath(props.course) ? 'multi-activity' : ''
            }`}
          >
            {/* Course summary - AVAILABLE */}
            {!isLoadingCourseSummary?.[props?.course?.courseId] && props.summary &&
              <div dangerouslySetInnerHTML={props.summary} />
            }
            {/* Course summary - SKELETON */}
            {isLoadingCourseSummary?.[props?.course?.courseId] &&
              <>
                <TextSkeleton width={550} height={16} borderRadius={5} className='skeleton-text-desk' />
                <TextSkeleton width={550} height={16} borderRadius={5} className='skeleton-text-desk' />
                <TextSkeleton width={200} height={16} borderRadius={5} className='skeleton-text-desk' />
              </>
            }

            {isCourseHarvard(props.course) && (
              <div className="top-desk-info_cover-harvard">
                <CoverHarvard type={props.course.typeHarvard} />
              </div>
            )}

            {props.ecpu && (
              <div className={'powered-by-ecpu-container'}>
                <span>{lang.POWERED_BY}</span>
                <EcpuIcon />
              </div>
            )}

            {props.abo && (
              <div className={'powered-by-ecpu-container'}>
                <span>{lang.RECOGNIZED_BY}</span>
                <AboIcon />
              </div>
            )}
          </div>

          {/* Related Mandatory Label */}
          {showOnboardingRMCarousel && !!hasOnboardingC6Course && isOnboardingRelatedMandatory(props.course) && (
            <div className="onboarding-tab-overview__related-mandatory-content-container pdp">
              <div className="onboarding-tab-overview__related-mandatory-content">
                <p className="onboarding-tab-overview__related-mandatory-content-text">
                  {lang?.ONBOARDING_PDP_RELATED_MANDATORY_CONTENT}
                </p>
              </div>
            </div>
          )}
          
          <ActivitiesList course={props.course} />
          <ActivitiesListLP course={props.course} coursesMap={props.coursesMap} />

          {showShareModal && (
            <ModalShare show={true} close={() => setShowShareModal(false)}>
              <ShareContent course={props.course} />
            </ModalShare>
          )}
          {(isAnEmptyVirtualClassroom(props.course) || isAnEmptyLivestream(props.course)) && 
          <div>
            <div className="top-desk__emptyvc-comingsoon">{lang.EMPTY_VC_COMINGSOON}</div> 
            <div className="top-desk__emptyvc-description">{lang.EMPTY_VC_DESCRIPTION}</div>
          </div>
          }

          {showBoxContinueEnroll(props.course, coursesMap) && !showBoxCompleteEnroll(props.course, coursesMap) &&
            <InformationBox text={lang.BOX_CONTINUE_ENROLLMENT}/>
          }
          {showBoxCompleteEnroll(props.course, coursesMap) &&
            <InformationBox text={lang.BOX_COMPLETE_ENROLLMENT}/>
          }
          {canCourseBeAccessed(props.course) && (
            <div
              className={topDeskCtaRowClassName}
            >
              {shouldShowDownloadButton && (
                <div className="download-share">
                  <div className="award">
                    <LazySVG src="/images/svg-icons/award.svg" alt={'award'} />
                    <div className="award-label">
                      <p>{lang.CONGRATULATIONS}</p>
                      <p>{lang.COMPLETED_CONTENT}</p>
                    </div>
                  </div>
                  <div className="completed-course__buttons">
                    <ButtonV2
                      small
                      className="completed-course__certificate"
                      variant="text-btn-no-arrow"
                      ariaLabel={lang.DOWNLOAD + ' ' + props.course.courseFullName}
                          handleClick={(e)=>{buildClickDataTrackingObject("download_pdf",camelCase(props?.course?.courseId));handleDownloadClick(e)}}
                    >
                      <LazySVG
                        className="area-of-expertise__l1-channel-arrow"
                        src={'/images/svg-icons/download-certificate.svg'}
                        alt={lang.DOWNLOAD}
                      />
                      {lang.CTA_DOWNLOAD_CERTIFICATE}
                    </ButtonV2>
                    <ButtonV2
                      small
                      className="completed-course__certificate"
                      variant="text-btn-no-arrow"
                      ariaLabel={lang.CTA_SHARE + ' ' + props.course.courseFullName}
                      handleClick={() => {buildClickDataTrackingObject("shareLinkedin",camelCase(props?.course?.courseId));setShowShareModal(true)}}
                    >
                      <LazySVG
                        className="area-of-expertise__l1-channel-arrow"
                        src={'/images/svg-icons/plus2.svg'}
                        alt={lang.CTA_SHARE}
                      />
                      {lang.CTA_SHARE}
                    </ButtonV2>
                  </div>
                </div>
              )}
              <div className="buttons-container">
                {props.showLaunchButton && (
                  <>
                    {isCourseHarvard(props.course) &&
                      <ButtonHarvard
                        textCTA={lang.LESSON_HARVARD_CTA}
                        linkBtnExternal="https://leonardo.essilorluxottica.com/"
                        course={props.course}
                        tooltip={lang.LESSON_HARVARD_TOOLTIP}
                        isExternalAction={true}
                      />
                    }
                    {!isCourseHarvard(props.course) && !isCourseWithVersioning(coursesMap, props?.course?.courseIdMaster) && !isNewLivestream(props.course) &&
                      <DetailsSingleActivityButton
                        lang={lang}
                        course={props.course}
                        subtitle={props.selectedSubtitle}
                        buttonVariant={shouldShowDownloadButton ? 'secondary' : ''}
                        isLoading={false}
                        buttonSmall
                      />
                    }
                    {/* BUTTON NEW LIVESTREAM */}
                    {!isCourseHarvard(props.course) && isNewLivestream(props.course) && !isCourseWithVersioning(coursesMap, props?.course?.courseIdMaster) &&
                      <div className='btn-container'>
                        <ButtonNewLivestream course={props.course} />
                      </div>
                    }
                    {/* CONTENT LANGUAGE */}
                    {!isCourseHarvard(props.course) && isCourseWithVersioning(coursesMap, props?.course?.courseIdMaster) &&
                      <ButtonV2
                        className={`open-content-language-modal__cta`}
                        handleClick={() => openContentLanguageModal(dispatch)}
                        small
                        loading={isLoadingCourse[props?.course?.courseIdMaster] || isLoadingDetails[props?.course?.courseIdMaster]}
                      >
                        {handleOpenContentLanguageModalButtonLabel(lang, props?.course)}
                      </ButtonV2>
                    }
                  </>
                )}
              </div>
              
              {/* ASSIGN TO YOUR TEAM  */}
              {isUserManager(userProfile?.role) && props?.course?.managerAssignment &&
                <ButtonV2
                  small
                  variant="secondary"
                  className={'cta-assign-to-team'}
                  handleClick={handleAssignToTeamCta}
                >
                  <div className='icon-container'>
                    <IconCtaAssignToTeam className={`cta-assign-to-team`} />
                  </div>
                  <div className='text-container'>
                    <span className='cta-text'>{lang.CTA_ASSIGN_TO_TEAM_PDP}</span>
                  </div>
                </ButtonV2>
              }
              {/* LOADER */}
              {!props.showLaunchButton && (
                <>
                  {isLoadingDetails[props.course.courseIdMaster] && (
                    <div className="launch-spinner">
                      <CardLoader />
                    </div>
                  )}
                </>
              )}
              {!!willBeNotifiedRecording(props.course, userProfile) && (
                <span className="card-v3-overview__will-be-notified-recording">
                  {lang.WILL_BE_NOTIFIED_RECORDING_LIVESTREAM}
                </span>
              )}

              {!userEl360 && !courseEl360 && (
                <>
                  {isCourseMaster(props.course) && isPurchased(props.course) && (
                    <span className="top-desk__text-purchased">
                      {isPurchasedIndividually(props.course, userProfile)
                        ? lang.PURCHASED
                        : lang.INCLUDED_IN_YOUR_PLAN}
                    </span>
                  )}
                  {!isCourseMaster(props.course) && isPurchased(props.course) && (
                    <span className="top-desk__text-purchased__lesson-case">
                      {isPurchasedIndividually(props.course, userProfile)
                        ? lang.PURCHASED
                        : lang.INCLUDED_IN_YOUR_PLAN}
                    </span>
                  )}
                </>
              )}

              {/* {isLpEnrolled(props.course) &&
                !isCompletedCourse(props.course) &&
                !isLpEnrolling &&
                !isAnEvent(props.course) && (
                  <ButtonV2
                    variant="text-btn-no-arrow"
                    small
                    className={'disenroll-btn'}
                    handleClick={() => dispatch(enrollLp(false, props.course.courseIdMaster))}
                    ariaLabel={lang.DISENROLL_LABEL}
                  >
                    {lang.DISENROLL_LABEL}
                  </ButtonV2>
                )} */}
            </div>
          )}

          {canCourseBeAccessed(props.course) && props.isCurrentLpBlocked && (
            <div className="top-desk__blockedby-row">
              <NotYetAvailableIcon className="top-desk__blockedby-lock" />
              <div className="top-desk__blockedby-text">
                <span className="text-14">{lang.ENROLL_AFTER_COMPLETING}</span>
                {props.blockingLps.map(blockingLp => (
                  <span
                    key={blockingLp.courseIdMaster}
                    className="text-14 top-desk__blocking-lp"
                    onClick={() => props.toggleModalCourseById(blockingLp.courseIdMaster)}
                  >
                    {blockingLp.courseFullName}
                  </span>
                ))}
              </div>
            </div>
          )}

          {isPurchasable(props.course, true) && !isPurchased(props.course) && (
            <BuyLicenseSection
              course={props.course}
              isStartedCourse={isStartedCourse(props.course)}
            />
          )}

          {isPurchased(props.course, true) && (
            <Accordion
              title={lang.BUY_MORE_LICENSES}
              className="accordion-more-licenses course-detail-desk"
              toggleDefault={false}
              trackTransition={true}
            >
              <BuyLicenseSection course={props.course} buyNowVariant="secondary" />
            </Accordion>
          )}
        </div>
      </div>

      {CourseRating()}
      {props.languages && props.languages?.length > 0 && !isCourseHarvard(props.course) && (
        <section className="languages-section" aria-label={lang.COURSE_AVAILABLE_LANGUAGES}>
          <span className="text-languages">{lang.COURSE_AVAILABLE_LANGUAGES}</span>
          <ChipsList
            items={props.languages}
            selectedItem={props.selectedLanguage}
            setItem={props.setLanguage}
            id="languages"
            isSelected={(item, selected) =>
              item && selected
                ? item.code === selected.code && item.courseId === selected.courseId
                : false
            }
            disabled={props.isLoadingCourse?.[props.course.courseIdMaster]}
            loading={props.isLoadingLanguages || props.isLoadingCourse?.[props.course.courseIdMaster]}
          />
        </section>
      )}
      {isLivestreamCourse(props.course) && props.liveLang && (
        <section className="languages-section" aria-label={lang.COURSE_AVAILABLE_LANGUAGES}>
          <span className="text-languages">{lang.COURSE_AVAILABLE_LANGUAGES}</span>
          <Chip label={props.liveLang} checked={true} className="cursor-default" />
        </section>
      )}
      {isNewLivestream(props.course) && getChipLanguageForNewLivestream(props.course, languages) && (
        <section className="languages-section" aria-label={lang.COURSE_AVAILABLE_LANGUAGES}>
          <span className="text-languages">{lang.COURSE_AVAILABLE_LANGUAGES}</span>
          <Chip label={getChipLanguageForNewLivestream(props.course,languages)} checked={true} className="cursor-default" />
        </section>
      )}
      {props.course.comingSoonLanguages && props.course.comingSoonLanguages?.length > 0 && (
        <ComingSoonList
          items={props.course.comingSoonLanguages}
          availableLanguages={props.course.totalLanguages}
        />
      )}
      {props.subtitles && props.subtitles?.length > 0 && (
        <section
          className="languages-section"
          aria-label={lang.COURSE_AVAILABLE_SUBTITLES_LANGUAGES}
        >
          <span className="text-languages">{lang.COURSE_AVAILABLE_SUBTITLES_LANGUAGES}</span>
          <ChipsList
            items={props.subtitles}
            selectedItem={props.selectedSubtitle}
            setItem={props.setSubtitle}
            id={props.selectedLanguage?.code + '_subtitles'}
            isSelected={(item, selected) =>
              item && selected
                ? item.code === selected.code && item.courseId === selected.courseId
                : false
            }
          />
        </section>
      )}

      {isLoadingDetails?.[props.course.courseIdMaster] && props.isMultiActivity && (
        <div className="top-desk__multiactivity-skeleton">
          {[...Array(3)].map((e, i) => (
            <CardActivitySkeleton key={i} />
          ))}
        </div>
      )}
    </div>
  );
};
  

export default DisplayTopDesk;
