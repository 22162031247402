import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { 
    GET_COUNTRY_LABELS, 
    GET_COUNTRY_LANGUAGES_MAPPING 
} from '../../config';
import { getHeaders } from '@utility/Api';
import { 
    GET_COUNTRY_LABELS_FAILURE, 
    GET_COUNTRY_LABELS_REQUEST, 
    GET_COUNTRY_LABELS_SUCCESS,
    GET_COUNTRY_LANGUAGES_MAPPING_REQUEST ,
    GET_COUNTRY_LANGUAGES_MAPPING_SUCCESS,
    GET_COUNTRY_LANGUAGES_MAPPING_FAILURE
} from './actionTypes';
import { showError } from './utilsActions';
import { CountryLanguagesMapping, CountryTranslations, GetCountryLabelsResponse, GetCountryLanguagesMappingResponse } from '../../types/ContentLanguage/contentLanguage';

// GET country labels from /label/country/labels/{language} API call for a specific language
export const getCountryLabels = (language: string) => {

    return (dispatch: Dispatch) => {

        dispatch(request());
        const url = GET_COUNTRY_LABELS?.replace('{language}', language);

        axios({
            url,
            method: 'GET',
            headers: getHeaders(),
        })
            .then((response: AxiosResponse<GetCountryLabelsResponse>) => {
                if(response && response.data && response.data.translations) {
                    const translations = response.data.translations;
                    dispatch(success(translations));
                }
            })
            .catch(err => {
                console.error('❌ Error during getCountryLabels request', err);
                dispatch(failure());
                showError(err);
            });
    };

    function request() {
        return {
            type: GET_COUNTRY_LABELS_REQUEST,
        };
    }

    function success(payload: CountryTranslations) {
        return {
            type: GET_COUNTRY_LABELS_SUCCESS,
            payload,
        };
    }

    function failure() {
        return {
            type: GET_COUNTRY_LABELS_FAILURE,
        };
    }
};

// GET country-languages from /course/language/all?courseId={courseId} API call for a specific courseId
export const getCountryLanguagesMapping = (courseId: string) => {

    return (dispatch: Dispatch) => {

        dispatch(request());
        const url = GET_COUNTRY_LANGUAGES_MAPPING?.replace('{courseId}', courseId);

        axios({
            url,
            method: 'GET',
            headers: getHeaders(),
        })
            .then((response: AxiosResponse<GetCountryLanguagesMappingResponse>) => {
                if(response && response.data && response.data.countryLanguageMapping) {
                    const { countryLanguageMapping } = response.data;
                    console.log("🚀 ~ .then ~ countryLanguageMapping:", countryLanguageMapping)
                    dispatch(success(countryLanguageMapping));
                }
            })
            .catch(err => {
                console.error('❌ Error during getCountryLabels request', err);
                dispatch(failure());
                showError(err);
            });
    };

    function request() {
        return {
            type: GET_COUNTRY_LANGUAGES_MAPPING_REQUEST,
        };
    }

    function success(payload: CountryLanguagesMapping) {
        return {
            type: GET_COUNTRY_LANGUAGES_MAPPING_SUCCESS,
            payload,
        };
    }

    function failure() {
        return {
            type: GET_COUNTRY_LANGUAGES_MAPPING_FAILURE,
        };
    }
};