import React, { ReactElement, useEffect, useState } from 'react';

import {
  getMultiactivityListOfVCs,
  getParentCourse,
  getSessionFilteredByActivity,
  getSessionFilteredByActivityToBook,
  handleOnEnterKeyPress,
  isAnEmptyLivestream,
  isAnEmptyVirtualClassroom,
  isAnEvent,
  isChildOfAnEvent,
  isCompletedCourse,
  isLearningPath,
  isLessonOptionalForLP,
  isLivestreamCourse,
  isMultiActivity,
  isNewLivestream,
  multiActivityHasMoreVC,
  printHTML,
  truncateLabel,
  vcGetSessionToBook,
  vcHasMoreSession,
  vcHasMoreSessionToBook,
  vcHasOneSessionBooked,
  vcSessionNoBooked,
} from '@utility/Api';
import { checkCourseState, isVirtualClassroom } from '@utility/Api';
import { Course, CourseDetail, CoursesMap, getTrainerFullName, LangMap } from '@model/CoursesClass';
import { useSelector } from '@hooks/redux';
import clsx from '@utility/clsx';
import Lock2Icon from '@images/svg-icons/lock2.svg';
import LockIconSmall from '@images/svg-icons/lock-icon-small.svg';
import TagBadge from './UI/TagBadge';
import useElementSize from '@hooks/useElementSize';
import PlayIcon from './UI/PlayIcon';
import DetailsSingleActivityButton from './DetailsSingleActivityButton';
import useToggle from '@hooks/useToggle';
import ButtonV2 from './UI/ButtonV2';
import VCMultiSessionCarouselSection from './CourseOverview/VCMultiSessionCarouselSection';
import Arrowleft from '@svg-icons/arrow-right5.svg';
import { courseStatesConst } from '@utility/const';
import RibbonCourseEvolution from './UI/RibbonCourseEvolution';
import { getVCRecCompletion, getVCRecording, getVCRecordingLinks, getVCRecordings, isVCLiveNow } from '@utility/VCUtility';
import LazySVG from './LazySvg';
import { DetailMultiactivityClass } from '@hooks/useGetDetailsMultiactivity';
import { canCourseBeAccessed } from '@utility/ecommerceUtility';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { isAssignmentLesson, isCourseOnBoarding, isSupportMaterialLesson } from '@utility/onBoardingCourseUtility';
import TextSkeleton from './skeletons/TextSkeleton';
import { useDispatch } from 'react-redux';
import * as actions from '../store/actions';
import { classNames } from 'react-select/src/utils';
// import CardCover from './CardV3/CardCover';

type CardActivity = {
  className?: string;
  id: number | string;
  number?: string;
  name: string;
  description?: { __html: string } | string;
  ctypeName: string;
  duration: string;
  valueBar: number;
  onClickPlayIcon: () => void;
  isBlocked: boolean;
  orderNumber: number;
  image: string;
  isLessonInOnboarding?: boolean;
  course?: Course;
  onClickCard?: () => void;
  topic3Id?: string;
  credits?: number;
  isSupportLessonInOnboarding?: boolean;
  isAssignment?: boolean;
  isOneToOne?: boolean;
  isSupportMaterial?: boolean;
  hidePlayButton?: boolean;
  isHarvard?: boolean;
  badgeLabel?: string;
  badgeColor?: string;
  badgePriority?: number;
  // delayImageLoading?: boolean;
  activity?: DetailMultiactivityClass;
  cardRef: (node: Element) => void;
  isProgramLocked?: boolean;
  showTopic3OptionalBadge?: boolean;
  showTopic2OptionalBadge?: boolean;
  showTopic1OptionalBadge?: boolean;
  fatherCourseId?: string;
};

const maxChars: number = 60;

export const animationSlideToTop = {
  initialPosition: 'bottom',
  initialY: '20px',
  initialX: '0',
  intersectionObserver: true,
  delay: 0,
  useSectionTagHtml: false,
};

const CardActivity = (props: CardActivity): ReactElement => {
  const lang: LangMap = useSelector(state => state.utils.lang);

  // const [showImage, setShowImage] = useState<boolean>(false);
  const isLoadingDetails = useSelector(state => state.course.isLoadingDetails);
  const isLoadingCourseSummary = useSelector(state => state.course.isLoadingCourseSummary);
  let isOnboardingPage = window.location.href.includes('program');
  let isBycPage = window.location.href.includes('build-your-career');
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const { SCHEDULED } = courseStatesConst;
  const { isDesktopOrTabletLandscape, isPhone, isTabletPortrait, isPhoneOrTabletPortrait } = useBreakpointBoolean();
  const [containerHeight, setContainerHeight] = useState<number>(0)
  const userProfile = useSelector(state => state.user.userProfile);
  // useEffect(() => {
  //   if (props.delayImageLoading) {
  //     //delay because the image slows down the accordion animation (lesson within the topic3 accordion in onboarding)
  //     setTimeout(() => {
  //       setShowImage(true);
  //     }, 1000);
  //   } else {
  //     setShowImage(true);
  //   }
  // }, []);

  const handleClickCard = () => {
    if (!props.isBlocked && !props.isProgramLocked) {
      localStorage.setItem('arrivedFromOnboarding', 'true');
      if (isLearningPath(coursesMap[props?.fatherCourseId]) && !isCourseOnBoarding(coursesMap[props?.fatherCourseId])) {
        const data = {
          arrivedFromLp: true,
          fatherCourseId: props?.fatherCourseId
        };
        localStorage.setItem('arrivedFromLpData', JSON.stringify(data));
      } 
      props.onClickCard();
    }
  };

  const handleClickPlayIcon = () => {
    if (!props.isBlocked && !props.isProgramLocked) {
      props.onClickPlayIcon();
    }
  };

  const openRecordings = link => {
    window.open(link);
  };

  const dispatch = useDispatch();

  const [div, size] = useElementSize();

  const TagBadgeCardActivity = ({ className = '' }) => (
    <TagBadge
      variant="white-border"
      className={'tag-badge-card-evolution font-medium evolution-primary ' + className}
      label={lang?.COMPLETED}
    />
  );

  const TagBadgeEmptyVC = ({ className = '' }) => (
    <TagBadge
      variant="white-border"
      className={'tag-badge-card-evolution font-medium white' + className}
      label={lang?.EMPTY_VC_COMINGSOON}
    />
  );

  const TagBadgeOptional = ({ className = '' }) => (
    <TagBadge
      variant="white-border"
      className={'tag-badge-card-evolution font-medium white ' + className}
      label={lang?.OPTIONAL_LABEL}
    />
  );

  //SHOW TEACHER NAME , CITY , LANGUAGE AVAILABLE FOR VC
  const firstSession = props?.course?.courseSessions?.[0];

  const checkTrainers = props?.course?.courseSessions.every(
    item =>
      item.trainers?.[0]?.firstName === firstSession.trainers?.[0]?.firstName &&
      item.trainers?.[0]?.lastName === firstSession.trainers?.[0]?.lastName &&
      item?.city === firstSession?.city &&
      item?.city !== ' ' &&
      item?.language === firstSession?.language
  );

  const showSessionLabels = isVirtualClassroom(props?.course) && checkTrainers;

  const showCalendarIconForVC =
    (isVirtualClassroom(props?.course) && checkCourseState(props?.course?.courseStates, SCHEDULED)) || isNewLivestream(props?.course);

  const idScroll = 'ID' + props.orderNumber;
  
  const isLPCourse = coursesMap && props?.fatherCourseId && isLearningPath(coursesMap[props?.fatherCourseId]);

  function findLabelSubtitle(course: Course) {
    switch (course.language) {
      case 'en':
        return lang?.ENGLISH_SUBTITLE;
      case 'fr':
        return lang?.FRENCH_SUBTITLE;
      case 'es':
        return lang?.SPANISH_SUBTITLE;
      case 'it':
        return lang?.ITALIAN_SUBTITLE;
      case 'de':
        return lang?.GERMAN_SUBTITLE;
      case 'nl':
        return lang?.DUTCH_SUBTITLE;
      case 'zh_cn':
        return lang?.CHINESE_SUBTITLE;
      case 'zh_tw':
        return lang?.CHINESE_SUBTITLE;
      case 'ja':
        return lang?.JAPANESE_SUBTITLE;
      case 'pt_br':
        return lang?.PORTOGUESE_SUBTITLE;
      default:
        return '';
    }
  }

  const handleClickForVC = () => {
    const {completionurl,directurl} = getVCRecordingLinks(props.course)
    dispatch(actions.toggleIframePopup(
      true, 
      props.course,
      directurl, 
      completionurl,  
      true
    ))
  }

  return (
    <div
      className={clsx('card-activity', {
        [props.className]: props.className,
        'card-activity--completed': props.valueBar === 100,
        'card-activity--locked card-activity--is-locked': props.isBlocked,
        'lesson-is-part-of-event ': isChildOfAnEvent(props?.course?.courseId, coursesMap),
        'lesson-in-onboarding':
          props.isLessonInOnboarding && !isChildOfAnEvent(props?.course?.courseId, coursesMap),
        'lesson-support-in-onboarding': props.isSupportLessonInOnboarding,
        'card-activity__assignment': props.isAssignment,
        'card-activity__onetoone': props.isOneToOne,
        'card-activity__supportmaterial': props.isSupportMaterial,
      })}
      style={{ height: size.height }}
      data-id={props.id}
      onClick={!!props.onClickCard ? handleClickCard : undefined}
      onKeyDown={event =>
        !!props.onClickCard ? handleOnEnterKeyPress(event, handleClickCard, true) : {}
      }
      tabIndex={!!props.onClickCard ? 0 : undefined}
      id={props.topic3Id}
      ref={props?.cardRef}
    >
      {!props.isBlocked && (
        <div className="card-activity__cover-container" style={{ height: 'auto' }} id={idScroll}>
          {/* <div className="card-activity__effect" />
          {showImage && !props.isAssignment && !props.isOneToOne && !props.isSupportMaterial && (
            <CardCover
              className="card-activity__cover"
              courseFullName={props.name}
              image={props.image}
              variant="card-activity-cover"
              hideShadow
              hideSpinner
              isHarvard={!!props?.isHarvard}
            />
          )} */}
        </div>
      )}
      <div className={'card-activity__content'} ref={div}>
        <div className="card-activity__header">
          {props.orderNumber && !isOnboardingPage && !isBycPage && (
            <div className="card-activity__number">
              {props.orderNumber?.toString()?.padStart(2, '0')}
            </div>
          )}
          <div className="phone-and-tablet-portrait">
            <div className="flex flex--wrap">
              <span className="card-activity__ctype-name">{props.ctypeName}</span>
              <span className="card-activity__duration">{props?.duration}</span>
              {props.credits > 0 && !isChildOfAnEvent(props?.course?.courseId, coursesMap) && (
                <span className="card-activity__duration">
                  {props?.credits +
                    ' ' +
                    (props.credits === 1 ? lang.CREDIT_LABEL : lang.CREDITS_LABEL)}
                </span>
              )}
              {isChildOfAnEvent(props?.course?.courseId, coursesMap) && (
                <span className="card-activity__ctype-name sub-activity">
                  {findLabelSubtitle(props.course)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="card-activity__info-section">
          <div className="card-activity__first-row">
            <div className="desktop-and-tablet-landscape">
              <div className="flex flex--wrap">
                <span className="card-activity__ctype-name">{props.ctypeName}</span>
                <span className="card-activity__duration">{props?.duration}</span>
                {props.credits > 0 && !isChildOfAnEvent(props?.course?.courseId, coursesMap) && (
                  <span className="card-activity__duration">
                    {props?.credits +
                      ' ' +
                      (props.credits === 1 ? lang.CREDIT_LABEL : lang.CREDITS_LABEL)}
                  </span>
                )}
                {isChildOfAnEvent(props?.course?.courseId, coursesMap) && (
                  <span className="card-activity__ctype-name sub-activity">
                    {findLabelSubtitle(props.course)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <span className="card-activity__name">
            {truncateLabel(props.name, maxChars).toUpperCase()}
            {props.valueBar === 100 && props.isSupportLessonInOnboarding && (
              <div className="card-activity__tag-badge-mobile-container">
                <TagBadgeCardActivity />
              </div>
            )}
          </span>
          {showSessionLabels && firstSession && (
            <div className="card-activity__labels">
              {firstSession?.trainers && firstSession?.trainers[0] &&
                <span className="card-activity__trainer">
                  {getTrainerFullName(firstSession.trainers[0])}
                </span>}
              {firstSession?.city ? (
                <span className="card-activity__city">
                  {` - ${firstSession.city}`}
                </span>
              ) : null}
              {firstSession?.language &&
                <span className="card-activity__language">
                  <TagBadge label={firstSession?.language} />
                </span>
              }
            </div>
          )}
          {props.description && !isLoadingCourseSummary?.[props?.course?.courseId] && (
            <span
              className="card-activity__description short"
              dangerouslySetInnerHTML={
                typeof props.description === 'string'
                  ? printHTML(props.description)
                  : props.description
              }
            />
          )}
          {isLoadingCourseSummary?.[props.course?.courseId] &&
            <div className="card-activity__description short">
              <TextSkeleton className='tablet-landscape' width={300} height={15} borderRadius={12}/>
              <TextSkeleton className='tablet-landscape' width={200} height={15} borderRadius={12}/>
              <TextSkeleton className='desktop' width={600} height={18} borderRadius={15}/>
              <TextSkeleton className='desktop' width={200} height={18} borderRadius={15}/>
            </div>
          }
          {props.valueBar === 100 &&
            !props.isSupportLessonInOnboarding && isPhoneOrTabletPortrait &&
            !isChildOfAnEvent(props?.course?.courseId, coursesMap) && (
              <div className="card-activity__tag-badge-mobile-container">
                <TagBadgeCardActivity />
              </div>
            )}
          {isMultiActivity(props?.course) &&
            !vcHasMoreSession(props?.course) &&
            isCompletedCourse(props?.course) && (
              <div className="card-activity__tag-badge-mobile-container">
                <TagBadgeCardActivity />
              </div>
            )}
          {((isAnEmptyVirtualClassroom(props?.course) || isAnEmptyLivestream(props?.course)) && !isLessonOptionalForLP(props?.course, coursesMap, props?.fatherCourseId) && isPhoneOrTabletPortrait) && (
            <div className="">
              <TagBadgeEmptyVC />
            </div>
          )}
          
          {!isCompletedCourse(props?.course) && !isVirtualClassroom(props.course) &&
            isLessonOptionalForLP(props?.course, coursesMap, props?.fatherCourseId) &&  isPhoneOrTabletPortrait &&
            !props?.showTopic3OptionalBadge &&
            !props?.showTopic1OptionalBadge &&
            !props?.showTopic2OptionalBadge && (
            <div className="card-activity__tag-badge-mobile-container">
              <TagBadgeOptional className="tag-badge-optional" />
            </div>
          )}

          {!!props.onClickCard && !isAssignmentLesson(props?.course) && !isSupportMaterialLesson(props?.course) && (!isMultiActivity(props?.course) || (isMultiActivity(props?.course) && isOnboardingPage)) && (
            <ButtonV2 variant="text-btn-no-arrow" handleClick={handleClickCard}>
              {lang.READ_MORE_LABEL}
              <Arrowleft />
            </ButtonV2>
          )}
        </div>
        <div className={isVirtualClassroom(props?.course)? "card-activity__progress-container card-activity__progress-container-withVC" : "card-activity__progress-container"}>
          {props.valueBar === 100 && !props.isSupportLessonInOnboarding && (
            <div className="flex align-center">
              <TagBadgeCardActivity className="overPhone" />
            </div>
          )}
          {isMultiActivity(props?.course) &&
            !vcHasMoreSession(props?.course) &&
            !isCompletedCourse(props?.course) && (
              <div className="flex align-center">
                <TagBadgeCardActivity className="overPhone" />
              </div>
            )}
          
          
          {!isCompletedCourse(props?.course) &&
            isLessonOptionalForLP(props?.course, coursesMap,props?.fatherCourseId) && !isVirtualClassroom(props?.course) &&
            !props?.showTopic3OptionalBadge && 
            !props?.showTopic2OptionalBadge && 
            !props?.showTopic1OptionalBadge && (
            <div className="flex align-center">
              <TagBadgeOptional className="overPhone tag-badge-optional" />
            </div>
          )}


          {isVirtualClassroom(props?.course)  &&
            !isLessonOptionalForLP(props?.course, coursesMap, props?.fatherCourseId) &&  (
            <>
              <RibbonCourseEvolution
                showBadgeVC={true}
                course={props?.course}
                useBadgeLabelLive={true}
                ribbonInfo={{
                  label: props?.badgeLabel,
                  color: props?.badgeColor,
                  priority: props?.badgePriority,
                }}
              />
              {props.course.showLiveBadge && props.course.showJoin && (
                <div style={showSessionLabels && { top: 70 }} className="ellipse-live"></div>
              )}
            </>
          )}
          {(props.isBlocked || props.isProgramLocked) ? (
            <div className="card-activity__locked">
              <ButtonV2 variant="icon-btn">
                {props.isBlocked ? (
                  <Lock2Icon />
                ) : (
                  <LockIconSmall />
                )}
              </ButtonV2>
            </div>
          ) : (
            (!vcHasMoreSessionToBook(props.course) && !props.hidePlayButton ? (
              <>
              {!isCompletedCourse(props?.course) && isVirtualClassroom(props?.course) && isVCLiveNow(props.course, userProfile) &&
              isLessonOptionalForLP(props?.course, coursesMap, props?.fatherCourseId) && !isPhoneOrTabletPortrait &&
              !props?.showTopic3OptionalBadge &&
              !props?.showTopic2OptionalBadge && (
              <div className="card-activity__tag-badge-mobile-container">
                <TagBadgeOptional className="tag-badge-optional" />
              </div>
            )}
              {(isDesktopOrTabletLandscape) && 
                <DetailsSingleActivityButton
                  course={props.course}
                  className={ 
                    (showCalendarIconForVC
                      ? 'desktop-and-tablet-landscape launch-container__second-button'
                      : 'desktop-and-tablet-landscape')
                  }
                  lang={lang}
                  subtitle={null}
                  isLessonWithinLp
                  delayGetCourseDetails
                  isLoading={isLoadingDetails[props.course.courseIdMaster]}
                  canBePlayIcon
                  showDateCTA
                  replaceCancelBookingWithAddCalendar
                  buttonVariant={showCalendarIconForVC ? 'icon-btn' : 'primary'}
                  // buttonVariant='primary'
                  buttonSmall={true}
                  isClickedFromOnboarding={true}
                  isClickedFromLp={isLPCourse}
                  vcLesson={multiActivityHasMoreVC(props.course) ? props.activity?.instance : null}
                  fatherCourseId={props?.fatherCourseId}
                />
              }

              {(isPhone || isTabletPortrait) &&
                <DetailsSingleActivityButton
                  course={props.course}
                  className={
                    showCalendarIconForVC
                      ? 'phone-and-tablet-portrait cancel-calendar'
                      : 'phone-and-tablet-portrait'
                  }
                  lang={lang}
                  subtitle={null}
                  isLessonWithinLp
                  delayGetCourseDetails
                  isClickedFromOnboarding={true}
                  isClickedFromLp={isLPCourse}
                  isLoading={isLoadingDetails[props.course.courseIdMaster]}
                  canBePlayIcon
                  showDateCTA
                  replaceCancelBookingWithAddCalendar
                  buttonVariant={showCalendarIconForVC ? 'text-btn-no-arrow' : 'text-btn'}
                  buttonSmall={true}
                  vcLesson={multiActivityHasMoreVC(props.course) ? props.activity?.instance : null}
                  fatherCourseId={props?.fatherCourseId}
                />
              }
                {isVirtualClassroom(props?.course) &&
                  getVCRecordings(props?.course)?.length > 1 && //Multirecording case vc
                  getVCRecordings(props?.course).map((recording, i) => {
                    return (
                      <ButtonV2
                        variant="text-btn-no-arrow"
                        className="button-streaming-recording"
                        key={recording}
                        // OLD - WHEN VC WHERE NOT HOSTED IN CROSSCAST (TEAMS MIGRATION)
                        // handleClick={() => openRecordings(recording)}
                        handleClick={() => handleClickForVC()}
                      >
                        <LazySVG src="/images/svg-icons/play-2.svg" alt={lang?.RECORDING_VC} />
                        {lang?.RECORDING_VC +
                          ' - ' +
                          lang.PART_OF_RECORDING +
                          ' ' +
                          (i + 1).toString().padStart(2, '0')}
                      </ButtonV2>
                    );
                  })}
                {isVirtualClassroom(props?.course) && getVCRecordings(props.course) &&
                  getVCRecordings(props?.course)?.length === 1 && //Multirecording case vc
                  <ButtonV2
                    variant="text-btn-no-arrow"
                    className="button-streaming-recording"
                    key={getVCRecordings(props.course)}
                    // OLD - WHEN VC WHERE NOT HOSTED IN CROSSCAST (TEAMS MIGRATION)
                    // handleClick={() => openRecordings(getVCRecordings(props.course)[0])}
                    handleClick={() => handleClickForVC()}
                  >
                    <LazySVG src="/images/svg-icons/play-2.svg" alt={lang?.ACTIVITY_BUTTON_VIEW_RECORDING} />
                    {lang?.ACTIVITY_BUTTON_VIEW_RECORDING}
                  </ButtonV2>
                }
              </>
            ) : (
              props.hidePlayButton && (
                <PlayIcon
                  valueBar={props.valueBar}
                  ariaLabel={lang?.SHOW_OVERVIEW_ITEM?.replace('{name}', props.name)}
                  onClick={handleClickPlayIcon}
                />
              )
            ))
          )}
            {!isCompletedCourse(props?.course) && 
            isLessonOptionalForLP(props?.course, coursesMap,props?.fatherCourseId) && isVirtualClassroom(props?.course) && !isVCLiveNow(props.course, userProfile) &&
            !props?.showTopic3OptionalBadge && 
            !props?.showTopic2OptionalBadge && 
            !props?.showTopic1OptionalBadge && (
            <div className="flex align-center">
              <TagBadgeOptional className="overPhone tag-badge-optional vc-optional-case" />
            </div>
          )}
        </div>
        {!isCompletedCourse(props?.course) && isVirtualClassroom(props?.course) && (isPhone || isTabletPortrait) &&
            isLessonOptionalForLP(props?.course, coursesMap, props?.fatherCourseId) && 
            !props?.showTopic3OptionalBadge &&
            !props?.showTopic2OptionalBadge && (
            <div className="card-activity__tag-badge-mobile-container">
              <TagBadgeOptional className="tag-badge-optional" />
            </div>
          )}
        {/*BEFORE BOOKING*/}
        {props.course &&
          isMultiActivity(props.course) &&
          isVirtualClassroom(props.course) &&
          !isCompletedCourse(props?.course) &&
          vcHasMoreSessionToBook(props.course) &&
          !multiActivityHasMoreVC(props.course) && ( //only 1 vc in the multiacitivty
            <div className="card-activity__carousel-vc-section" id="sessions">
              <VCMultiSessionCarouselSection
                id="multi-activity-vc-sessions"
                vcMultiSessions={vcGetSessionToBook(props.course)}
                classNames="vcMultiSession"
                isLoading={false}
                variableWidth={false}
                course={props.course}
              />
            </div>
          )}
{//AT THE MOMENT multiactivity does not support vc multi sessions, the field facetofaceid has to be removed at februaru 2024 (homepage optimization)
}
        {/*props.course &&
          isMultiActivity(props.course) &&
          isVirtualClassroom(props.course) &&
          vcHasMoreSessionToBook(props.course) &&
          multiActivityHasMoreVC(props.course) && (
            <div className="card-activity__carousel-vc-section" id="sessions">
              <VCMultiSessionCarouselSection
                id="multi-activity-vc-sessions"
                vcMultiSessions={getSessionFilteredByActivityToBook(
                  props.activity.instance,
                  props.course
                )}
                classNames="vcMultiSession"
                isLoading={false}
                variableWidth={false}
                course={props.course}
                vcLesson={props.activity.instance}
              />
            </div>
                )*/}
      </div>
    </div>
  );
};

export default CardActivity;
