import React from "react";
import { useSelector } from "@hooks/redux";
import CarouselSection from "@components/CarouselSection";
import CardMediumPillSkeleton from "@components/skeletons/CardMediumPillSkeleton";
import { Course, CoursesMap } from '@model/CoursesClass';
 
type ParentCoursesCarouselProps = {
  className?: string;
  stringTitle?: string;
  numCardsToShow?: number;
  props
};
 
export const ParentCoursesCarousel = ({
  className = '',
  stringTitle = '',
  numCardsToShow = 12,
  props
}: ParentCoursesCarouselProps): React.ReactElement => {

  const lang = useSelector(state => state.utils.lang);
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);

  const parentCourses = props.course.parentLP
  .sort((a: Course, b: Course) => a.courseFullName.localeCompare(b.courseFullName))
  .map(course => coursesMap[course.courseId]);

return (
  parentCourses.length > 0 && (
    <section className={'parent-courses-section'}>
      <CarouselSection
        lang={lang}
        stringTitle={stringTitle}
        classNames={className}
        title={stringTitle}
        variableWidth={true}
        skeletonElement={<CardMediumPillSkeleton />}
        numCoursesToShow={numCardsToShow}
        animationSlideCustom={{ initialY: '0' }}
        isLoading={false}
        courses={parentCourses}
      />
    </section>
  )
);
};
 
export default ParentCoursesCarousel;